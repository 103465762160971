var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { DrawerComponent, AntdForm, DrawerFooter, ResponsiveWithPlusIcon, NumberFormatInput, useTableScroll, useEntireLoader, GUIDE_NAMES, SortableTables, StyledFormItem, checkPermissionForOnClick } from 'renderer/components';
import { TICKET_TYPE, TicketTemplateModel, TICKET_BENEFIT_TYPE } from 'renderer/models';
import { TicketTemplateQuery, ProductQuery, ManagerQuery } from 'renderer/queries';
import { Lodash, sleep, useWindowSize } from 'renderer/utils';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import Alert from 'antd/lib/alert';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import { KRWFomatToString } from 'renderer/components/Intl';
import { MediaQuery } from 'renderer/styles';
import { MANAGER_PERMISSION_ACTIONS, MANAGER_PERMISSION_KEYS, MANAGER_PERMISSION_PRIORITY } from 'renderer/models';
import Modal from 'antd/lib/modal';
import { REGEX_NUMBER_FORMMATER } from 'renderer/constants';
import Select from 'antd/lib/select';
import Switch from 'antd/lib/switch';
import { SettingTemplate } from './elements';
import { getBenefitFieldsByType, getFinalAmountFieldsByType, getTicketInfoFieldsByType, InlineFormItem } from './templateFields';
export var TicketSetting = function (props) {
    var store = props.store;
    var _a = React.useState(), drawer = _a[0], setDrawer = _a[1];
    var loginManager = ManagerQuery.getLoginManagerInCache();
    var commonParams = {
        key: MANAGER_PERMISSION_KEYS.TICKET_TEMPLATE_LIST,
        priority: MANAGER_PERMISSION_PRIORITY.ALL
    };
    var hasCreatePerm = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission(__assign(__assign({}, commonParams), { action: MANAGER_PERMISSION_ACTIONS.CREATE }));
    var hasUpdatePerm = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission(__assign(__assign({}, commonParams), { action: MANAGER_PERMISSION_ACTIONS.UPDATE }));
    var hasDeletePerm = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission(__assign(__assign({}, commonParams), { action: MANAGER_PERMISSION_ACTIONS.DELETE }));
    var disabled = !hasCreatePerm;
    var handleClose = function () {
        setDrawer(null);
    };
    var isPC = useWindowSize().isPC;
    var scroll = {
        y: useTableScroll(true, false)
    };
    return (React.createElement(SettingTemplate, { description: "\uD310\uB9E4\uD560 \uC120\uBD88\uC561\uACFC \uC815\uAE30\uAD8C(\uD69F\uC218\uAD8C,\uAE30\uAC04\uAD8C)\uC744 \uB4F1\uB85D\uD574\uBCF4\uC138\uC694!  ", guideName: GUIDE_NAMES['선불액∙정기권 관리'], action: React.createElement(ResponsiveWithPlusIcon, { children: "\uC120\uBD88\uC561\u2219\uC815\uAE30\uAD8C \uB4F1\uB85D", onClick: function () {
                if (checkPermissionForOnClick(hasCreatePerm, '선불액∙정기권 생성 권한이 없습니다.')) {
                    setDrawer({});
                }
            } }), title: "\uC120\uBD88\uC561\u2219\uC815\uAE30\uAD8C \uAD00\uB9AC", body: React.createElement(TicketTemplateTable, { isPC: isPC, hasUpdatePerm: hasUpdatePerm, hasDeletePerm: hasDeletePerm, store: store, onClose: handleClose, drawer: drawer, scroll: scroll }) }));
};
var TicketTemplateTable = function (props) {
    var _a, _b;
    var _c = React.useState(), drawer = _c[0], setDrawer = _c[1];
    var handleClose = function () {
        setDrawer(null);
        if (props.onClose) {
            props.onClose();
        }
    };
    React.useEffect(function () {
        if (drawer !== props.drawer) {
            setDrawer(props.drawer);
        }
    }, [props.drawer]);
    var storeId = props.store.id;
    var ticketTemplateQuery = TicketTemplateQuery.useTicketTemplates(storeId, { enabled: !!storeId });
    var productsQuery = ProductQuery.useProducts({ storeId: storeId, paranoid: true }, { enabled: !!storeId });
    var createGroupMutate = TicketTemplateQuery.useCreateTemplate();
    var updateGroupMutate = TicketTemplateQuery.useUpdateTemplate();
    var deleteGroupMutate = TicketTemplateQuery.useDeleteTemplate();
    var updateTicketOrder = TicketTemplateQuery.useUpdateTicketTemplateOrder();
    var handleSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var isUpdate, payload, _a, templates, priority, payload, undefinedOrder, _b;
        var _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    isUpdate = drawer === null || drawer === void 0 ? void 0 : drawer.id;
                    if (isUpdate) {
                        if (!checkPermissionForOnClick(hasUpdatePerm, '선불액∙정기권 수정 권한이 없습니다.')) {
                            return [2 /*return*/];
                        }
                    }
                    entireLoader.show();
                    if (!isUpdate) return [3 /*break*/, 5];
                    payload = __assign(__assign({}, data), { id: drawer.id });
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updateGroupMutate.mutateAsync(payload)];
                case 2:
                    _d.sent();
                    notification.success({ message: '선불액∙정기권 템플릿이 수정되었습니다.' });
                    handleClose();
                    return [3 /*break*/, 4];
                case 3:
                    _a = _d.sent();
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 12];
                case 5:
                    templates = (_c = ticketTemplateQuery === null || ticketTemplateQuery === void 0 ? void 0 : ticketTemplateQuery.data) === null || _c === void 0 ? void 0 : _c.filter(function (item) { return item.type === data.type && !item.isProductDeleted; });
                    priority = (templates === null || templates === void 0 ? void 0 : templates.length) || 0;
                    _d.label = 6;
                case 6:
                    _d.trys.push([6, 11, , 12]);
                    payload = __assign(__assign({}, data), { meta: __assign(__assign({}, data.meta), { priority: priority }) });
                    return [4 /*yield*/, createGroupMutate.mutateAsync(payload)];
                case 7:
                    _d.sent();
                    return [4 /*yield*/, sleep(1000)];
                case 8:
                    _d.sent();
                    undefinedOrder = templates
                        .map(function (item, index) {
                        var _a, _b;
                        if (!((_a = item.meta) === null || _a === void 0 ? void 0 : _a.priority) && ((_b = item.meta) === null || _b === void 0 ? void 0 : _b.priority) !== 0) {
                            return __assign(__assign({}, item), { meta: __assign(__assign({}, item.meta), { priority: index }) });
                        }
                        else {
                            return null;
                        }
                    })
                        .filter(Boolean);
                    if (!(undefinedOrder.length >= 1)) return [3 /*break*/, 10];
                    return [4 /*yield*/, updateTicketOrder.mutateAsync({ items: undefinedOrder, storeId: props.store.id })];
                case 9:
                    _d.sent();
                    _d.label = 10;
                case 10:
                    notification.success({ message: '선불액∙정기권 메뉴가 등록되었습니다' });
                    return [3 /*break*/, 12];
                case 11:
                    _b = _d.sent();
                    return [3 /*break*/, 12];
                case 12:
                    entireLoader.hide();
                    handleClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function (record) {
        if (storeId) {
            if (!checkPermissionForOnClick(props.hasDeletePerm, '선불액∙정기권 삭제 권한이 없습니다.')) {
                return;
            }
            var modal_1 = Modal.warn({
                centered: true,
                zIndex: 9999,
                title: '선불액∙정기권을 삭제하시겠습니까?',
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, deleteGroupMutate.mutateAsync({ storeId: storeId, id: Number(record.id) })];
                            case 1:
                                _b.sent();
                                notification.success({ message: '선불액∙정기권 메뉴가 삭제되었습니다' });
                                handleClose();
                                return [3 /*break*/, 3];
                            case 2:
                                _a = _b.sent();
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); },
                onCancel: function () {
                    modal_1.destroy();
                },
                okCancel: true,
                cancelText: '아니오'
            });
        }
    };
    var hasUpdatePerm = props.hasUpdatePerm;
    var entireLoader = useEntireLoader();
    var handleOrder = function (records) { return __awaiter(void 0, void 0, void 0, function () {
        var record, templates, errorMessage, lastIndex_1, newTemplates, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!checkPermissionForOnClick(hasUpdatePerm, '선불액∙정기권 수정 권한이 없습니다.')) {
                        return [2 /*return*/];
                    }
                    record = records[0];
                    if (!record) {
                        return [2 /*return*/];
                    }
                    templates = ticketTemplateQuery.data;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    lastIndex_1 = 0;
                    newTemplates = templates
                        .map(function (item, index) {
                        var _a;
                        var sortedIndex = records.findIndex(function (record) { return record.id === item.id; });
                        var isNotInclude = sortedIndex === -1;
                        if (item.type !== (templates && templates[index - 1] && templates[index - 1].type)) {
                            lastIndex_1 = index;
                        }
                        var priority = isNotInclude ? index : lastIndex_1 + sortedIndex;
                        if (priority !== ((_a = item.meta) === null || _a === void 0 ? void 0 : _a.priority)) {
                            return {
                                id: item.id,
                                name: item.name,
                                meta: item.meta ? __assign(__assign({}, item.meta), { priority: priority }) : { priority: priority }
                            };
                        }
                        else {
                            return null;
                        }
                    })
                        .filter(Boolean);
                    if (!(newTemplates.length > 1)) return [3 /*break*/, 3];
                    entireLoader.show();
                    return [4 /*yield*/, updateTicketOrder.mutateAsync({ items: newTemplates, storeId: props.store.id })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [3 /*break*/, 6];
                case 4:
                    error_1 = _a.sent();
                    errorMessage = error_1.message;
                    return [3 /*break*/, 6];
                case 5:
                    // TODO: 2초 지연을 할까?? 서버부하 줄이는 용으로
                    if (!errorMessage) {
                        notification.success({ message: '선불액∙정기권 순서가 변경되었습니다' });
                    }
                    entireLoader.hide();
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Wrapper, null,
        ticketTemplateQuery.isError ? (React.createElement(Alert, { message: "Error", description: "\uD15C\uD50C\uB9BF \uBD88\uB7EC\uC624\uAE30 \uC2E4\uD328", type: "error", showIcon: true })) : (React.createElement(SortableTables, { tables: ['charging_money', 'count', 'term'].map(function (type, index) {
                var _a;
                return {
                    bordered: false,
                    onRow: function (record) { return ({
                        onClick: function () {
                            setDrawer(record);
                            // !hasUpdatePerm
                            //   ? notification.info({ message: '수정 권한이 없습니다.' })
                            //   : setDrawer({ type: 'product', data: record }),
                        }
                    }); },
                    id: index,
                    columns: [
                        {
                            title: TicketTemplateModel.getTypeToString(type),
                            dataIndex: 'name',
                            key: 'name',
                            // className
                            width: '50%',
                            fixed: true,
                            align: 'left',
                            render: function (value, record, index) {
                                return value;
                            }
                        },
                        {
                            title: '',
                            dataIndex: 'data',
                            key: 'data',
                            // className
                            width: '25%',
                            fixed: true,
                            align: 'right',
                            render: function (_, record, index) {
                                return record.amountString;
                            }
                        },
                        {
                            title: '',
                            dataIndex: 'price',
                            key: 'price',
                            // className
                            width: '25%',
                            fixed: true,
                            align: 'right',
                            render: function (_, record, index) {
                                return KRWFomatToString({ value: record.totalPrice });
                            }
                        }
                    ],
                    onSortEnd: handleOrder,
                    dataSource: (((_a = ticketTemplateQuery.data) === null || _a === void 0 ? void 0 : _a.filter(function (template) { return template.type === type; }).map(function (item, index) {
                        return new TicketTemplateModel(__assign(__assign({}, item), { key: index, index: index }));
                    })) || []).filter(function (item) { return !item.isProductDeleted; })
                };
            }) })),
        React.createElement(TicketTemplateForm, { data: drawer, onClose: handleClose, onSubmit: handleSubmit, onDelete: handleDelete, store: props.store, products: (_a = productsQuery.data) === null || _a === void 0 ? void 0 : _a.products, groups: (_b = productsQuery.data) === null || _b === void 0 ? void 0 : _b.groups })));
};
export var TicketTemplateForm = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (!props.data)
        return React.createElement("div", null);
    var productOptions = ((_b = (_a = props.groups) === null || _a === void 0 ? void 0 : _a.filter(function (item) { var _a; return !item.deletedAt || ((_a = props.data) === null || _a === void 0 ? void 0 : _a.storeProductId) === item.id; })) === null || _b === void 0 ? void 0 : _b.map(function (item) {
        var _a;
        return ({
            label: item.name,
            options: (_a = item.products
                .filter(function (item) { return !item.isInside; })) === null || _a === void 0 ? void 0 : _a.map(function (product) { return ({ label: product.name, value: product.id }); })
        });
    })) || [];
    console.log('productOptions', productOptions);
    var _h = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _h[1];
    var ticketTypeOptions = Object.keys(TICKET_TYPE)
        .map(function (key) {
        var value = TICKET_TYPE[key];
        if (value === TICKET_TYPE.CHARGING_MONEY) {
            return { label: '선불액', value: value };
        }
        else if (value === TICKET_TYPE.COUNT) {
            return { label: '횟수권', value: value };
        }
        else if (value === TICKET_TYPE.TERM) {
            return { label: '기간권', value: value };
        }
        //  else if (value === TICKET_TYPE.TIME) {
        //   return { label: '시간권', value };
        // }
    })
        .filter(Boolean);
    var form = Form.useForm()[0];
    var prevTicketTemplate = props.data;
    var prevAdditionalFeatures = ((_c = prevTicketTemplate === null || prevTicketTemplate === void 0 ? void 0 : prevTicketTemplate.data) === null || _c === void 0 ? void 0 : _c.additionalFeatures) || [];
    var isUpdated = !!((_d = props.data) === null || _d === void 0 ? void 0 : _d.id);
    var defaultType = ((_e = props.data) === null || _e === void 0 ? void 0 : _e.type) ? props.data.type : ticketTypeOptions[0].value;
    var _j = React.useState(prevAdditionalFeatures.map(function (feature, index) { return ({ index: index, value: feature }); })), additionalFields = _j[0], setAdditionalFields = _j[1];
    var appendAdditionalToFields = function () {
        var tmp = {};
        additionalFields.map(function (additional, index) {
            tmp["add-".concat(index)] = additional.value;
        });
        return tmp;
    };
    var initialValues = isUpdated
        ? Lodash.isEmpty(additionalFields)
            ? __assign(__assign({}, prevTicketTemplate), { isLimit: !!((_f = prevTicketTemplate.data) === null || _f === void 0 ? void 0 : _f.expiredOption), totalPrice: prevTicketTemplate.totalPrice, finalAmount: prevTicketTemplate.finalAmountString, data: __assign(__assign({}, prevTicketTemplate.data), { expiredOption: prevTicketTemplate.data.expiredOption }) }) : __assign(__assign(__assign({}, prevTicketTemplate), appendAdditionalToFields()), { isLimit: !!((_g = prevTicketTemplate.data) === null || _g === void 0 ? void 0 : _g.expiredOption), totalPrice: prevTicketTemplate.totalPrice, finalAmount: prevTicketTemplate.finalAmountString, expiredOption: prevTicketTemplate.data.expiredOption })
        : {
            type: defaultType,
            isLimit: true,
            data: {
                unit: 'month',
                expiredOption: { unit: 'year', amount: 1 },
                benefit: { type: TICKET_BENEFIT_TYPE.ADD, unit: 'month' }
            }
        };
    var _k = React.useState(initialValues), _ = _k[0], setTicketTemplate = _k[1];
    var type = form.getFieldValue('type');
    var getDrawerTitle = function () {
        var text = isUpdated ? '수정' : '등록';
        return "\uC815\uAE30\uAD8C ".concat(text);
    };
    var handleAddFeild = function () {
        var prevIndex = Lodash.max(additionalFields.map(function (field) { return field.index; }));
        var index = Lodash.isNumber(prevIndex) ? prevIndex + 1 : 0;
        setAdditionalFields(additionalFields.concat({ index: index, value: '' }));
    };
    var handleRemoveField = function (index) {
        setAdditionalFields(additionalFields.filter(function (item) { return item.index !== index; }));
    };
    var renderAdditionalField = function (field) {
        return {
            type: 'text',
            rightElement: (React.createElement(Button, { type: "primary", ghost: true, children: "\uCDE8\uC18C", onClick: function () { return handleRemoveField(field.index); }, style: { marginLeft: '-5px', marginRight: '13px' } })),
            itemProps: {
                name: "add-".concat(field.index),
                rules: [{ type: 'string', min: 1, max: 50 }],
                style: { marginBottom: '5px !important' },
                wrapperCol: { span: 24 }
            },
            inputProps: {
                placeholder: '정기권명 추가입력',
                value: field.value,
                onChange: function (event) {
                    setAdditionalFields(additionalFields.map(function (item) {
                        if (item.index !== field.index) {
                            return item;
                        }
                        else {
                            return { index: item.index, value: event.target.value };
                        }
                    }));
                }
            }
        };
    };
    var calculateTotalPrice = function () {
        // 추가 혜택이 할인인 경우에만 Minus해준다.
        var price = form.getFieldValue('price') || 0;
        var totalPrice = 0;
        if (TICKET_BENEFIT_TYPE.DISCOUNT === form.getFieldValue(['data', 'benefit', 'type'])) {
            var amount = form.getFieldValue(['data', 'benefit', 'amount']) || 0;
            totalPrice = price - amount;
        }
        else {
            totalPrice = price;
        }
        form.setFieldsValue({ totalPrice: totalPrice });
    };
    var calculateFinalAmount = function () {
        var ticketType = form.getFieldValue(['type']);
        var benefitType = form.getFieldValue(['data', 'benefit', 'type']);
        // 충전권은 가격으로 Amount계산
        var amount = ticketType === TICKET_TYPE.CHARGING_MONEY
            ? form.getFieldValue('price') || 0
            : form.getFieldValue(['data', 'amount']) || 0;
        var finalAmount = 0;
        if (TICKET_BENEFIT_TYPE.ADD === benefitType) {
            var benfitAmount = form.getFieldValue(['data', 'benefit', 'amount']) || 0;
            var dataUnit = form.getFieldValue(['data', 'unit']) || 'month';
            var benefitUnit = form.getFieldValue(['data', 'benefit', 'unit']) || 'month';
            if (TICKET_TYPE.TERM === form.getFieldValue(['type'])) {
                finalAmount = "".concat(amount).concat(TicketTemplateModel.unitToString(dataUnit), " + ").concat(benfitAmount).concat(TicketTemplateModel.unitToString(benefitUnit));
            }
            else {
                finalAmount = amount + benfitAmount;
            }
        }
        else {
            if (TICKET_TYPE.TERM === form.getFieldValue(['type'])) {
                var dataUnit = form.getFieldValue(['data', 'unit']) || 'month';
                finalAmount = "".concat(amount).concat(TicketTemplateModel.unitToString(dataUnit));
            }
            else {
                finalAmount = amount;
            }
        }
        form.setFieldsValue({ finalAmount: finalAmount });
    };
    var calculateProductName = function (changedValues) {
        var _a, _b, _c;
        var values = form.getFieldsValue();
        if (changedValues.type || changedValues.storeProductId || ((_a = changedValues.data) === null || _a === void 0 ? void 0 : _a.unit) || ((_b = changedValues.data) === null || _b === void 0 ? void 0 : _b.amount)) {
            var storeProductId_1 = values.storeProductId || form.getFieldValue(['storeProductId']);
            var product = (_c = props === null || props === void 0 ? void 0 : props.products) === null || _c === void 0 ? void 0 : _c.find(function (product) { return product.id === storeProductId_1; });
            var productName = product === null || product === void 0 ? void 0 : product.nameWithGroupName;
            form.setFieldsValue({ name: TicketTemplateModel.createTemplatetName(productName, __assign({}, values)) });
        }
    };
    React.useEffect(function () {
        if (!prevTicketTemplate) {
            form.setFieldsValue(null);
            forceUpdate();
        }
        else {
            calculateFinalAmount();
            form.setFieldsValue(initialValues);
            forceUpdate();
            setTicketTemplate(initialValues);
        }
    }, [prevTicketTemplate]);
    var isLimit = form.getFieldValue(['isLimit']);
    var fields = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([
        {
            type: 'select',
            itemProps: {
                name: 'type',
                label: '선불액∙정기권 선택',
                rules: [{ type: 'string', required: true }]
            },
            inputProps: {
                disabled: isUpdated,
                placeholder: '선불액∙정기권 유형을 선택해주세요',
                options: ticketTypeOptions
            }
        }
    ], getTicketInfoFieldsByType(type, productOptions), true), [
        {
            type: 'text',
            itemProps: {
                name: 'name',
                label: type === TICKET_TYPE.CHARGING_MONEY ? '선불액명' : '정기권명',
                rules: [{ type: 'string', required: true, min: 1, max: 100 }]
            },
            inputProps: {
                autoSize: true,
                placeholder: type !== TICKET_TYPE.CHARGING_MONEY ? '자동으로 입력됩니다.' : '선불액명을 입력하세요',
                disabled: type !== TICKET_TYPE.CHARGING_MONEY
            }
        }
    ], false), additionalFields.map(renderAdditionalField), true), [
        {
            element: (React.createElement(Button, { type: "primary", ghost: true, children: "\uCD94\uAC00 \uC815\uBCF4 \uC785\uB825", onClick: handleAddFeild, style: { marginTop: '-10px', marginBottom: '10px' } }))
        },
        {
            type: 'number',
            itemProps: {
                name: 'price',
                label: "".concat(TicketTemplateModel.getTypeToString(form.getFieldValue('type')), " \uAC00\uACA9"),
                rules: [{ type: 'number', required: true, min: 0, max: 999999999 }]
            },
            inputProps: {
                // placeholder: '가격을 입력해주세',
                suffix: '원'
            }
        },
        {
            type: 'select',
            itemProps: {
                name: ['data', 'benefit', 'type'],
                label: '제공 혜택',
                rules: [{ type: 'string', required: true }]
            },
            inputProps: {
                defaultValue: TICKET_BENEFIT_TYPE.DISCOUNT,
                onChange: function () {
                    var ticketType = form.getFieldValue(['type']);
                    var amount = ticketType === TICKET_TYPE.CHARGING_MONEY
                        ? form.getFieldValue('price') || 0
                        : form.getFieldValue(['data', 'amount']) || 0;
                    form.setFieldsValue({
                        finalAmount: amount,
                        data: {
                            benefit: ticketType === TICKET_TYPE.TERM ? { amount: undefined, unit: 'month' } : { amount: undefined }
                        }
                    });
                    forceUpdate();
                },
                options: [
                    { value: 'none', label: '없음' },
                    { value: 'discount', label: '할인' },
                    { value: 'add', label: '추가' }
                ]
            }
        }
    ], false), getBenefitFieldsByType(type, form.getFieldValue(['data', 'benefit', 'type'])), true), [
        getFinalAmountFieldsByType(type, form.getFieldsValue()),
        {
            type: 'number',
            itemProps: {
                name: 'totalPrice',
                label: '최종가격',
                shouldUpdate: true,
                rules: [{ type: 'number', required: true, min: 0 }]
            },
            inputProps: {
                disabled: true,
                placeholder: '자동으로 계산됩니다.',
                suffix: '원'
            }
        },
        {
            element: (React.createElement(StyledFormItem, { name: "isLimit", label: "\uC720\uD6A8\uAE30\uAC04", rules: [
                    {
                        required: true
                    }
                ] },
                React.createElement(Switch, { checked: isLimit })))
        },
        isLimit && {
            element: (React.createElement(InlineFormItem, { items: [
                    {
                        name: ['data', 'expiredOption', 'amount'],
                        rules: [{ type: 'number', required: true, message: "\uAE30\uAC04\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694." }],
                        input: (React.createElement(NumberFormatInput, { style: { width: '100%' }, placeholder: '기간을 입력해주세요.', formatter: function (value) { return "".concat(value).replace(REGEX_NUMBER_FORMMATER, ','); } }))
                    },
                    {
                        name: ['data', 'expiredOption', 'unit'],
                        rules: [{ type: 'string', required: true, message: "\uB2E8\uC704\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694." }],
                        input: (React.createElement(Select, { style: { width: '100%' }, options: [
                                { label: '개월', value: 'month' },
                                { label: '주', value: 'week' },
                                { label: '일', value: 'day' },
                                { label: '년', value: 'year' }
                            ], placeholder: '기간을 입력해주세요.' }))
                    }
                ] }))
        },
        {
            type: 'textarea',
            itemProps: {
                name: 'description',
                label: '정기권 설명',
                rules: [{ type: 'string', min: 0, max: 100 }]
            },
            inputProps: { placeholder: '정기권을 설명해주세요.' }
        }
    ], false).filter(Boolean);
    var handleSubmit = function (values) {
        var _a;
        props.onSubmit(TicketTemplateModel.convertObjectToTemplatePayload(values, (_a = props.store) === null || _a === void 0 ? void 0 : _a.id));
    };
    return (React.createElement(DrawerComponent, { title: getDrawerTitle(), open: !!prevTicketTemplate, onClose: props.onClose, footer: React.createElement(DrawerFooter, { leftExtra: isUpdated &&
                props.onDelete && (React.createElement(Col, { children: React.createElement(Button, { children: "\uC0AD\uC81C", danger: true, type: "primary", size: "large", onClick: function () { return props.onDelete(props.data); } }), flex: 1 })), onCancel: props.onClose, onConfirm: form.submit, confirmText: isUpdated ? '수정' : '등록' }) },
        React.createElement(AntdForm, { formProps: {
                form: form,
                layout: 'vertical',
                labelCol: {
                    span: 24
                },
                wrapperCol: {
                    span: 24
                },
                initialValues: initialValues,
                onValuesChange: function (changedValues, values) {
                    calculateTotalPrice();
                    calculateFinalAmount();
                    calculateProductName(changedValues);
                    // FIXME: 재 렌더를 위한 state
                    setTicketTemplate(values);
                },
                onFinish: handleSubmit
            }, onSubmit: handleSubmit, fields: fields })));
};
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  width: 100%;\n  ", "\n"], ["\n  flex: 1;\n  width: 100%;\n  ", "\n"])), MediaQuery.tabletWithMobile(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 100%;\n  "], ["\n    height: 100%;\n  "]))));
var templateObject_1, templateObject_2;
