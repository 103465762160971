var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { AntModalFunc, AntNotification, checkPermissionForOnClick, TextComponent } from 'renderer/components';
import { CustomerQuery } from 'renderer/queries';
import { COLORS } from 'renderer/styles';
import { SettingTemplate } from './elements';
import Button from 'antd/lib/button';
import { Row } from 'antd/lib';
export var CustomerNumberSetting = function (props) {
    var _a;
    var hasUpdatePerm = (_a = props.permission) === null || _a === void 0 ? void 0 : _a.update;
    var createNumber = CustomerQuery.useBulkCreateCustomerNumber();
    var deleteNumber = CustomerQuery.useRemoveCustomerNumber();
    var handleClick = function (type) {
        var isCreate = type === 'create';
        if (checkPermissionForOnClick(hasUpdatePerm, '고객정보 수정 권한이 없습니다.')) {
            AntModalFunc({
                type: 'confirm',
                zIndex: 9999,
                title: isCreate ? '고객번호 일괄생성' : '고객번호 일괄삭제',
                icon: null,
                content: isCreate
                    ? "\uACE0\uAC1D\uBC88\uD638 \uC77C\uAD04 \uC0DD\uC131 \uC2DC \uAE30\uC874 \uACE0\uAC1D\uBC88\uD638\uB294 \uC0AD\uC81C\uB429\uB2C8\uB2E4.\uC0AD\uC81C\uB41C \uACE0\uAC1D\uBC88\uD638\uB294 \uBCF5\uAD6C\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.\u2019\uC77C\uAD04\uC0DD\uC131'\uC744 \uC9C4\uD589 \uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?"
                    : "\uACE0\uAC1D\uBC88\uD638 \uC77C\uAD04 \uC0AD\uC81C \uC2DC \uBAA8\uB4E0 \uACE0\uAC1D\uBC88\uD638\uAC00 \uC0AD\uC81C\uB429\uB2C8\uB2E4.\uC0AD\uC81C\uB41C \uACE0\uAC1D\uBC88\uD638\uB294 \uBCF5\uAD6C\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.\u2019\uC77C\uAD04\uC0AD\uC81C'\uB97C \uC9C4\uD589 \uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?",
                okText: isCreate ? '일괄생성' : '일괄삭제',
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var err_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 5, , 6]);
                                if (!isCreate) return [3 /*break*/, 2];
                                return [4 /*yield*/, createNumber.mutateAsync(props.storeId)];
                            case 1:
                                _a.sent();
                                return [3 /*break*/, 4];
                            case 2: return [4 /*yield*/, deleteNumber.mutateAsync(props.storeId)];
                            case 3:
                                _a.sent();
                                _a.label = 4;
                            case 4:
                                AntNotification.success({ message: '고객번호 생성 성공' });
                                return [3 /*break*/, 6];
                            case 5:
                                err_1 = _a.sent();
                                AntNotification.error({ message: err_1.message });
                                return [3 /*break*/, 6];
                            case 6: return [2 /*return*/];
                        }
                    });
                }); },
                okButtonProps: {
                    danger: !isCreate,
                    type: 'primary'
                },
                cancelText: '취소'
            });
        }
    };
    return (React.createElement(SettingTemplate
    //   guideName={GUIDE_NAMES.고객등급설정}
    , { 
        //   guideName={GUIDE_NAMES.고객등급설정}
        body: React.createElement("div", { style: { marginTop: '20px' } },
            React.createElement(TextComponent, { children: "\uACE0\uAC1D\uBC88\uD638 \uC77C\uAD04\uC124\uC815", type: "body2" }),
            React.createElement(TextComponent, { children: "\uC804\uCCB4 \uACE0\uAC1D\uC758 \uACE0\uAC1D\uBC88\uD638\uB97C \uC77C\uAD04 \uC0DD\uC131\uD569\uB2C8\uB2E4. \uAE30\uC874 \uACE0\uAC1D\uBC88\uD638\uB294 \uC0AD\uC81C\uB429\uB2C8\uB2E4.", type: "caption", color: COLORS.gray2, marginBottom: 8 }),
            React.createElement(Row, null,
                React.createElement(Button, { children: '일괄생성', style: { marginRight: '8px' }, onClick: function () { return handleClick('create'); } }),
                React.createElement(Button, { type: "primary", danger: true, ghost: true, children: '일괄삭제', onClick: function () { return handleClick('delete'); } }))), title: "\uACE0\uAC1D\uBC88\uD638 \uC124\uC815", description: "" }));
};
