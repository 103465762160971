var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled from 'styled-components';
import React from 'react';
import { COLORS } from 'renderer/styles';
import { convertHexToRgbA, openWindow } from 'renderer/utils';
import Alert from 'antd/lib/alert';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import { TextComponent, UnderlineClickableText } from '../Typography';
import notification from 'antd/lib/notification';
import { Row } from 'antd/lib/grid';
import { Icon } from 'common-ui';
export var AlertComponent = function (props) {
    var _a = props.color, color = _a === void 0 ? COLORS.warning : _a, rest = __rest(props, ["color"]);
    return React.createElement(StyledAlert, __assign({ color: color }, rest));
};
var StyledAlert = styled(Alert)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 0px;\n  background-color: ", ";\n  & .ant-alert-message {\n    font-family: Noto Sans KR;\n    font-size: 14px;\n    font-weight: 700;\n    letter-spacing: -0.2px;\n    color: ", ";\n  }\n\n  & .ant-alert-description {\n    font-family: Noto Sans KR;\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 1.38;\n    letter-spacing: -0.2px;\n    color: ", ";\n  }\n"], ["\n  border: 0px;\n  background-color: ", ";\n  & .ant-alert-message {\n    font-family: Noto Sans KR;\n    font-size: 14px;\n    font-weight: 700;\n    letter-spacing: -0.2px;\n    color: ", ";\n  }\n\n  & .ant-alert-description {\n    font-family: Noto Sans KR;\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 1.38;\n    letter-spacing: -0.2px;\n    color: ", ";\n  }\n"])), function (props) { return convertHexToRgbA(props.type === 'warning' ? COLORS.warning : COLORS.info, 0.1); }, function (props) { return (props.type === 'warning' ? COLORS.warning : COLORS.info); }, COLORS.gray2);
export var confirmWithInput = function (props) {
    var value = '';
    var handleSubmit = function () {
        if (!value) {
            notification.warn({ message: '내용을 입력해주세요.' });
        }
        else {
            modal.destroy();
            return props.onOk(value);
        }
    };
    var modal = Modal.confirm(__assign(__assign({}, props), { autoFocusButton: null, centered: true, icon: null, content: (React.createElement("div", null,
            props.label && React.createElement(TextComponent, { type: "subtitle3", children: props.label, marginBottom: 5 }),
            React.createElement(Input, { autoFocus: true, onKeyUp: function (ev) {
                    if (ev.key === 'Enter') {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleSubmit();
                    }
                }, onChange: function (ev) {
                    value = ev.target.value;
                } }),
            props.content)), okButtonProps: __assign({ 
            // disabled: !value,
            children: '확인' }, props.okButtonProps), onOk: handleSubmit }));
    return modal;
};
export var AlertSentenceWithIcon = function (props) {
    return (React.createElement(Row, { wrap: false, align: "middle", style: props.style },
        React.createElement(Icon.Icon, { name: "alert-triangle", color: props.color || COLORS.info }),
        React.createElement(TextComponent, { type: "caption1", children: props.descriptions[0], color: props.color || COLORS.info, marginLeft: 5 })));
};
export var NoticeSentenceWithLink = function (props) {
    return (React.createElement(RedBox, { align: "middle", className: props.className },
        React.createElement(TextComponent, { children: "\uD544\uB3C5!", type: "subtitle3", color: COLORS.calendarRedActive }),
        React.createElement(UnderlineClickableText, { style: { flex: 1 }, color: COLORS.black, type: "subtitle3", marginLeft: 10, children: props.title, onClick: function () { return openWindow(props.url, props.title); } })));
};
var RedBox = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 2px;\n  padding-left: 15px;\n  background-color: rgba(255, 77, 79, 0.05);\n  margin-bottom: 3.5px;\n"], ["\n  border-radius: 2px;\n  padding-left: 15px;\n  background-color: rgba(255, 77, 79, 0.05);\n  margin-bottom: 3.5px;\n"])));
var templateObject_1, templateObject_2;
