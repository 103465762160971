var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { SortableElement, SortableHandle, SortableContainer } from 'react-sortable-hoc';
import { arrayMove } from 'renderer/utils';
import React from 'react';
import { StyledTable, RowModal } from './Table';
import styled, { css } from 'styled-components';
import { DragHandlerIcon } from '../TableColumnSetter';
import { typographyByName } from 'renderer/components';
import { COLORS } from 'renderer/styles';
import { useSortable, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
var DragHandle = SortableHandle(function () { return React.createElement(DragHandlerIcon, null); });
var Row = function (_a) {
    var children = _a.children, disableSort = _a.disableSort, props = __rest(_a, ["children", "disableSort"]);
    var _b = useSortable({
        id: props['data-row-key']
    }), attributes = _b.attributes, listeners = _b.listeners, setNodeRef = _b.setNodeRef, setActivatorNodeRef = _b.setActivatorNodeRef, transform = _b.transform, transition = _b.transition, isDragging = _b.isDragging;
    var style = __assign(__assign(__assign({}, props.style), { transform: CSS.Transform.toString(transform && __assign(__assign({}, transform), { scaleY: 1 })), transition: transition }), (isDragging ? { position: 'relative', zIndex: 9999 } : {}));
    var dataRowKey = props['data-row-key'];
    return React.useMemo(function () { return (React.createElement("tr", __assign({}, props, { ref: setNodeRef, style: style }, attributes), React.Children.map(children, function (child) {
        if (child.key === 'sort') {
            return React.cloneElement(child, {
                children: !disableSort ? (React.createElement("img", __assign({ ref: setActivatorNodeRef, style: { touchAction: 'none', cursor: 'move' } }, listeners, { src: IMAGES.icon_drag }))) : (React.createElement("div", null))
            });
        }
        return child;
    }))); }, [dataRowKey, style]);
};
import { DndContext } from '@dnd-kit/core';
import { IMAGES } from 'renderer/assets';
import { useInView } from 'react-intersection-observer';
export var SortableTable = function (props) {
    var _a = React.useState(props.dataSource || []), dataSource = _a[0], setDataSource = _a[1];
    var columns = props.columns, onSortEnd = props.onSortEnd, rest = __rest(props, ["columns", "onSortEnd"]);
    React.useEffect(function () {
        if (props.dataSource !== dataSource) {
            setDataSource(props.dataSource || []);
        }
    });
    var DragColumn = [
        {
            title: props.disableParentSort ? '' : React.createElement(DragHandle, null),
            dataIndex: 'sort',
            key: 'sort',
            fixed: 'left',
            width: 20,
            className: 'drag-visible',
            render: function () { return (dataSource.length <= 1 ? React.createElement("div", null) : React.createElement(DragHandle, null)); }
        }
    ];
    var createId = function (data) {
        var _a, _b;
        return ((_a = data.updatedAt) === null || _a === void 0 ? void 0 : _a.getTime) ? "".concat(data.id, "_").concat((_b = data.updatedAt) === null || _b === void 0 ? void 0 : _b.getTime()) : data.id;
    };
    var onDragEnd = function (_a) {
        var active = _a.active, over = _a.over;
        if (active.id !== (over === null || over === void 0 ? void 0 : over.id)) {
            setDataSource(function (previous) {
                var activeIndex = previous.findIndex(function (i) { return createId(i) === active.id; });
                var overIndex = previous.findIndex(function (i) { return createId(i) === (over === null || over === void 0 ? void 0 : over.id); });
                var newData = arrayMove(previous, activeIndex, overIndex);
                console.log({
                    previous: previous,
                    active: active,
                    over: over,
                    activeIndex: activeIndex,
                    overIndex: overIndex,
                    newData: newData
                });
                if (onSortEnd) {
                    onSortEnd(newData);
                }
                return newData;
            });
        }
    };
    var _b = React.useState(undefined), modalStyle = _b[0], setModalStyle = _b[1];
    var handleRowClick = function (ev, record, rowIndex) {
        setModalStyle({
            top: ev.clientY,
            left: ev.clientX,
            height: 200,
            data: record
        });
    };
    var disableSort = dataSource.length <= 1;
    return (React.createElement(React.Fragment, null,
        modalStyle && props.actions && (React.createElement(RowModal, { onClose: function () { return setModalStyle(undefined); }, modalStyle: modalStyle, actions: props.actions })),
        React.createElement(DndContext, { onDragEnd: onDragEnd, modifiers: [restrictToVerticalAxis] },
            React.createElement(SortableContext
            // rowKey array
            , { 
                // rowKey array
                items: (dataSource === null || dataSource === void 0 ? void 0 : dataSource.map(createId)) || [], strategy: verticalListSortingStrategy },
                React.createElement(StyledTableComponent, __assign({}, rest, { onRow: function (record, rowIndex) {
                        return props.onRow
                            ? __assign({}, props.onRow(record, rowIndex)) : {
                            onClick: function (event) {
                                if (props.actions) {
                                    handleRowClick(event, record, rowIndex);
                                }
                            }
                        };
                    }, components: {
                        body: {
                            row: function (props) { return Row(__assign(__assign({}, props), { disableSort: disableSort })); }
                        }
                    }, rowKey: createId, pagination: false, dataSource: dataSource || [], columns: DragColumn.concat(__spreadArray([], props.columns, true)) }))))));
};
var StyledTableComponent = styled(StyledTable)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 20px;\n  & table {\n    & .ant-table-cell {\n      padding: 6px;\n      ", "} \n      color: ", ";\n    }\n    & .ant-table-thead > tr > th {\n      background-color: ", ";\n      color: ", ";\n      font-weight: 500;\n      ", "} \n    }\n  \n    & .ant-table-thead > tr > th {\n      &:hover {\n        background-color: ", ";\n        color: ", ";\n      }\n    }\n    & .ant-table-tbody > tr > td {\n      color: ", ";\n    }\n    & .ant-table-selection-column {\n      border-right: 0px !important;\n    }\n  }\n"], ["\n  margin-bottom: 20px;\n  & table {\n    & .ant-table-cell {\n      padding: 6px;\n      ", "} \n      color: ", ";\n    }\n    & .ant-table-thead > tr > th {\n      background-color: ", ";\n      color: ", ";\n      font-weight: 500;\n      ", "} \n    }\n  \n    & .ant-table-thead > tr > th {\n      &:hover {\n        background-color: ", ";\n        color: ", ";\n      }\n    }\n    & .ant-table-tbody > tr > td {\n      color: ", ";\n    }\n    & .ant-table-selection-column {\n      border-right: 0px !important;\n    }\n  }\n"])), css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        ", "\n      "], ["\n        ", "\n      "])), typographyByName('caption1')), COLORS.black, COLORS.gray7, COLORS.gray2, css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        ", "\n      "], ["\n        ", "\n      "])), typographyByName('subtitle3')), COLORS.gray7, COLORS.black, COLORS.black);
var SortableTableItem = SortableElement(function (props) {
    return React.createElement(SortableTable, __assign({}, props.value, { style: { zIndex: 9999 } }));
});
var SortableTableContainer = SortableContainer(function (props) {
    return (React.createElement("div", null, (props.items || []).map(function (item, index) {
        return React.createElement(SortableTableItem, { key: "item-".concat(index), index: index, value: item });
    })));
});
export var SortableTables = function (props) {
    var onSortEnd = props.onSortEnd;
    var handleSortEnd = function (_a) {
        var oldIndex = _a.oldIndex, newIndex = _a.newIndex;
        if (oldIndex !== newIndex) {
            var newData = arrayMove([].concat(props.tables), oldIndex, newIndex).filter(function (el) { return !!el; });
            if (onSortEnd) {
                onSortEnd(newData);
            }
        }
    };
    return !onSortEnd ? (React.createElement(React.Fragment, null, props.tables.map(function (table, index) {
        return React.createElement(SortableTable, __assign({ key: "table-".concat(index) }, table, { disableParentSort: true }));
    }))) : (React.createElement(SortableTableContainer, { items: props.tables, useDragHandle: true, onSortEnd: handleSortEnd, helperClass: "row-dragging" }));
};
export var VitualRenderSortableTables = function (props) {
    var _a = useInView({
        threshold: 0
    }), ref = _a.ref, inView = _a.inView;
    var onSortEnd = props.onSortEnd;
    var handleSortEnd = function (_a) {
        var oldIndex = _a.oldIndex, newIndex = _a.newIndex;
        if (oldIndex !== newIndex) {
            var newData = arrayMove([].concat(props.tables), oldIndex, newIndex).filter(function (el) { return !!el; });
            if (onSortEnd) {
                onSortEnd(newData);
            }
        }
    };
    return (React.createElement("div", { ref: ref }, inView ? (React.createElement(SortableTableContainer, { items: props.tables, useDragHandle: true, onSortEnd: handleSortEnd, helperClass: "row-dragging" })) : (React.createElement("div", null))));
};
var templateObject_1, templateObject_2, templateObject_3;
