var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { TICKET_BENEFIT_TYPE, TICKET_TYPE } from 'renderer/models';
import { REGEX_NUMBER_FORMMATER } from 'renderer/constants';
import Select from 'antd/lib/select';
import { NumberFormatInput, StyledFormItem } from 'renderer/components';
import { Col, Row } from 'antd/lib/grid';
export var getTicketInfoFieldsByType = function (type, productOptions) {
    return [
        type !== TICKET_TYPE.CHARGING_MONEY && {
            type: 'select',
            itemProps: {
                name: 'storeProductId',
                label: '상품 종류',
                rules: [{ type: 'number', required: true }]
            },
            inputProps: { placeholder: '상품을 선택하세요.', options: productOptions }
        },
        type === TICKET_TYPE.COUNT && {
            type: 'number',
            itemProps: {
                name: ['data', 'amount'],
                label: '횟수',
                rules: [{ type: 'number', required: true, min: 1, max: 999999999 }]
            },
            inputProps: {
                placeholder: '횟수를 입력하세요. ',
                suffix: '회'
            }
        },
        // (type === TICKET_TYPE.COUNT || type === TICKET_TYPE.TIME) && {
        //   type: 'number',
        //   itemProps: {
        //     name: ['data', 'amount'],
        //     label: TICKET_TYPE.TIME ? '시간' : '횟수',
        //     rules: [{ type: 'number', required: true, min: 1 }]
        //   },
        //   inputProps: {
        //     placeholder: TICKET_TYPE.TIME ? '시간을 입력하세요.' : '횟수를 입력하세요.',
        //     formatter: (value) => `${value} ${TICKET_TYPE.TIME ? '시간' : '회'}`.replace(REGEX_NUMBER_FORMMATER, ',')
        //   }
        // },
        type === TICKET_TYPE.TERM && {
            element: React.createElement(NumberWithUnit, { number: ['data', 'amount'], unit: ['data', 'unit'], label: "\uAE30\uAC04" })
        }
    ];
};
export var getBenefitFieldsByType = function (type, benefitType) {
    var rightElementText = type === TICKET_TYPE.CHARGING_MONEY
        ? '원'
        : type === TICKET_TYPE.COUNT
            ? '회'
            : type === TICKET_TYPE.TIME
                ? '시간'
                : '';
    switch (benefitType) {
        case TICKET_BENEFIT_TYPE.ADD:
            return [
                type === TICKET_TYPE.TERM
                    ? {
                        element: React.createElement(NumberWithUnit, { number: ['data', 'benefit', 'amount'], unit: ['data', 'benefit', 'unit'] })
                    }
                    : {
                        type: 'number',
                        itemProps: {
                            name: ['data', 'benefit', 'amount'],
                            rules: [{ type: 'number', required: true }],
                            label: '제공량'
                        },
                        inputProps: {
                            placeholder: '제공 할 추가서비스 입력하세요.',
                            suffix: rightElementText
                        }
                    }
            ].filter(Boolean);
        case TICKET_BENEFIT_TYPE.DISCOUNT:
            return [
                {
                    type: 'number',
                    itemProps: {
                        label: '할인금액',
                        name: ['data', 'benefit', 'amount'],
                        rules: [{ type: 'number', required: true, min: 1, max: 999999999 }]
                    },
                    inputProps: {
                        placeholder: '할인 할 금액을 입력하세요. ',
                        suffix: '원',
                        formatter: function (value) {
                            return value
                                ? "-".concat(value, " \uC6D0").replace(REGEX_NUMBER_FORMMATER, ',')
                                : "".concat(value, " \uC6D0").replace(REGEX_NUMBER_FORMMATER, ',');
                        }
                    }
                }
            ];
        case TICKET_BENEFIT_TYPE.NONE:
        default:
            return [];
    }
};
export var getFinalAmountFieldsByType = function (type, template) {
    var rightElementText = type === TICKET_TYPE.CHARGING_MONEY
        ? '원'
        : type === TICKET_TYPE.COUNT
            ? '회'
            : type === TICKET_TYPE.TIME
                ? '시간'
                : '';
    var data = template.data;
    switch (type) {
        case TICKET_TYPE.CHARGING_MONEY:
        case TICKET_TYPE.COUNT:
        case TICKET_TYPE.TIME:
            return {
                type: 'number',
                itemProps: {
                    name: 'finalAmount',
                    label: '최종 제공량',
                    rules: [{ required: true }]
                },
                inputProps: {
                    disabled: true,
                    placeholder: '자동으로 계산됩니다. ',
                    suffix: rightElementText
                    // formatter: (value) => `${value} ${rightElementText}`.replace(REGEX_NUMBER_FORMMATER, ',')
                }
            };
        case TICKET_TYPE.TERM:
            return {
                type: 'text',
                itemProps: {
                    name: 'finalAmount',
                    label: '최종 제공량',
                    rules: [{ required: true }]
                },
                inputProps: {
                    disabled: true,
                    placeholder: '자동으로 계산됩니다.'
                }
            };
        default:
            return;
    }
};
export var NumberWithUnit = function (props) {
    return (React.createElement(InlineFormItem, { label: props.label, items: [
            {
                name: props.number,
                rules: [{ type: 'number', required: true, message: "\uAE30\uAC04\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694." }],
                input: (React.createElement(NumberFormatInput, { style: { width: '100%' }, placeholder: '기간을 입력해주세요.', formatter: function (value) { return "".concat(value).replace(REGEX_NUMBER_FORMMATER, ','); } }))
            },
            {
                name: props.unit,
                rules: [{ type: 'string', required: true, message: "".concat(props.label, "\uB2E8\uC704\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.") }],
                input: (React.createElement(Select, { style: { width: '100%' }, options: [
                        { label: '개월', value: 'month' },
                        { label: '주', value: 'week' },
                        { label: '일', value: 'day' },
                        { label: '년', value: 'year' }
                    ], placeholder: '기간을 입력해주세요.' }))
            }
        ] }));
};
export var InlineFormItem = function (props) {
    var _a;
    return (React.createElement(Row, { wrap: false, gutter: 4, align: "bottom", className: props.className }, (_a = props.items) === null || _a === void 0 ? void 0 : _a.map(function (item, index) {
        return (React.createElement(Col, { key: "".concat(item.name, "_").concat(index), span: 12 },
            React.createElement(StyledFormItem, __assign({}, item, { label: index === 0 ? props.label : '', name: item.name, rules: item.rules }), item.input)));
    })));
};
