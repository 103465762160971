var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import styled, { css } from 'styled-components';
import { CircleButton, commonCssTypography, ManagerCircleButton, TextComponent, typographyByName, ProfileCircleContent, ScrollBar, DefaultCircleButton, useAccessToken, AntNotification, AntButton, CustomerRegister, useGlobalModal } from 'renderer/components';
import { IMAGES } from 'renderer/assets';
import { COLORS, MediaQuery } from 'renderer/styles';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Empty from 'antd/lib/empty';
import { REMOTE_SUPPORT_URL, PATH_APP_MY_PROFILE, PATH_APP_MY_ACCOUNT, API_STAGE } from 'renderer/constants';
import { useNavigate } from 'react-router';
import { WebviewInterface, useWindowSize, Lodash, isPlussharpMobileApp, getQuery } from 'renderer/utils';
import * as dayjs from 'dayjs';
import { onSnapshot, where, orderBy, limit, or, and } from 'firebase/firestore';
import { getDocuments, setDocument } from 'renderer/utils/firebase';
export var GrayBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0 0 4px;\n  padding: 9px 12px 9px 12px;\n  border-radius: 2px;\n  background-color: var(--gray-7);\n"], ["\n  margin: 0 0 4px;\n  padding: 9px 12px 9px 12px;\n  border-radius: 2px;\n  background-color: var(--gray-7);\n"])));
export var PurpleBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 6px 0;\n  padding: 10px 10px 10px 10px;\n  border-radius: 2px;\n  background-color: var(--primary-5);\n"], ["\n  margin: 6px 0;\n  padding: 10px 10px 10px 10px;\n  border-radius: 2px;\n  background-color: var(--primary-5);\n"])));
export var ProfileItem = function (props) {
    var _a, _b, _c, _d, _e;
    var navigate = useNavigate();
    var isAdmin = props.manager.isSuperAdmin;
    var signOut = useAccessToken().signOut;
    var handleProfileSetting = function () {
        navigate(PATH_APP_MY_PROFILE);
    };
    var handleAccountSetting = function () {
        navigate(PATH_APP_MY_ACCOUNT);
    };
    var handleSignout = function () {
        signOut(true);
    };
    var profilePicture = ((_a = props.manager.profile) === null || _a === void 0 ? void 0 : _a.pictures) && ((_b = props.manager.profile) === null || _b === void 0 ? void 0 : _b.pictures[0]);
    var externalLinks = [
        { title: '공지사항', href: 'https://help.thinkofyou.kr/notice' },
        { title: '원격지원 요청', href: REMOTE_SUPPORT_URL },
        { title: '사용 가이드', href: 'https://help.thinkofyou.kr' }
    ];
    var hasStore = (_c = props.manager) === null || _c === void 0 ? void 0 : _c.storeId;
    var Profile = function (isInMenu) {
        var _a, _b, _c, _d;
        return (React.createElement(ProfileItemRow, { gutter: 16, "$isInMenu": isInMenu, wrap: false },
            React.createElement(Col, null,
                React.createElement(ManagerCircleButton, { "$manager": props.manager, children: React.createElement(TextComponent, { type: "headline2", color: COLORS.white, children: React.createElement(ProfileCircleContent, { picture: profilePicture, firstChar: (_a = props.manager) === null || _a === void 0 ? void 0 : _a.firstCharInName }) }) })),
            React.createElement(Col, null,
                React.createElement(Row, null,
                    React.createElement(TextComponent, { type: "subtitle3", color: COLORS.gray1 }, (_b = props.manager) === null || _b === void 0 ? void 0 :
                        _b.displayName,
                        " (", (_c = props.manager.profile) === null || _c === void 0 ? void 0 :
                        _c.position,
                        ")")),
                React.createElement(Row, null,
                    React.createElement(TextComponent, { type: "caption2", color: COLORS.gray2 }, (_d = props.manager) === null || _d === void 0 ? void 0 : _d.loginId)))));
    };
    var menu = (React.createElement(StyledMenu, null,
        Profile(true),
        !isAdmin && hasStore && React.createElement(StyledMenuItem, { onClick: handleProfileSetting }, " \uD504\uB85C\uD544 \uC124\uC815"),
        !isAdmin && hasStore && React.createElement(StyledMenuItem, { onClick: handleAccountSetting }, "\uACC4\uC815 \uC124\uC815"),
        !isAdmin && hasStore && ((_d = props === null || props === void 0 ? void 0 : props.manager) === null || _d === void 0 ? void 0 : _d.id) === 1 && (React.createElement(StyledMenuItem, { onClick: function () {
                WebviewInterface.getInstance().requestChangeStage({
                    stage: 'development'
                });
            } }, "\uAC1C\uBC1C\uACC4 \uC804\uD658")),
        React.createElement(StyledMenuItem, { color: COLORS.danger, onClick: handleSignout }, "\uB85C\uADF8\uC544\uC6C3")));
    return (React.createElement(Dropdown, { overlay: menu, placement: "topRight", trigger: ['click'], overlayStyle: { zIndex: 9999 } }, props.circleButton ? (React.createElement(ManagerCircleButton, { "$manager": props.manager, children: React.createElement(TextComponent, { type: "headline2", color: COLORS.white, children: React.createElement(ProfileCircleContent, { picture: profilePicture, firstChar: (_e = props.manager) === null || _e === void 0 ? void 0 : _e.firstCharInName }) }) })) : (Profile(false))));
};
var ProfileItemRow = styled(Row)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: ", ";\n  padding-left: ", ";\n  padding-top: ", ";\n  ", ";\n  height: ", "px;\n  background: ", ";\n  width: 100%;\n  margin-right: 0px !important;\n  margin-left: 0px !important;\n  z-index: 1;\n}\n"], ["\n  cursor: ", ";\n  padding-left: ", ";\n  padding-top: ", ";\n  ", ";\n  height: ", "px;\n  background: ", ";\n  width: 100%;\n  margin-right: 0px !important;\n  margin-left: 0px !important;\n  z-index: 1;\n}\n"])), function (props) { return (props.$isInMenu ? 'default' : 'pointer'); }, function (props) { return (props.$isInMenu ? '0px' : '6px'); }, function (props) { return (props.$isInMenu ? '0px' : '12px'); }, function (props) {
    return props.$isInMenu ? "border-bottom: 1px solid ".concat(COLORS.gray6) : "border-top: 1px solid ".concat(COLORS.gray6, ";");
}, function (props) { return (props.$isInMenu ? 50 : 60); }, COLORS.white);
export var MoreCircle = function (props) {
    var menu = React.createElement(StyledMenu, { width: 250 });
    return (React.createElement(Dropdown, { overlay: menu, placement: "bottomRight" },
        React.createElement(CircleButton, { icon: React.createElement(NotiImage, { src: IMAGES.more }) })));
};
export var ToastNotifications = function (props) {
    var _a;
    var storeId = (_a = props.manager) === null || _a === void 0 ? void 0 : _a.storeId;
    // const
    var _b = React.useState(0), openTimestamp = _b[0], setOpenTimestamp = _b[1];
    var QUERIES = [
        where('storeId', '==', storeId),
        where('sendAt', '>=', dayjs().unix() * 1000),
        limit(5),
        orderBy('sendAt', 'desc')
    ];
    var _c = React.useState([]), notifications = _c[0], setNotifications = _c[1];
    var _d = React.useState([]), shownNotiIds = _d[0], setShownNotiIds = _d[1];
    var query = getQuery("notifications-".concat(API_STAGE), QUERIES);
    var customerModal = useGlobalModal();
    React.useEffect(function () {
        onSnapshot(query, {
            next: function (snapshot) {
                var newNotis = snapshot.docs.map(function (item) {
                    return item.data();
                });
                setNotifications(newNotis.filter(function (item) { var _a, _b; return ((_b = (_a = item.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.type) === 'pc-cid' && item.sendAt > openTimestamp; }));
            }
        });
    }, []);
    React.useEffect(function () {
        var newNotis = [];
        notifications
            .filter(function (item) { var _a, _b; return ((_b = (_a = item.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.type) === 'pc-cid' && item.sendAt > openTimestamp && !shownNotiIds.includes(item.id); })
            .map(function (noti) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            if ((_b = (_a = noti === null || noti === void 0 ? void 0 : noti.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.title) {
                var customerId_1 = (_e = (_d = (_c = noti === null || noti === void 0 ? void 0 : noti.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url) === null || _e === void 0 ? void 0 : _e.split('?id=')[1];
                var phone_1 = (_h = (_g = (_f = noti === null || noti === void 0 ? void 0 : noti.payload) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.url) === null || _h === void 0 ? void 0 : _h.split('?phone=')[1];
                newNotis.push(noti.id);
                AntNotification.open({
                    key: noti.id,
                    duration: 0,
                    message: (_k = (_j = noti === null || noti === void 0 ? void 0 : noti.payload) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.title,
                    description: (_m = (_l = noti === null || noti === void 0 ? void 0 : noti.payload) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.body,
                    btn: customerId_1 ? (React.createElement(AntButton, { size: "small", onClick: function () {
                            customerModal.open({
                                type: 'customerDetail',
                                data: {
                                    customerId: customerId_1
                                }
                            });
                            AntNotification.destroy(noti.id);
                        } }, "\uACE0\uAC1D \uC0C1\uC138\uC815\uBCF4")) : phone_1 ? (React.createElement(AntButton, { size: "small", onClick: function () {
                            setSelectedCustomer({ phone: phone_1, storeId: storeId });
                            AntNotification.destroy(noti.id);
                        } }, "\uACE0\uAC1D\uB4F1\uB85D")) : null
                });
            }
        });
        setShownNotiIds(shownNotiIds.concat(newNotis));
        setOpenTimestamp(dayjs().unix() * 1000);
    }, [notifications]);
    var _e = React.useState(null), selectedCustomer = _e[0], setSelectedCustomer = _e[1];
    return (React.createElement("div", null, selectedCustomer && (React.createElement(CustomerRegister, { data: selectedCustomer, storeId: storeId, onClose: function () { return setSelectedCustomer(null); } }))));
};
var NotiImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), MediaQuery.tabletWithMobile(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 25px;\n    height: 25px;\n  "], ["\n    width: 25px;\n    height: 25px;\n  "]))));
export var NotificationCircle = function (props) {
    var _a, _b;
    var managerId = (_a = props.manager) === null || _a === void 0 ? void 0 : _a.id;
    var QUERIES = [
        and(where('sendAt', '>=', dayjs().subtract(30, 'day').unix() * 1000), or(where('storeId', '==', (_b = props.manager) === null || _b === void 0 ? void 0 : _b.storeId), where('managerId', '==', managerId))),
        limit(30),
        orderBy('sendAt', 'desc')
    ];
    var query = getQuery("notifications-".concat(API_STAGE), QUERIES);
    var docs = getDocuments("notifications-".concat(API_STAGE), QUERIES);
    React.useEffect(function () {
        onSnapshot(query, {
            next: function (snapshot) {
                var newNotis = snapshot.docs.map(function (item) {
                    return item.data();
                });
                setNewNotification(newNotis);
            }
        });
    }, []);
    var navigate = useNavigate();
    var isPC = useWindowSize().isPC;
    var _c = React.useState([]), notifications = _c[0], setNotifications = _c[1];
    var setNewNotification = function (data) {
        if (!Lodash.isEqual(data, notifications)) {
            setNotifications(data);
        }
    };
    var menu = (React.createElement(StyledMenu, { height: "90vh", width: 370 },
        React.createElement(ScrollBar, { style: { maxHeight: '100%' }, options: { overflowBehavior: { x: 'hidden' } } },
            React.createElement(TextComponent, { children: "\uC54C\uB9BC", type: isPC ? 'headline2' : 'caption2', marginBottom: 26, marginTop: 14, marginLeft: 14 }),
            Lodash.isEmpty(notifications) ? (React.createElement(Empty, { description: "\uC54C\uB9BC\uC774 \uC5C6\uC2B5\uB2C8\uB2E4." })) : (notifications.map(function (noti) { return React.createElement(NotificationItem, { navigate: navigate, key: noti.id, data: noti }); })))));
    var numberOfNew = (notifications.filter(function (notification) {
        return !notification.isRead;
    }) || []).length;
    return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown, { dropdownRender: function () { return menu; }, placement: "bottomRight", trigger: ['click'], onOpenChange: function (visible) {
                if (visible === false) {
                    docs.then(function (doc) {
                        doc.docs.map(function (doc) {
                            if (!doc.data().isRead) {
                                setDocument(doc.ref, __assign(__assign({}, doc.data()), { isRead: true }));
                            }
                        });
                    });
                }
            } },
            React.createElement(DefaultCircleButton, { type: "ghost", icon: React.createElement(NotiImage, { src: IMAGES.notification_gray }) })),
        numberOfNew ? (React.createElement(RedDot, null,
            React.createElement(TextComponent, { type: isPC ? 'caption1' : 'caption2', children: numberOfNew >= 100 ? '99+' : numberOfNew, color: COLORS.white }))) : (React.createElement("div", null))));
};
export var HelpCenterButton = function (props) {
    return (React.createElement(DefaultCircleButton, { onClick: props.onClick, type: "ghost", icon: React.createElement(NotiImage, { src: IMAGES.helpcenter }), style: { marginLeft: '5px', marginRight: '10px' } }));
};
var NotificationItem = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    var title = props.data.title || ((_c = (_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.payload) === null || _b === void 0 ? void 0 : _b.notification) === null || _c === void 0 ? void 0 : _c.title) || ((_f = (_e = (_d = props.data) === null || _d === void 0 ? void 0 : _d.payload) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.title);
    var body = props.data.body || ((_j = (_h = (_g = props.data) === null || _g === void 0 ? void 0 : _g.payload) === null || _h === void 0 ? void 0 : _h.notification) === null || _j === void 0 ? void 0 : _j.body) || ((_m = (_l = (_k = props.data) === null || _k === void 0 ? void 0 : _k.payload) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.body);
    var action = props.data.action || ((_q = (_p = (_o = props.data) === null || _o === void 0 ? void 0 : _o.payload) === null || _p === void 0 ? void 0 : _p.notification) === null || _q === void 0 ? void 0 : _q.action) || ((_t = (_s = (_r = props.data) === null || _r === void 0 ? void 0 : _r.payload) === null || _s === void 0 ? void 0 : _s.data) === null || _t === void 0 ? void 0 : _t.action);
    var url = props.data.url || ((_w = (_v = (_u = props.data) === null || _u === void 0 ? void 0 : _u.payload) === null || _v === void 0 ? void 0 : _v.notification) === null || _w === void 0 ? void 0 : _w.url) || ((_z = (_y = (_x = props.data) === null || _x === void 0 ? void 0 : _x.payload) === null || _y === void 0 ? void 0 : _y.data) === null || _z === void 0 ? void 0 : _z.url);
    var isRead = props.data.isRead;
    if (!title) {
        return React.createElement("div", null);
    }
    var isPlussharp = isPlussharpMobileApp();
    return (React.createElement(NotificationItemWrapper, { onClick: function () {
            if (action === 'navigate') {
                var URL = "https://crm.thinkofyou.kr";
                if (url) {
                    // FIXME: 개발계랑 로컬은??
                    var isExternal = /https?:\/\//g.test(url) && !(url === null || url === void 0 ? void 0 : url.includes(URL));
                    if (isExternal) {
                        if (isPlussharp) {
                            WebviewInterface.getInstance().requestPopup({
                                url: url,
                                title: title || '알림'
                            });
                        }
                        else {
                            window.open(url, '_blank');
                        }
                    }
                    else {
                        props.navigate(url.startsWith(URL) ? url.replace(URL, '') : url);
                    }
                }
            }
        } },
        React.createElement(Row, { justify: "space-between", align: "middle" },
            React.createElement(TextComponent, { type: "caption1", color: COLORS.gray1 }, title),
            !isRead && React.createElement(PrimaryDot, null)),
        React.createElement(TextComponent, { type: "body2", color: COLORS.gray2, marginTop: 10, marginBottom: 10 }, body),
        React.createElement(TextComponent, { children: dayjs.unix(props.data.sendAt / 1000).fromNow() })));
};
var NotificationItemWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-bottom: 12px;\n  margin-left: 14px;\n  margin-right: 14px;\n  padding: 20px 18px;\n  border-radius: 2px;\n  border: solid 1px var(--gray-5);\n  cursor: pointer;\n  display: flex;\n  align-items: stretch;\n  justify-content: space-between;\n  flex-direction: column;\n  &:hover {\n    border: solid 1px var(--primary-3);\n    background-color: var(--primary-5);\n  }\n"], ["\n  margin-bottom: 12px;\n  margin-left: 14px;\n  margin-right: 14px;\n  padding: 20px 18px;\n  border-radius: 2px;\n  border: solid 1px var(--gray-5);\n  cursor: pointer;\n  display: flex;\n  align-items: stretch;\n  justify-content: space-between;\n  flex-direction: column;\n  &:hover {\n    border: solid 1px var(--primary-3);\n    background-color: var(--primary-5);\n  }\n"])));
var PrimaryDot = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 10px;\n  height: 10px;\n  border-radius: 5px;\n  background-color: var(--primary-1);\n"], ["\n  width: 10px;\n  height: 10px;\n  border-radius: 5px;\n  background-color: var(--primary-1);\n"])));
var RedDot = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 27px;\n  height: 19px;\n  background-color: var(--status-danger);\n  position: absolute;\n  right: 5px;\n  top: 0px;\n  border-radius: 9.5px;\n  z-index: 999;\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 27px;\n  height: 19px;\n  background-color: var(--status-danger);\n  position: absolute;\n  right: 5px;\n  top: 0px;\n  border-radius: 9.5px;\n  z-index: 999;\n\n  ", "\n"])), MediaQuery.tabletWithMobile(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  right: -5px;\n  width: 24px;\n  height: 15px;\n  border-radius: 6.5px;\n"], ["\n  right: -5px;\n  width: 24px;\n  height: 15px;\n  border-radius: 6.5px;\n"]))));
var StyledMenu = styled(Menu)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: ", "px;\n  padding: 12px;\n  border-radius: 2px;\n  box-shadow: 0 0 5px 0 var(--primary-shadow);\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n  height: ", ";\n"], ["\n  width: ", "px;\n  padding: 12px;\n  border-radius: 2px;\n  box-shadow: 0 0 5px 0 var(--primary-shadow);\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n  height: ", ";\n"])), function (props) { return props.width || 240; }, function (props) { return props.height || 'auto'; });
var StyledMenuItem = styled(Menu.Item)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  ", "\n  ", "}\n  color: ", ";\n  padding: 10px 12px;\n  \n  & > a {\n    color: ", ";\n  }\n"], ["\n  ", "\n  ", "}\n  color: ", ";\n  padding: 10px 12px;\n  \n  & > a {\n    color: ", ";\n  }\n"])), commonCssTypography, css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    ", ";\n  "], ["\n    ", ";\n  "])), typographyByName('subtitle2')), function (props) { return props.color || COLORS.gray1; }, COLORS.gray1);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
