import React from 'react';
export function FloatingAction(props) {
    return (
    // <Box className={classes.container}>
    //   <Slide appear={true} direction="up" in={props.in}>
    //     <AppBar position="fixed" color="primary" className={classes.appBar}>
    //       <Box flexDirection="row" display="flex" justifyContent="flex-end">
    //         {props.actions.map((action) => action)}
    //       </Box>
    //     </AppBar>
    //   </Slide>
    // </Box>
    React.createElement("div", null));
}
