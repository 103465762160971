var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
export var ZIndexContext = React.createContext({
    zIndexes: [],
    addZIndex: function (_) { },
    removeZIndex: function (_) { }
});
export var ZIndexProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState([]), zIndexes = _b[0], setZIndexes = _b[1];
    var addZIndex = function (zIndex) {
        setZIndexes(function (zIndexes) { return __spreadArray(__spreadArray([], zIndexes, true), [zIndex], false); });
    };
    var removeZIndex = function (zIndex) {
        setZIndexes(function (zIndexes) {
            var index = zIndexes.findIndex(function (usedZIndex) { return usedZIndex === zIndex; });
            return zIndexes.filter(function (_, i) { return i !== index; });
        });
    };
    var value = { zIndexes: zIndexes, addZIndex: addZIndex, removeZIndex: removeZIndex };
    return React.createElement(ZIndexContext.Provider, { value: value }, children);
};
var DEFAULT_Z_INDEX = 1000;
export var ZIndexer = function (_a) {
    var children = _a.children;
    var _b = React.useState(-1), zIndex = _b[0], setZIndex = _b[1];
    var _c = React.useContext(ZIndexContext), zIndexes = _c.zIndexes, addZIndex = _c.addZIndex, removeZIndex = _c.removeZIndex;
    React.useEffect(function () {
        var hasLastIndex = zIndexes.length > 0;
        var nextZIndex = hasLastIndex ? zIndexes[zIndexes.length - 1] + 1 : DEFAULT_Z_INDEX;
        setZIndex(nextZIndex);
        addZIndex(nextZIndex);
        return function () {
            removeZIndex(nextZIndex);
        };
    }, []);
    return children({ zIndex: zIndex });
};
