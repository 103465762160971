var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Menu from 'antd/lib/menu';
import Row from 'antd/lib/row';
import React from 'react';
import { AdNotice, BigTitleModal, ButtonWithPlusCircle, FormItemWithGrayBoarder, HalfWrapper, Marginer, SelectMessageType, TextComponent, useEntireLoader, Icon, checkSMSService, TimePickerAutoaccept, EntireLoader, GUIDE_NAMES, SettingTemplate, XScrollableTable, AlertSentenceWithIcon, TextWithTooltip } from 'renderer/components';
import { AUTO_MESSAGE_TEMPLATE_LIST, AutoMessageSettingModel, DEFAULT_VARIABLES } from 'renderer/models';
import { createUUID, isEmpty, Lodash, uploadFileToMessageImageDirectory, useWindowSize } from 'renderer/utils';
import styled from 'styled-components';
import { Col } from 'antd/lib/grid';
import Switch from 'antd/lib/switch';
import Radio from 'antd/lib/radio';
import dayjs from 'dayjs';
import { IMAGES } from 'renderer/assets';
import Select from 'antd/lib/select';
import { COLORS } from 'renderer/styles';
import Form from 'antd/lib/form';
import { ProductQuery, MessageTemplateQuery, StoreQuery } from 'renderer/queries';
import { DATE_FORMAT, DISABLED_HOURS } from 'renderer/constants';
import notification from 'antd/lib/notification';
import { MessageContentEditor } from 'renderer/containers/app/Message/MessageTemplates';
import { useRecoilState } from 'recoil';
import { Atoms } from 'renderer/stores';
import Checkbox from 'antd/lib/checkbox/Checkbox';
export var AutoMessageSetting = function (props) {
    var _a;
    var store = StoreQuery.getMyStoreInCache();
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var isPC = useWindowSize().isPC;
    var _b = React.useState(), settings = _b[0], setSettings = _b[1];
    var columns = [
        {
            title: '사용여부',
            dataIndex: 'isActive',
            key: 'isActive',
            width: 80,
            render: function (value) { return (value ? '사용' : '미사용'); }
        },
        {
            title: '유형',
            dataIndex: 'type',
            key: 'type',
            width: 50,
            render: function (_, record) { return record.typeString; }
        },
        {
            title: '자동발송시간',
            dataIndex: 'sendAt',
            key: 'sendAt',
            width: 100,
            render: function (_, record) { return record.sendOptionString; }
        },
        {
            title: '발송문구',
            dataIndex: ['content', 'body'],
            key: 'content.body',
            width: 900
            // render: (value) => value
        },
        {
            title: '설정 저장일',
            width: 100,
            dataIndex: ['createdAt'],
            key: 'createdAt',
            render: function (value) { return dayjs(value).format(DATE_FORMAT); }
        }
    ].filter(Boolean);
    var _c = React.useState([
        AutoMessageSettingModel.AUTO_MESSAGE_KEYS.PENDING_RESERVATION_NOTIFICATION
    ]), selectedKeys = _c[0], setSelectedKeys = _c[1];
    var selectedKey = selectedKeys && selectedKeys[0];
    var atsTemplates = MessageTemplateQuery.useATSTemplates(storeId);
    var autoSettings = MessageTemplateQuery.useGetAutoSettings(storeId);
    var deleteSetting = MessageTemplateQuery.useDeleteAutoMessageSetting();
    var handleDelete = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deleteSetting.mutateAsync({ id: data.id, storeId: storeId })];
                case 1:
                    _a.sent();
                    notification.success({ message: "".concat(data.title, " \uC790\uB3D9\uBC1C\uC1A1 \uC124\uC815\uC774 \uC0AD\uC81C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.") });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    notification.error({ message: error_1.message });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _d = useRecoilState(Atoms.globalSettingModalState), _ = _d[0], openSettings = _d[1];
    var getCount = function (key) {
        var _a, _b;
        return ((_b = (_a = autoSettings === null || autoSettings === void 0 ? void 0 : autoSettings.data) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.key === key && item.isActive; })) === null || _b === void 0 ? void 0 : _b.length) || 0;
    };
    return storeId ? (React.createElement(SettingTemplate, { title: "\uBB38\uC790 \uC790\uB3D9\uBC1C\uC1A1 \uC124\uC815", guideName: GUIDE_NAMES.문자자동발송설정, description: "\uC608\uC57D, \uB9E4\uCD9C, \uC120\uBD88\uC561\uB4F1 \uB9E4\uC7A5\uC744 \uC6B4\uC601\uD558\uBA70 \uC0DD\uAE30\uB294 \uB2E4\uC591\uD55C \uC0C1\uD669\uC5D0 \uB9DE\uCDB0 \uC790\uB3D9\uC73C\uB85C \uC18C\uBE44\uC790\uC5D0\uAC8C \uBB38\uC790/\uC54C\uB9BC\uD1A1\uC744 \uC804\uB2EC\uD574\uB4DC\uB9BD\uB2C8\uB2E4!", body: React.createElement("div", null,
            React.createElement(AutoSendModalForNIT, { atsTempltes: atsTemplates.data, settings: settings, store: store, onClose: function () { return setSettings(undefined); } }),
            !isPC && (React.createElement(Select, { style: { width: '100%' }, value: selectedKeys && selectedKeys[0], onChange: function (value) {
                    setSelectedKeys([value]);
                } }, AUTO_MESSAGE_TEMPLATE_LIST.map(function (menu) {
                var count = getCount(menu.key);
                return (React.createElement(StyledSelectOption, { key: menu.key },
                    React.createElement(Row, { align: "middle", justify: "space-between", style: { flex: 1, height: '100%' } },
                        React.createElement(Col, { flex: 1 },
                            React.createElement(TextComponent, { children: menu.title, className: "menuText" })),
                        React.createElement(Col, null,
                            React.createElement(Row, null,
                                !menu.isAd && React.createElement("img", { src: IMAGES.kakao_icon, style: { marginRight: '5px' } }),
                                React.createElement(AmountNumber, { children: count }))))));
            }))),
            React.createElement(Row, { gutter: 16, wrap: false },
                isPC && (React.createElement(Col, null,
                    React.createElement(MenuTitle, null,
                        React.createElement(TextComponent, { color: COLORS.gray1, children: "\uBB38\uC790\uBC1C\uC1A1 \uD56D\uBAA9", type: "headline3" })),
                    React.createElement(StyledMenu, { style: { width: 210 }, selectedKeys: selectedKeys, onSelect: function (info) {
                            setSelectedKeys(info.selectedKeys);
                        }, mode: "vertical" }, AUTO_MESSAGE_TEMPLATE_LIST.map(function (menu) {
                        var count = getCount(menu.key);
                        return (React.createElement(StyledMenuItem, { key: menu.key },
                            React.createElement(Row, { wrap: false },
                                React.createElement(TextComponent, { children: menu.title, className: "menuText" }),
                                !menu.isAd && React.createElement("img", { src: IMAGES.kakao_icon }),
                                React.createElement(AmountNumber, { children: count }))));
                    })))),
                React.createElement(Col, { flex: 1 },
                    React.createElement(TableWrapper, null,
                        React.createElement(ButtonWithPlusCircle, { children: "\uC790\uB3D9\uBC1C\uC1A1 \uC124\uC815", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var result;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, checkSMSService(store, openSettings)];
                                        case 1:
                                            result = _a.sent();
                                            if (result) {
                                                setSettings({ key: selectedKey });
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }),
                        React.createElement(XScrollableTable, { id: "TABLE_ID_AUTOMESSAGE_SETTING", actions: [
                                { title: '수정', onClick: function (data) { return setSettings(data); } },
                                { title: '삭제', onClick: handleDelete }
                            ], resizable: {
                                additionalColumnWidth: 0
                            }, bordered: false, columns: columns, key: selectedKey, dataSource: (_a = autoSettings === null || autoSettings === void 0 ? void 0 : autoSettings.data) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.key === selectedKey; }) }))))) })) : (React.createElement(EntireLoader, { open: true }));
};
var ProductSelector = function (props) {
    var _a, _b, _c;
    var productQuery = ProductQuery.useProducts({ storeId: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id, paranoid: true }, { enabled: !!((_b = props.store) === null || _b === void 0 ? void 0 : _b.id) });
    var isAll = props.form.getFieldValue(['sendOption', 'allProducts']);
    return (React.createElement(FormItemWithGrayBoarder, { title: "\uC0C1\uD488 \uC120\uD0DD" },
        React.createElement(Form.Item, { name: ['sendOption', 'allProducts'], rules: [{ required: true, message: '문자발송을 원하는 상품을 선택해주세요.' }] },
            React.createElement(Radio.Group, null,
                React.createElement(Radio, { value: true }, "\uC804\uCCB4 \uC0C1\uD488"),
                React.createElement(Radio, { value: false }, "\uC0C1\uD488 \uC120\uD0DD"))),
        !isAll && (React.createElement(Form.Item, { name: ['sendOption', 'productIds'], rules: [{ required: true, message: '문자발송을 원하는 상품을 선택해주세요.' }] },
            React.createElement(Select, { mode: "multiple", style: { width: '100%', maxHeight: '240px', overflowY: 'overlay' }, placeholder: "\uBB38\uC790\uBC1C\uC1A1\uC744 \uC6D0\uD558\uB294 \uC0C1\uD488\uC744 \uC120\uD0DD\uD574\uC8FC\uC138\uC694.", optionLabelProp: "label" }, (_c = productQuery.data.products) === null || _c === void 0 ? void 0 : _c.map(function (product) {
                return (React.createElement(Select.Option, { key: product.id, label: product.nameWithGroupName, children: product.nameWithGroupName, value: product.id }));
            })))),
        React.createElement(AlertSentenceWithIcon, { descriptions: [
                isAll
                    ? "\uC0C1\uD488 \uB9E4\uCD9C\uC774 \uBC1C\uC0DD\uD560 \uACBD\uC6B0 \uBB38\uC790\uAC00 \uBC1C\uC1A1\uB429\uB2C8\uB2E4."
                    : "\uC120\uD0DD\uD55C \uC0C1\uD488\uC774 \uD3EC\uD568\uB41C \uB9E4\uCD9C\uC774 \uBC1C\uC0DD\uD560 \uACBD\uC6B0 \uBB38\uC790\uAC00 \uBC1C\uC1A1\uB429\uB2C8\uB2E4."
            ] })));
};
var PreventToDormancy = function (props) {
    var isAd = props.form.getFieldValue('isAd');
    return isAd ? (React.createElement(FormItemWithGrayBoarder, { title: "\uBC1C\uC1A1 \uACE0\uAC1D \uC124\uC815" },
        React.createElement(Row, { align: "middle" },
            React.createElement(Form.Item, { name: ['sendOption', 'preventSendDormancy'], rules: [{ type: 'boolean' }] },
                React.createElement(Switch, { checked: props.form.getFieldValue(['sendOption', 'preventSendDormancy']) })),
            React.createElement(TextComponent, { children: "\uAD11\uACE0\uC131 \uBB38\uC790 \uBD88\uAC00\uB2A5 \uACE0\uAC1D \uC81C\uC678", marginLeft: 12, marginBottom: 10 })),
        React.createElement(Row, { align: "top", wrap: false },
            React.createElement(Col, { span: 1 },
                React.createElement(Icon, { name: "alert-triangle", color: COLORS.calendarRedActive })),
            React.createElement(TextComponent, { type: "caption1", color: COLORS.calendarRedActive, children: "\uBBF8\uC124\uC815 \uC2DC \uCD5C\uADFC \uC7AC\uD654\uAC70\uB798(\uB9E4\uCD9C\uB4F1\uB85D)\uC77C\uC774 6\uAC1C\uC6D4\uC774 \uC9C0\uB09C \uACE0\uAC1D\uC5D0\uAC8C \uAD11\uACE0\uBB38\uC790\uAC00 \uBC1C\uC1A1\uB420 \uC218 \uC788\uC73C\uBA70, \uAD11\uACE0\uC131\uBB38\uC790 \uC804\uC1A1\uADDC\uC815(\uC815\uBCF4\uD1B5\uC2E0\uB9DD\uBC95 \uC81C 61\uC870)\uC744 \uC704\uBC18\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.", marginLeft: 5 })))) : (React.createElement("div", null));
};
var ScheduleSetter = function (props) {
    var _a, _b, _c;
    var templateItem = props.autoTemplate;
    var type = props.form.getFieldValue(['sendOption', 'type']);
    var sendOption = templateItem.sendAtOptions.find(function (item) { return item.type === type; });
    var isFixed = (_a = sendOption === null || sendOption === void 0 ? void 0 : sendOption.type) === null || _a === void 0 ? void 0 : _a.startsWith('fixed-');
    var isAd = props.form.getFieldValue(['isAd']);
    return (React.createElement(FormItemWithGrayBoarder, { title: "\uC804\uC1A1 \uC2DC\uAC04" },
        React.createElement(Form.Item, { name: ['sendOption', 'type'], rules: [{ required: true }] },
            React.createElement(Radio.Group, null, (_b = templateItem === null || templateItem === void 0 ? void 0 : templateItem.sendAtOptions) === null || _b === void 0 ? void 0 : _b.map(function (item) {
                return (React.createElement(Radio, { key: item.type, value: item.type, onChange: function (ev) {
                        var _a;
                        var sendOption = templateItem.sendAtOptions.find(function (item) { return item.type === ev.target.value; });
                        if (sendOption && sendOption.items) {
                            props.form.setFieldValue(['sendOption', sendOption.valueUnit], (_a = sendOption.items[0]) === null || _a === void 0 ? void 0 : _a.value);
                        }
                    } }, item.label));
            }))),
        React.createElement(Row, { gutter: 8, wrap: false },
            (sendOption === null || sendOption === void 0 ? void 0 : sendOption.items) && (React.createElement(Col, { flex: 1 },
                React.createElement(Form.Item, { name: ['sendOption', sendOption === null || sendOption === void 0 ? void 0 : sendOption.valueUnit], rules: [{ required: true, message: '자동 발송 시간을 선택해주세요.' }] },
                    React.createElement(Select, null, (_c = sendOption === null || sendOption === void 0 ? void 0 : sendOption.items) === null || _c === void 0 ? void 0 : _c.map(function (item, index) { return (React.createElement(Select.Option, { key: index, value: item.value }, item.label)); }))))),
            isFixed && (React.createElement(Col, { flex: 1 },
                React.createElement(Form.Item, { name: ['sendOption', 'sendAt'], rules: [
                        {
                            required: true,
                            validator: function (_, value) {
                                if (!value) {
                                    return Promise.reject('자동 발송 시간을 선택해주세요.');
                                }
                                var isAd = props.form.getFieldValue(['isAd']);
                                if (isAd && DISABLED_HOURS.includes(value.hour())) {
                                    return Promise.reject('전송시간을 확인해주세요.');
                                }
                                else {
                                    return Promise.resolve();
                                }
                            }
                        }
                    ] },
                    React.createElement(TimePickerAutoaccept, { format: "HH:mm", showNow: false, placeholder: "\uC2DC\uAC04\uC120\uD0DD", disabledTime: function (day) { return ({
                            disabledHours: function () { return (isAd ? DISABLED_HOURS : []); }
                        }); } }))))),
        templateItem.key === AutoMessageSettingModel.AUTO_MESSAGE_KEYS.RESERVATION_NOTIFICATION && (React.createElement(Form.Item, { name: ['sendOption', 'preventSendAfterTime'] },
            React.createElement(Checkbox, { checked: props.form.getFieldValue(['sendOption', 'preventSendAfterTime']), onChange: function (ev) {
                    props.form.setFieldValue(['sendOption', 'preventSendAfterTime'], ev.target.checked);
                } },
                React.createElement(TextWithTooltip, { children: "\uBC1C\uC1A1(\uC608\uC57D) \uC2DC\uAC04 \uC774\uD6C4 \uC989\uC2DC \uBC1C\uC1A1 \uAE08\uC9C0", tooltipTitle: "\uC804\uC1A1\uC608\uC815 \uC2DC\uAC04\uC774 \uC9C0\uB098\uACE0 \uB4F1\uB85D\uD558\uB294 \uC608\uC57D \uAC74 \uC911 \uC989\uC2DC \uBC1C\uC1A1\uC744 \uC6D0\uCE58 \uC54A\uB294 \uACBD\uC6B0 \uCCB4\uD06C" })))),
        isAd && (React.createElement(Row, { align: "middle" },
            React.createElement(Row, { align: "middle" },
                React.createElement(Icon, { name: "alert-triangle", color: COLORS.info }),
                React.createElement(TextComponent, { type: "caption1", color: COLORS.info, children: '광고문자 전송 시간', marginLeft: 5 })),
            React.createElement(TextComponent, { type: "caption1", color: COLORS.info, children: "'오후 9시~오전 8시'사이에는 광고성 메세지 발송이 제한됩니다. 해당 시간에 발송 시도된 문자는 발송취소되며 과금되지 않습니다. 발송취소된 문자는 문자내역 페이지에서 확인할 수 있습니다.", marginLeft: 5 })))));
};
var AlimtalContentDiv = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  white-space: pre-line;\n  height: 450px;\n  overflow-y: scroll;\n  border-radius: 4px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n  padding: 10px;\n"], ["\n  white-space: pre-line;\n  height: 450px;\n  overflow-y: scroll;\n  border-radius: 4px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n  padding: 10px;\n"])));
var AmountNumber = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border-radius: 11px;\n  background-color: var(--primary-4);\n  height: 22px;\n  width: 35px;\n  visibility: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border-radius: 11px;\n  background-color: var(--primary-4);\n  height: 22px;\n  width: 35px;\n  visibility: ", ";\n"])), function (props) { return (props.children != '0' ? 'visible' : 'hidden'); });
var SelectOption = styled(Select.Option)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var MenuTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 46px;\n  padding-left: 16px;\n  display: flex;\n  align-items: center;\n  border: solid 1px var(--gray-6);\n  background-color: var(--gray-7);\n"], ["\n  height: 46px;\n  padding-left: 16px;\n  display: flex;\n  align-items: center;\n  border: solid 1px var(--gray-6);\n  background-color: var(--gray-7);\n"])));
var StyledMenu = styled(Menu)(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var StyledMenuItem = styled(Menu.Item)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex !important;\n  margin-inline: 0px;\n  align-items: center !important;\n  border-radius: 0px !important;\n  jusfity-content: space-between;\n  background-color: var(--white);\n  margin-bottom: 0px !important;\n  margin-top: 0px !important;\n  border: solid 1px var(--gray-6);\n  border-bottom: solid 0px var(--gray-6);\n  width: 100% !important;\n  margin-left: 0px !important;\n\n  &:last-child {\n    border-bottom: solid 1px var(--gray-6);\n  }\n\n  & > img {\n    margin-right: 5px;\n  }\n\n  & .menuText {\n    width: 125px;\n  }\n"], ["\n  display: flex !important;\n  margin-inline: 0px;\n  align-items: center !important;\n  border-radius: 0px !important;\n  jusfity-content: space-between;\n  background-color: var(--white);\n  margin-bottom: 0px !important;\n  margin-top: 0px !important;\n  border: solid 1px var(--gray-6);\n  border-bottom: solid 0px var(--gray-6);\n  width: 100% !important;\n  margin-left: 0px !important;\n\n  &:last-child {\n    border-bottom: solid 1px var(--gray-6);\n  }\n\n  & > img {\n    margin-right: 5px;\n  }\n\n  & .menuText {\n    width: 125px;\n  }\n"])));
var TableWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex: 1;\n  width: 100%;\n  display: flex;\n  align-items: flex-end;\n  flex-direction: column;\n\n  & > table {\n    width: 100%;\n  }\n"], ["\n  flex: 1;\n  width: 100%;\n  display: flex;\n  align-items: flex-end;\n  flex-direction: column;\n\n  & > table {\n    width: 100%;\n  }\n"])));
var StyledSelectOption = styled(SelectOption)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var AutoSendModalForNIT = function (props) {
    var _a, _b;
    var _c = useWindowSize(), isPC = _c.isPC, height = _c.height, width = _c.width;
    var bodyStyle = isPC ? { height: '800px' } : { height: "".concat(height - 150, "px"), overflow: 'scroll' };
    var form = Form.useForm()[0];
    var create = MessageTemplateQuery.useCreateAutoMessageSetting();
    var update = MessageTemplateQuery.useUpdateAutoMessageSetting();
    var loader = useEntireLoader();
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var content, image, template, uploadResult, er_1, model, title, isAd, key, payload, isUpdated, error_2;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    loader.show();
                    image = (_a = values.content) === null || _a === void 0 ? void 0 : _a.image;
                    if (!(values.type === 'ats' && ((_b = values.content) === null || _b === void 0 ? void 0 : _b.templateCode))) return [3 /*break*/, 1];
                    template = atsTemplates.find(function (item) {
                        var _a, _b;
                        return ((_a = item.content) === null || _a === void 0 ? void 0 : _a.templateCode) === ((_b = values.content) === null || _b === void 0 ? void 0 : _b.templateCode);
                    });
                    content = __assign(__assign({}, template === null || template === void 0 ? void 0 : template.content), { profileKey: (_c = values.content) === null || _c === void 0 ? void 0 : _c.profileKey });
                    image = '';
                    return [3 /*break*/, 5];
                case 1:
                    content = values.content;
                    if (!(image && typeof image !== 'string' && image[0] && image[0].originFileObj)) return [3 /*break*/, 5];
                    _h.label = 2;
                case 2:
                    _h.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, uploadFileToMessageImageDirectory(image[0].originFileObj, createUUID())];
                case 3:
                    uploadResult = _h.sent();
                    image = (uploadResult === null || uploadResult === void 0 ? void 0 : uploadResult.location) || '';
                    return [3 /*break*/, 5];
                case 4:
                    er_1 = _h.sent();
                    image = '';
                    return [3 /*break*/, 5];
                case 5:
                    model = autoSettingTemplate.model, title = autoSettingTemplate.title, isAd = autoSettingTemplate.isAd, key = autoSettingTemplate.key;
                    payload = __assign(__assign({}, props.settings), { content: __assign(__assign({}, content), { image: image }), model: model, title: title, isAd: typeof values.isAd === 'boolean' ? values.isAd : isAd, key: key, type: values.type, sendOption: __assign(__assign({}, values.sendOption), { sendAt: (_e = (_d = values.sendOption) === null || _d === void 0 ? void 0 : _d.sendAt) === null || _e === void 0 ? void 0 : _e.toDate(), sendMessageOnFailAlimtalk: values.sendMessageOnFailAlimtalk, target: (_f = autoSettingTemplate.sendAtOptions.find(function (item) { return values.sendOption.type === item.type; })) === null || _f === void 0 ? void 0 : _f.target }), storeId: (_g = props.store) === null || _g === void 0 ? void 0 : _g.id, isActive: values.isActive });
                    isUpdated = !!props.settings.id;
                    _h.label = 6;
                case 6:
                    _h.trys.push([6, 11, , 12]);
                    if (!isUpdated) return [3 /*break*/, 8];
                    return [4 /*yield*/, update.mutateAsync(payload)];
                case 7:
                    _h.sent();
                    return [3 /*break*/, 10];
                case 8: return [4 /*yield*/, create.mutateAsync(payload)];
                case 9:
                    _h.sent();
                    _h.label = 10;
                case 10:
                    notification.success({ message: "".concat(title, " \uC790\uB3D9\uBC1C\uC1A1\uC774 \uC124\uC815\uB418\uC5C8\uC2B5\uB2C8\uB2E4.") });
                    return [3 /*break*/, 12];
                case 11:
                    error_2 = _h.sent();
                    notification.error({ message: error_2.message });
                    return [3 /*break*/, 12];
                case 12:
                    loader.hide();
                    props.onClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var autoSettingTemplate = AUTO_MESSAGE_TEMPLATE_LIST.find(function (item) { var _a; return item.key === ((_a = props.settings) === null || _a === void 0 ? void 0 : _a.key); });
    var atsTemplates = (props.atsTempltes || []).filter(function (template) {
        return (template.isATSTemplate &&
            ((autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.title) === (template === null || template === void 0 ? void 0 : template.title) ||
                (template === null || template === void 0 ? void 0 : template.title.match(new RegExp("".concat(autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.title, "\\d"), 'g')))));
    });
    var isAlimtalk = form.getFieldValue('type') === 'ats';
    var autoSettingContent = form.getFieldValue(['content']);
    var _d = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _d[1];
    var defaultSendOption = autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.sendAtOptions[0];
    var sendOptionType = defaultSendOption === null || defaultSendOption === void 0 ? void 0 : defaultSendOption.type;
    React.useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var type = ((_a = props.settings) === null || _a === void 0 ? void 0 : _a.isAd) ? 'message' : !isEmpty(atsTemplates) ? 'ats' : 'message';
        var isAlimtalk = type === 'ats';
        var DEFAULT_VALUES = {
            type: type,
            key: autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.key,
            model: autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.model,
            title: isAlimtalk ? atsTemplates && atsTemplates[0] && atsTemplates[0].title : autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.title,
            content: isAlimtalk ? atsTemplates && atsTemplates[0] && atsTemplates[0].content : autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.content,
            isAd: ((_b = props.settings) === null || _b === void 0 ? void 0 : _b.id) ? (_c = props.settings) === null || _c === void 0 ? void 0 : _c.isAd : (autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.isAd) || false,
            isActive: true,
            sendOption: sendOptionType === 'fixed-before' || sendOptionType === 'fixed-after'
                ? {
                    type: sendOptionType,
                    sendAt: dayjs().startOf('day').add(9, 'hour'),
                    day: 0,
                    allProducts: true,
                    preventSendAfterTime: autoSettingTemplate.key === AutoMessageSettingModel.AUTO_MESSAGE_KEYS.RESERVATION_NOTIFICATION
                }
                : { type: sendOptionType, hour: 0, allProducts: true }
        };
        form.resetFields();
        form.setFieldsValue(((_d = props.settings) === null || _d === void 0 ? void 0 : _d.id)
            ? __assign(__assign({}, props.settings), { key: autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.key, model: autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.model, title: isAlimtalk
                    ? ((_e = props.settings) === null || _e === void 0 ? void 0 : _e.title) || (atsTemplates && atsTemplates[0] && atsTemplates[0].title)
                    : ((_f = props.settings) === null || _f === void 0 ? void 0 : _f.content) || (autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.title), content: isAlimtalk
                    ? ((_g = props.settings) === null || _g === void 0 ? void 0 : _g.content) || (atsTemplates && atsTemplates[0] && atsTemplates[0].content)
                    : ((_h = props.settings) === null || _h === void 0 ? void 0 : _h.content) || (autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.content), isAd: ((_j = props.settings) === null || _j === void 0 ? void 0 : _j.id) ? (_k = props.settings) === null || _k === void 0 ? void 0 : _k.isAd : (autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.isAd) || false, sendMessageOnFailAlimtalk: (_m = (_l = props.settings) === null || _l === void 0 ? void 0 : _l.sendOption) === null || _m === void 0 ? void 0 : _m.sendMessageOnFailAlimtalk, sendOption: ((_o = props.settings.sendOption) === null || _o === void 0 ? void 0 : _o.sendAt)
                    ? __assign(__assign({}, props.settings.sendOption), { allProducts: !!((_p = props.settings.sendOption) === null || _p === void 0 ? void 0 : _p.allProducts), sendAt: dayjs((_q = props.settings.sendOption) === null || _q === void 0 ? void 0 : _q.sendAt) }) : __assign(__assign({}, props.settings.sendOption), { allProducts: !!((_r = props.settings.sendOption) === null || _r === void 0 ? void 0 : _r.allProducts) }) }) : DEFAULT_VALUES);
        forceUpdate();
        console.log(isAlimtalk, props.settings, atsTemplates, DEFAULT_VALUES);
    }, [props.settings, props.atsTempltes]);
    return (React.createElement(BigTitleModal, { centered: true, bodyStyle: bodyStyle, open: !!props.settings, onCancel: props.onClose, title: "".concat(autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.title, " \uC790\uB3D9\uBC1C\uC1A1 \uC124\uC815"), width: isPC ? 800 : width, okText: "\uC800\uC7A5", cancelText: "\uB2EB\uAE30", onOk: form.submit, children: props.settings && (React.createElement("div", { style: { padding: '10px' } },
            React.createElement(Form, { form: form, onValuesChange: function (changed, value) {
                    var _a, _b, _c;
                    if (changed.type === 'ats' || ((_a = changed.content) === null || _a === void 0 ? void 0 : _a.templateCode)) {
                        if ((_b = changed.content) === null || _b === void 0 ? void 0 : _b.templateCode) {
                            var template = atsTemplates.find(function (item) {
                                var _a, _b;
                                return ((_a = item.content) === null || _a === void 0 ? void 0 : _a.templateCode) === ((_b = changed.content) === null || _b === void 0 ? void 0 : _b.templateCode);
                            });
                            // forceUpdate();
                            form.setFieldsValue({ isAd: false, content: template.content });
                        }
                        else {
                            form.setFieldsValue({ isAd: false, content: (_c = atsTemplates[0]) === null || _c === void 0 ? void 0 : _c.content });
                        }
                    }
                    forceUpdate();
                }, onFinish: handleSubmit },
                React.createElement(Row, { gutter: isPC ? 16 : 0, style: { width: '100%' } },
                    React.createElement(HalfWrapper, { span: isPC ? 12 : 24 },
                        React.createElement(AdNotice, null),
                        React.createElement(Marginer, { height: 12 }),
                        React.createElement(SelectMessageType, { form: form, atsTemplates: atsTemplates, hideType: ['fts'], store: props.store, disabledType: !!isEmpty(atsTemplates) || props.settings.isAd ? ['ats'] : [] }),
                        React.createElement(Marginer, { height: 12 }),
                        isAlimtalk ? (React.createElement(AlimtalContentDiv, null, autoSettingContent === null || autoSettingContent === void 0 ? void 0 : autoSettingContent.body)) : (React.createElement(MessageContentEditor, { variables: ((autoSettingTemplate === null || autoSettingTemplate === void 0 ? void 0 : autoSettingTemplate.variables) || DEFAULT_VARIABLES), onChangeContent: forceUpdate, availables: {
                                templateSaver: false,
                                templateSelector: false
                            }, template: (((_a = props.settings) === null || _a === void 0 ? void 0 : _a.id) ? props.settings : autoSettingTemplate), form: form, disabled: isAlimtalk }))),
                    React.createElement(HalfWrapper, { span: isPC ? 12 : 24 },
                        ((_b = autoSettingTemplate.data) === null || _b === void 0 ? void 0 : _b.hasProductIds) && React.createElement(ProductSelector, { store: props.store, form: form }),
                        React.createElement(Marginer, { height: 12 }),
                        React.createElement(ScheduleSetter, { store: props.store, autoTemplate: autoSettingTemplate, form: form }),
                        React.createElement(Marginer, { height: 12 }),
                        React.createElement(PreventToDormancy, { form: form }),
                        React.createElement(Marginer, { height: 12 }),
                        React.createElement(FormItemWithGrayBoarder, { title: "\uC790\uB3D9\uBC1C\uC1A1 \uD65C\uC131\uD654" },
                            React.createElement(Row, { align: "middle" },
                                React.createElement(Form.Item, { name: ['isActive'], rules: [{ type: 'boolean', required: true }] },
                                    React.createElement(Switch, { checked: form.getFieldValue('isActive') })),
                                React.createElement(TextComponent, { children: "\uC0AC\uC6A9\uC5EC\uBD80", marginLeft: 12, marginBottom: 10 })),
                            !Lodash.isEmpty(autoSettingTemplate.notices) ? (React.createElement(React.Fragment, null,
                                React.createElement(Row, { align: "middle" },
                                    React.createElement(Icon, { name: "alert-triangle", color: COLORS.info }),
                                    React.createElement(TextComponent, { type: "caption1", color: COLORS.info, children: "".concat(autoSettingTemplate.title, " \uC548\uB0B4"), marginLeft: 5 })),
                                autoSettingTemplate.notices.map(function (item, index) {
                                    return (React.createElement(TextComponent, { key: index, color: COLORS.info, children: "\u2022 ".concat(item), type: "caption1", marginLeft: 10, marginBottom: 5 }));
                                }))) : (React.createElement("div", null)))))))) }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
