var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { TextComponent, TimePickerAutoaccept, typographyByName } from 'renderer/components';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Radio from 'antd/lib/radio';
import Row from 'antd/lib/row';
import { dateToHHmm, convertHHmmToDate } from 'renderer/utils/dayjs';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';
var DAYS = [
    { key: 'monday', label: '월' },
    { key: 'tuesday', label: '화' },
    { key: 'wednesday', label: '수' },
    { key: 'thursday', label: '목' },
    { key: 'friday', label: '금' },
    { key: 'saturday', label: '토' },
    { key: 'sunday', label: '일' }
];
export var EverydayTimeSetter = function (props) {
    var operatingHour = props.operatingHour || {};
    var operatingHourArray = operatingHour && Object.keys(operatingHour).map(function (key) { return operatingHour[key]; });
    var isAll = operatingHourArray === null || operatingHourArray === void 0 ? void 0 : operatingHourArray.every(function (hour) {
        return !(hour === null || hour === void 0 ? void 0 : hour.isActive) || !operatingHourArray.find(function (item) { return item.start !== hour.start || item.end !== hour.end; });
    });
    var _a = React.useState(isAll ? 'all' : 'one'), mode = _a[0], setMode = _a[1];
    React.useEffect(function () {
        setMode(isAll ? 'all' : 'one');
    }, [isAll]);
    var handleChange = function (data) {
        if (data === void 0) { data = {}; }
        props.onChange(data);
    };
    return (React.createElement("div", null,
        React.createElement(TextComponent, { marginBottom: 5, type: "subtitle3" }, "\uC608\uC57D\uAC00\uB2A5 \uC2DC\uAC04*"),
        React.createElement(Row, { align: "middle", style: { marginBottom: '20px' } },
            React.createElement(Col, { span: 24 },
                React.createElement(Radio.Group, { value: mode, name: "radiogroup", onChange: function (e) {
                        setMode(e.target.value);
                        if (e.target.value === 'all') {
                            var targetValue_1 = (props.operatingHour && props.operatingHour.monday) || {
                                isActive: true,
                                start: '09:00',
                                end: '18:00'
                            };
                            var newOperatingHours_1 = {};
                            Object.keys(props.operatingHour).map(function (key) {
                                newOperatingHours_1[key] = targetValue_1;
                            });
                            if (props.onChange) {
                                props.onChange(newOperatingHours_1);
                            }
                        }
                    } },
                    React.createElement(Radio, { value: "all" }, "\uC77C\uAD04 \uC124\uC815"),
                    React.createElement(Radio, { value: "one" }, "\uC694\uC77C \uBCC4 \uC124\uC815")))),
        mode === 'all' && (React.createElement(AllSetter, { timeStep: props.timeStep, disabledHours: props.disabledHours, operatingHour: props.operatingHour, onChange: handleChange })),
        mode === 'one' && (React.createElement(React.Fragment, null,
            React.createElement(TextComponent, { marginBottom: 5, type: "subtitle3" }, "\uC608\uC57D\uAC00\uB2A5 \uC694\uC77C*"),
            React.createElement(Row, { align: "top" },
                React.createElement(Col, { span: 24 }, DAYS.map(function (day) {
                    var targetDay = operatingHour[day.key];
                    var isActive = targetDay === null || targetDay === void 0 ? void 0 : targetDay.isActive;
                    return (React.createElement(Row, { wrap: false, gutter: 8, style: { marginBottom: '5px' } },
                        React.createElement(Col, null,
                            React.createElement(DayButton, { key: day.key, type: isActive ? 'primary' : 'default', onClick: function () {
                                    var newOperatingDay = __assign({}, operatingHour);
                                    newOperatingDay[day.key] = __assign(__assign({}, targetDay), { isActive: !isActive });
                                    handleChange(newOperatingDay);
                                } }, day.label)),
                        React.createElement(Col, null,
                            React.createElement(TimeRangeInputs, { data: targetDay, timePickerProps: {
                                    minuteStep: props.timeStep || 10,
                                    disabledHours: function () { return props.disabledHours || []; },
                                    allowClear: false
                                }, onChange: function (data) {
                                    var newAvailableHour = __assign({}, operatingHour);
                                    newAvailableHour[day.key] = data;
                                    handleChange(newAvailableHour);
                                } }))));
                })))))));
};
export var AllSetter = function (props) {
    var operatingHour = props.operatingHour || {};
    var startAndEnd = operatingHour[Object.keys(operatingHour).find(function (key) { return operatingHour[key]; })];
    var handleTimeRangeChange = function (data) {
        var _a;
        var newAvailableHour = {};
        (_a = Object.keys(operatingHour)) === null || _a === void 0 ? void 0 : _a.map(function (key) {
            var prevData = operatingHour[key];
            newAvailableHour[key] = __assign(__assign({}, data), { isActive: !!(prevData === null || prevData === void 0 ? void 0 : prevData.isActive) });
        });
        props.onChange(newAvailableHour);
    };
    var handleChangeDay = function (dayName, active) {
        var newAvailableHour = __assign({}, operatingHour);
        newAvailableHour[dayName] = __assign(__assign({}, startAndEnd), { isActive: active });
        props.onChange(newAvailableHour);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TextComponent, { marginBottom: 5, type: "subtitle3" }, "\uC608\uC57D\uAC00\uB2A5 \uC694\uC77C*"),
        React.createElement(Row, { align: "middle", style: { marginBottom: '20px' } },
            React.createElement(Col, { span: 24 },
                React.createElement("div", { className: "ant-btn-group" }, DAYS.map(function (day) {
                    var targetDay = operatingHour[day.key];
                    var active = targetDay === null || targetDay === void 0 ? void 0 : targetDay.isActive;
                    return (React.createElement(DayButton, { key: day.key, onClick: function () { return handleChangeDay(day.key, !active); }, type: active ? 'primary' : 'default' }, day.label));
                })))),
        React.createElement(TextComponent, { type: "subtitle3", marginBottom: 5 }, "\uC608\uC57D\uAC00\uB2A5 \uC2DC\uAC04*"),
        React.createElement(Row, { align: "middle" },
            React.createElement(Col, { span: 24 },
                React.createElement(TimeRangeInputs, { timePickerProps: {
                        minuteStep: props.timeStep || 10,
                        disabledHours: function () { return props.disabledHours || []; }
                    }, onChange: handleTimeRangeChange, data: startAndEnd })))));
};
var DayButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 0px;\n  width: 32px;\n  padding: 2px;\n"], ["\n  border-radius: 0px;\n  width: 32px;\n  padding: 2px;\n"])));
export var TimeRangeInputs = function (props) {
    var data = props.data;
    var handleChange = function (type, date) {
        if (!date) {
            return;
        }
        var newData = __assign({}, data);
        newData[type] = dateToHHmm(date.toDate());
        if (convertHHmmToDate(newData.start).getTime() >= convertHHmmToDate(newData.end).getTime()) {
            if (newData.end !== '00:00') {
                return alert('시간 범위를 확인해주세요.');
            }
        }
        if (props.onChange) {
            props.onChange(newData);
        }
    };
    var TIMEPICKER_PROPS = __assign({ suffixIcon: undefined, placeholder: '00:00', format: 'a hh:mm', inputReadOnly: true, showNow: false }, props.timePickerProps);
    return (React.createElement(TimerangeWrapper, { wrap: false, align: "middle" },
        React.createElement(TimePickerAutoaccept, __assign({}, TIMEPICKER_PROPS, { onChange: function (momentData) { return handleChange('start', momentData); }, value: (data === null || data === void 0 ? void 0 : data.start) ? dayjs(convertHHmmToDate(data === null || data === void 0 ? void 0 : data.start)) : undefined })),
        React.createElement(TextComponent, { children: "-", marginLeft: 4, marginRight: 8 }),
        React.createElement(TimePickerAutoaccept, __assign({}, TIMEPICKER_PROPS, { onChange: function (momentData) { return handleChange('end', momentData); }, value: (data === null || data === void 0 ? void 0 : data.end) ? dayjs(convertHHmmToDate(data === null || data === void 0 ? void 0 : data.end)) : undefined }))));
};
var TimerangeWrapper = styled(Row)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 30px;\n  border-radius: 2px;\n  background-color: #f6f6f7;\n  width: 170px;\n\n  & .ant-picker {\n    border: 0px;\n    background-color: #f6f6f7;\n    height: 28px;\n    width: 60px;\n\n    & {\n      input {\n        ", "} \n      }\n    }\n  }\n"], ["\n  height: 30px;\n  border-radius: 2px;\n  background-color: #f6f6f7;\n  width: 170px;\n\n  & .ant-picker {\n    border: 0px;\n    background-color: #f6f6f7;\n    height: 28px;\n    width: 60px;\n\n    & {\n      input {\n        ", "} \n      }\n    }\n  }\n"])), css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          ", ";\n        "], ["\n          ", ";\n        "])), typographyByName('caption1')));
var templateObject_1, templateObject_2, templateObject_3;
