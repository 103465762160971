var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { DrawerComponent, DrawerFooter, GUIDE_NAMES, HookFormInputs, Marginer, SettingTemplate, TextComponent, XScrollableTable } from 'renderer/components';
import { useForm } from 'react-hook-form';
import { EXTENDED_SALES_METHODS } from 'renderer/models';
import { StoreQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import { COLORS } from 'renderer/styles';
import styled from 'styled-components';
export var PaymentMethodsSetting = function (props) {
    var methods = useForm();
    var _a = React.useState(), drawer = _a[0], setDrawer = _a[1];
    React.useEffect(function () {
        methods.reset(drawer);
    }, [drawer]);
    var store = StoreQuery.getMyStoreInCache();
    var update = StoreQuery.useUpdateStore();
    var paymentMethodSetting = store === null || store === void 0 ? void 0 : store.paymentMethodsSetting;
    var handleClose = function () {
        setDrawer(undefined);
        methods.reset(undefined);
    };
    var onSubmit = methods.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var newPaymentMethodSetting;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newPaymentMethodSetting = paymentMethodSetting;
                    if (!(Object.keys(EXTENDED_SALES_METHODS)
                        .map(function (key) { return EXTENDED_SALES_METHODS[key]; })
                        .includes(values.key) &&
                        store.preference)) return [3 /*break*/, 2];
                    newPaymentMethodSetting[values.key] = {
                        name: values.name,
                        active: values.active
                    };
                    return [4 /*yield*/, update.mutateAsync({
                            id: store.id,
                            preference: __assign(__assign({}, store.preference), { paymentMethods: newPaymentMethodSetting })
                        })];
                case 1:
                    _a.sent();
                    notification.info({ message: '결제수단 수정 완료' });
                    handleClose();
                    return [3 /*break*/, 3];
                case 2:
                    console.log(values);
                    notification.error({ message: '결제수단 수정 중 에러발생' });
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('error', errors);
        notification.error({ message: '결제수단 수정 중 에러발생??' });
    });
    var active = methods.watch('active');
    return (React.createElement(SettingTemplate, { title: "\uAE30\uD0C0 \uACB0\uC81C\uC218\uB2E8 \uC124\uC815", guideName: GUIDE_NAMES['기타 결제수단 설정'], description: React.createElement("div", null,
            React.createElement(TextComponent, { marginTop: 10, type: "subtitle3", color: COLORS.gray2, children: "\uAE30\uD0C0 \uACB0\uC81C\uC218\uB2E8\uC758 \uBA85\uCE6D\uACFC \uB178\uCD9C\uC5EC\uBD80\uB97C \uC124\uC815\uD560 \uC218 \uC788\uC5B4\uC694. \uC0C1\uC810\uC5D0 \uAF2D \uD544\uC694\uD55C \uACB0\uC81C\uC218\uB2E8\uC73C\uB85C \uD3B8\uC9D1\uD574\uBCF4\uC138\uC694!" }),
            React.createElement(TextComponent, { type: "subtitle3", color: COLORS.gray2, children: " \uACB0\uC81C \uC218\uB2E8\uBA85\uC744 \uBCC0\uACBD\uD560 \uACBD\uC6B0 \uACFC\uAC70\uC758 \uD1B5\uACC4 \uB370\uC774\uD130\uB3C4 \uBCC0\uACBD\uB41C \uACB0\uC81C\uC218\uB2E8\uC73C\uB85C \uD45C\uC2DC\uB429\uB2C8\uB2E4." })), body: React.createElement("div", null,
            React.createElement(Marginer, { height: 20 }),
            React.createElement(XScrollableTable, { id: 'TABLE_ID_PAYMENT_METHODS_SETTING', resizable: {
                    additionalColumnWidth: 0
                }, onRow: function (data) {
                    return {
                        onClick: function () { return setDrawer(data); }
                    };
                }, columns: [
                    {
                        title: '기타 결제 수단명',
                        key: 'name',
                        width: 200,
                        render: function (value) {
                            return value.name;
                        }
                    },
                    {
                        title: '노출여부',
                        key: 'active',
                        width: 160,
                        render: function (value) {
                            return value.active ? '노출' : '미노출';
                        }
                    }
                ], rowKey: function (row) { return row.key; }, dataSource: Object.keys(paymentMethodSetting).map(function (key) {
                    return __assign({ key: key }, paymentMethodSetting[key]);
                }) }),
            React.createElement(DrawerComponent, { title: "\uAE30\uD0C0 \uACB0\uC81C\uC218\uB2E8 \uC124\uC815", open: !!drawer, onClose: handleClose, footer: React.createElement(DrawerFooter, { onCancel: handleClose, onConfirm: onSubmit, confirmText: "\uC800\uC7A5" }) },
                React.createElement(StyledForm, null,
                    React.createElement(HookFormInputs.Text, { controlProps: {
                            name: "key",
                            control: methods.control,
                            rules: {
                                required: true
                            }
                        }, isHorizontal: true, inputProps: {
                            size: 'large',
                            hidden: true,
                            placeholder: '결제수단 명'
                        } }),
                    React.createElement(HookFormInputs.Text, { controlProps: {
                            name: "name",
                            control: methods.control,
                            rules: {
                                maxLength: {
                                    value: 10,
                                    message: '10자 이하로 입력해주세요.'
                                }
                            }
                        }, isHorizontal: true, label: "\uACB0\uC81C\uC218\uB2E8\uBA85", inputProps: {
                            size: 'large',
                            placeholder: '결제수단 명'
                        } }),
                    React.createElement(HookFormInputs.SwitchInput, { isHorizontal: true, inputProps: {
                            checked: !!active,
                            onChange: function (e) {
                                methods.setValue('active', e.target.value);
                            }
                        }, label: "\uB178\uCD9C \uC124\uC815", controlProps: {
                            name: "active",
                            control: methods.control
                        } })))) }));
};
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > * {\n    margin-bottom: 20px;\n  }\n"], ["\n  & > * {\n    margin-bottom: 20px;\n  }\n"])));
var templateObject_1;
