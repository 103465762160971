var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { DefaultCircleButton, SendMessageModal, TextComponent, ScheduleBlock, DrawerComponent, ScrollBar, PremiumCover, AntPrimaryTextButton, HelpCenterIframe } from 'renderer/components';
import { IMAGES } from 'renderer/assets';
import { COLORS, MediaQuery } from 'renderer/styles';
import { useHotkeys } from 'react-hotkeys-hook';
import { Col, Row } from 'antd/lib/grid';
import styled, { keyframes } from 'styled-components';
import { useSaleModal, useScheduleModal } from 'renderer/stores';
import { Icon, Layout } from 'common-ui';
import { ScheduleQuery } from 'renderer/queries';
import * as dayjs from 'dayjs';
import useMeasure from 'react-use/lib/useMeasure';
import Divider from 'antd/lib/divider';
import { APP_VERSION, BUILD_NUMBER, REMOTE_SUPPORT_URL, SUPPORT_TEL_NUMBER } from 'renderer/constants';
import { useWindowSize } from 'renderer/utils';
export var QuickMenuButton = function (props) {
    useHotkeys('ctrl+q', function () {
        props.handleOpen();
    });
    return React.createElement(DefaultCircleButton, { type: "ghost", icon: React.createElement(NotiImage, { src: IMAGES.quick_icon }), onClick: props.handleOpen });
};
export var QuickMenu = function (props) {
    var _a, _b;
    var visible = props.visible;
    var scheduleModdal = useScheduleModal();
    var add = useSaleModal().add;
    var handleClose = function () {
        props.handleClose();
    };
    var _c = React.useState(), drawer = _c[0], setDrawer = _c[1];
    var handleSubDrawerClose = function () {
        setDrawer(undefined);
    };
    return (React.createElement(Wrapper, { type: "quick" },
        props.isPC ? (props.visible ? (React.createElement(PCQuickMenu, null,
            React.createElement(Header, null,
                React.createElement(Row, null,
                    React.createElement(TextComponent, { children: "\uD035\uBC84\uD2BC", type: "headline3" })),
                React.createElement(Icon.Icon, { name: "x", size: 24, onClick: function () { return props.handleClose(); } })),
            React.createElement(QuickMenuMain, { onClose: handleClose, store: props.store, visible: visible, onClickDrawerOpen: function (drawer) {
                    if (drawer.schedule) {
                        scheduleModdal.add({
                            type: 'create',
                            schedule: drawer.schedule
                        });
                    }
                    else if (drawer.salesHistory) {
                        add(drawer.salesHistory);
                    }
                    else {
                        setDrawer(drawer);
                    }
                } }))) : (React.createElement("div", null))) : (React.createElement(StyledDrawer, { closeIcon: null, title: React.createElement(Row, { justify: "space-between" },
                React.createElement(Row, null,
                    React.createElement(TextComponent, { children: "\uD035\uBC84\uD2BC", type: "headline3" })),
                React.createElement(Icon.Icon, { name: "x", size: 24, onClick: function () { return props.handleClose(); } })), keyboard: false, onClose: handleClose, children: React.createElement(QuickMenuMain, { onClose: handleClose, store: props.store, visible: visible, onClickDrawerOpen: function (drawer) {
                    if (drawer.schedule) {
                        scheduleModdal.add({
                            type: 'create',
                            schedule: drawer.schedule
                        });
                    }
                    else if (drawer.salesHistory) {
                        add(drawer.salesHistory);
                    }
                    else {
                        setDrawer(drawer);
                    }
                } }), open: visible })),
        React.createElement(SendMessageModal, { store: props.store, target: { customerIds: ((_a = drawer === null || drawer === void 0 ? void 0 : drawer.message) === null || _a === void 0 ? void 0 : _a.customerId) ? [Number((_b = drawer === null || drawer === void 0 ? void 0 : drawer.message) === null || _b === void 0 ? void 0 : _b.customerId)] : [] }, onClose: handleSubDrawerClose, visible: !!(drawer === null || drawer === void 0 ? void 0 : drawer.message), trigger: React.createElement("div", null) })));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", "px;\n"], ["\n  width: ", "px;\n"])), function (props) { return (props.type === 'quick' ? 360 : 720); });
var PCQuickMenu = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  border-left: solid 1px ", ";\n  overflow: hidden;\n"], ["\n  height: 100%;\n  width: 100%;\n  border-left: solid 1px ", ";\n  overflow: hidden;\n"])), COLORS.gray5);
export var HelpCenter = function (props) {
    var visible = props.visible;
    var handleClose = function () {
        props.handleClose();
    };
    var _a = React.useState(), drawer = _a[0], setDrawer = _a[1];
    var handleSubDrawerClose = function () {
        setDrawer(undefined);
    };
    var _b = React.useState('list'), step = _b[0], setStep = _b[1];
    var leftChevron = function () {
        return step === 'list' ? React.createElement("div", null) : React.createElement(Icon.Icon, { name: "left-chevron", size: 24, onClick: function () { return setStep('list'); } });
    };
    return (React.createElement(Wrapper, { type: "help" }, props.isPC ? (props.visible ? (React.createElement(PCQuickMenu, null,
        React.createElement(Header, null,
            React.createElement(Row, null,
                leftChevron(),
                React.createElement(TextComponent, { children: "\uACE0\uAC1D\uC13C\uD130", type: "headline3" })),
            React.createElement(Icon.Icon, { name: "x", size: 24, onClick: function () { return props.handleClose(); } })),
        React.createElement(HelpCenterMain, { step: step, onClick: function (url) { return setStep(url); } }))) : (React.createElement("div", null))) : (React.createElement(StyledDrawer, { closeIcon: null, title: React.createElement(Row, { justify: "space-between" },
            React.createElement(Row, null,
                leftChevron(),
                React.createElement(TextComponent, { children: "\uACE0\uAC1D\uC13C\uD130", type: "headline3" })),
            React.createElement(Icon.Icon, { name: "x", size: 24, onClick: function () { return props.handleClose(); } })), keyboard: false, onClose: handleClose, children: React.createElement(HelpCenterMain, { step: step, onClick: function (url) { return setStep(url); } }), visible: visible }))));
};
var HelpCenterMain = function (props) {
    var isPC = useWindowSize().isPC;
    var groups = [
        {
            key: 'help',
            title: '도움이 필요하신가요?',
            items: [
                isPC && {
                    emoji: '👩🏻‍💻',
                    title: '원격지원',
                    url: REMOTE_SUPPORT_URL,
                    type: 'popup'
                },
                {
                    emoji: '📖',
                    title: '사용가이드',
                    url: 'https://help.thinkofyou.kr/',
                    type: 'iframe'
                },
                {
                    emoji: 'ℹ️',
                    title: '자주 묻는 질문',
                    url: 'https://help.thinkofyou.kr/faq',
                    type: 'iframe'
                }
            ].filter(Boolean)
        },
        {
            key: 'notice',
            title: '팅커뷰의 최신 소식을 확인해보세요',
            items: [
                {
                    emoji: '🔔',
                    title: '업데이트 & 공지사항',
                    url: 'https://help.thinkofyou.kr/notice',
                    type: 'iframe'
                }
                // {
                //   emoji: '🎁',
                //   title: '혜택∙이벤트',
                //   url: 'https://help.thinkofyou.kr/',
                //   type: 'iframe'
                // }
            ]
        }
    ];
    return props.step !== 'list' ? (React.createElement(HelpCenterIframe, { src: props.step, style: { width: '100%', height: '100%' } })) : (React.createElement(HelpCenterMainWrapper, null,
        groups.map(function (group) {
            return (React.createElement("div", { key: group.key },
                React.createElement(TextComponent, { type: "caption2", color: COLORS.gray2, children: group.title, marginBottom: 10 }),
                React.createElement(Layout.FluidColumns, { columns: 2, gutter: 8, style: { marginBottom: '20px' } }, group.items.map(function (item, index) {
                    return (React.createElement(Layout.Column, { key: "".concat(group.key, "_").concat(index), onClick: function () {
                            if (item.type === 'popup') {
                                window.open(item.url);
                            }
                            else {
                                props.onClick(item.url);
                            }
                        } },
                        React.createElement(ItemButton, null,
                            React.createElement(TextComponent, { type: "headline2", children: item.emoji, marginRight: 13 }),
                            React.createElement(TextComponent, { type: "subtitle3", children: item.title }))));
                }))));
        }),
        React.createElement(Divider, null),
        React.createElement(Layout.FluidColumns, { columns: isPC ? 2 : 1, gutter: 8, style: { marginBottom: '20px' } },
            React.createElement(Layout.Column, null,
                React.createElement(HelpGraybox, null,
                    React.createElement("a", { href: "tel:".concat(SUPPORT_TEL_NUMBER) },
                        React.createElement(Layout.FluidInline, { gutter: 16 },
                            React.createElement(TextComponent, { children: "\uD83D\uDCDE", type: "headline1" }),
                            React.createElement("div", null,
                                React.createElement(TextComponent, { children: "[\uC804\uD654\uC0C1\uB2F4] ".concat(SUPPORT_TEL_NUMBER), type: "subtitle3" }),
                                React.createElement(TextComponent, { children: "\uD3C9\uC77C \uC624\uC804 10\uC2DC~\uC624\uD6C4 6\uC2DC (\uC8FC\uB9D0,\uACF5\uD734\uC77C \uD734\uBB34)", color: COLORS.gray3, type: "caption2" })))))),
            React.createElement(Layout.Column, null,
                React.createElement(HelpGraybox, null,
                    React.createElement(Layout.FluidInline, { gutter: 16 },
                        React.createElement(TextComponent, { children: "\uD83D\uDCBB", type: "headline1" }),
                        React.createElement("div", null,
                            React.createElement(TextComponent, { children: "\uBC84\uC804\uC815\uBCF4", color: COLORS.gray3, type: "caption2" }),
                            React.createElement(TextComponent, { children: "".concat(APP_VERSION, " (").concat(BUILD_NUMBER, ")"), type: "subtitle3" }))))))));
};
var HelpCenterMainWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 14px 20px;\n"], ["\n  padding: 14px 20px;\n"])));
var ItemButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 40px;\n  border-radius: 4px;\n  border: 0px solid;\n  background-color: ", ";\n  border: solid 1px ", ";\n  padding-left: 12px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-cotent: space-between;\n  width: 100%;\n  cursor: pointer;\n"], ["\n  height: 40px;\n  border-radius: 4px;\n  border: 0px solid;\n  background-color: ", ";\n  border: solid 1px ", ";\n  padding-left: 12px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-cotent: space-between;\n  width: 100%;\n  cursor: pointer;\n"])), COLORS.white, COLORS.gray5);
var Header = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 50.5px;\n  border-bottom: solid 1px ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 15px;\n"], ["\n  height: 50.5px;\n  border-bottom: solid 1px ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 15px;\n"])), COLORS.gray6);
var StyledDrawer = styled(DrawerComponent)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  & .ant-drawer-title {\n    margin-top: 0px;\n  }\n  & .ant-drawer-body {\n    padding: 0;\n  }\n  & .ant-drawer-close {\n    display: none;\n  }\n"], ["\n  & .ant-drawer-title {\n    margin-top: 0px;\n  }\n  & .ant-drawer-body {\n    padding: 0;\n  }\n  & .ant-drawer-close {\n    display: none;\n  }\n"])));
var boxFade = keyframes(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  0% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.3;\n  }\n  100% {\n    opacity: 1;\n  }"], ["\n  0% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.3;\n  }\n  100% {\n    opacity: 1;\n  }"])));
var NotiImage = styled.img(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", "\n  animation: ", " 2s 1s infinite linear alternate;\n"], ["\n  ", "\n  animation: ", " 2s 1s infinite linear alternate;\n"])), MediaQuery.tabletWithMobile(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    width: 25px;\n    height: 25px;\n  "], ["\n    width: 25px;\n    height: 25px;\n  "]))), boxFade);
var QuickMenuMain = function (props) {
    var ref = React.useRef();
    React.useEffect(function () {
        // setSelectedCustomerId(undefined);
        setTimeout(function () {
            var _a;
            if (ref && ref.current) {
                if (props.visible) {
                    (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.focus();
                }
            }
        });
    }, [props.visible]);
    useHotkeys('esc', function (ev) {
        ev.stopPropagation();
        ev.preventDefault();
        props.onClose();
    }, { enableOnTags: ['INPUT'] });
    var _a = useMeasure(), wrapperRef = _a[0], height = _a[1].height;
    return (React.createElement(QuickMenuMainWrapper, { ref: wrapperRef },
        React.createElement(StyledScrollbar, { height: height },
            React.createElement(ActionButtons, { store: props.store, onClickDrawerOpen: props.onClickDrawerOpen }),
            React.createElement(PendingSchedules, { store: props.store }))));
};
var StyledScrollbar = styled(ScrollBar)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  height: ", "px;\n"], ["\n  height: ", "px;\n"])), function (props) { return props.height; });
var QuickMenuMainWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - 70px);\n  overflow: hidden;\n  padding: 14px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - 70px);\n  overflow: hidden;\n  padding: 14px;\n"])));
var PendingSchedules = function (props) {
    var _a, _b, _c, _d;
    var depositTimeLimit = (_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.operationPreference) === null || _b === void 0 ? void 0 : _b.depositTimeLimit;
    var depositTimeLimitToUnit = depositTimeLimit > 30 ? depositTimeLimit / 60 : depositTimeLimit;
    var depositTimeLimitString = depositTimeLimitToUnit === 30 ? "".concat(depositTimeLimitToUnit, "\uBD84") : "".concat(depositTimeLimitToUnit, "\uC2DC\uAC04");
    var hasPlan = (_c = props.store) === null || _c === void 0 ? void 0 : _c.hasSubscription;
    var _e = React.useState(false), pendingMode = _e[0], setPendingMode = _e[1];
    var pendingReservations = ScheduleQuery.usePendingSchedules({
        storeId: (_d = props.store) === null || _d === void 0 ? void 0 : _d.id,
        timelimit: depositTimeLimit
    }, {
        refetchInterval: 60000,
        enabled: hasPlan && pendingMode
    });
    var _f = pendingReservations.data || {}, after = _f.after, before = _f.before;
    var activeScheduleBlockSettings = props.store.activeScheduleBlockSettings;
    return (React.createElement(Card, null,
        React.createElement(CardTitle, { title: "\uC608\uC57D \uB300\uAE30\uD604\uD669" }),
        React.createElement(CardBody, { content: hasPlan ? (pendingMode ? (React.createElement(React.Fragment, null,
                React.createElement(TextComponent, { children: "\uB4F1\uB85D\uC2DC\uAC04\uC774 ".concat(depositTimeLimitString, "\uC774 \uC9C0\uB09C \uC608\uC57D"), type: "caption1", marginBottom: 8 }),
                (after || []).map(function (schedule) {
                    return (React.createElement(PendingBlock, { key: schedule.id, schedule: schedule, blockSettings: activeScheduleBlockSettings }));
                }),
                React.createElement(TextComponent, { children: "\uB4F1\uB85D\uC2DC\uAC04\uC774 ".concat(depositTimeLimitString, " \uC774\uB0B4\uC778 \uC608\uC57D"), type: "caption1", marginTop: 10, marginBottom: 8 }),
                (before || []).map(function (schedule) {
                    return (React.createElement(PendingBlock, { key: schedule.id, schedule: schedule, blockSettings: activeScheduleBlockSettings }));
                }))) : (React.createElement(React.Fragment, null,
                React.createElement(Graybox, { height: "310px", width: "100%", style: { padding: '20px' } },
                    React.createElement(TextComponent, { textAlign: "center", color: COLORS.gray1, type: "caption2" }, "\uC608\uC57D\uAE08 \uBB34\uD1B5\uC7A5 \uC785\uAE08\uC744 \uC2E4\uC2DC\uAC04\uC73C\uB85C \uD655\uC778\uD558\uACE0"),
                    React.createElement(TextComponent, { textAlign: "center", color: COLORS.gray1, type: "caption2" }, "\uC608\uC57D\uC0C1\uD0DC\uB97C \uC790\uB3D9\uC73C\uB85C \uAD00\uB9AC\uD574\uC8FC\uB294"),
                    React.createElement(TextComponent, { textAlign: "center", color: COLORS.gray1, type: "caption2" }, "\uBD80\uAC00\uC11C\uBE44\uC2A4\uB97C \uC774\uC6A9\uD574 \uBCF4\uC138\uC694"),
                    React.createElement(TextComponent, { marginTop: 20, textAlign: "center", color: COLORS.gray1, type: "caption2" }, "\uC608\uC57D\uB300\uAE30\uAD00\uB9AC\uAC00 \uD544\uC694\uD558\uC2E0 \uBD84\uC740"),
                    React.createElement(TextComponent, { textAlign: "center", color: COLORS.gray1, type: "caption2" }, "\uC544\uB798 [\uB300\uAE30\uD604\uD669 \uD655\uC778\uD558\uAE30] \uBC84\uD2BC\uC744 \uD074\uB9AD\uD574"),
                    React.createElement(TextComponent, { textAlign: "center", color: COLORS.gray1, type: "caption2" }, "\uC774\uC6A9\uD574\uC8FC\uC138\uC694!\uD83D\uDC47")),
                React.createElement(Row, { justify: "center" },
                    React.createElement(AntPrimaryTextButton, { children: "\uB300\uAE30\uD604\uD669 \uD655\uC778\uD558\uAE30 >", onClick: function () { return setPendingMode(true); } }))))) : (React.createElement(PremiumCover, { store: props.store, textType: "body1" })) })));
};
var PendingBlock = function (props) {
    var _a, _b;
    var schedule = props.schedule;
    return (React.createElement(Row, { gutter: 4, wrap: false, style: { marginBottom: '6px' } },
        React.createElement(Col, null,
            React.createElement(Graybox, null,
                React.createElement(TextComponent, { type: "caption2" }, dayjs((_a = schedule.dateOption) === null || _a === void 0 ? void 0 : _a.startedAt).format('MM-DD(ddd)')),
                React.createElement(TextComponent, { type: "headline3" }, dayjs((_b = schedule.dateOption) === null || _b === void 0 ? void 0 : _b.startedAt).format('HH:mm')))),
        React.createElement(Col, { flex: 1 },
            React.createElement(StyledScheduleBlock, { showTime: false, key: schedule.id, schedule: schedule, blockSettings: props.blockSettings }))));
};
var Graybox = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  border-radius: 4px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: ", ";\n  height: ", ";\n"], ["\n  border-radius: 4px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: ", ";\n  height: ", ";\n"])), COLORS.gray7, function (props) { return props.width || '54px'; }, function (props) { return props.height || '60px'; });
var StyledScheduleBlock = styled(ScheduleBlock)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  height: 60px;\n  overflow: hidden;\n"], ["\n  height: 60px;\n  overflow: hidden;\n"])));
var ActionButtons = function (props) {
    var buttons = [
        {
            icon: 'BsFillCalendarCheckFill',
            size: 20,
            onClick: function () {
                props.onClickDrawerOpen({
                    schedule: (props.customerId ? { customer: { id: props.customerId } } : {})
                });
            },
            label: '예약등록'
        },
        {
            icon: 'BsFillEnvelopeOpenFill',
            size: 20,
            onClick: function () {
                props.onClickDrawerOpen({
                    message: (props.customerId ? { customerId: props.customerId } : {})
                });
            },
            label: '문자발송'
        },
        {
            icon: 'IoIosGift',
            size: 24,
            onClick: function () {
                props.onClickDrawerOpen({
                    salesHistory: {
                        type: 'product',
                        data: (props.customerId
                            ? { customer: { id: props.customerId } }
                            : { customer: { type: 'customer' } })
                    }
                });
            },
            label: '상품판매'
        },
        {
            icon: 'FaTicketAlt',
            size: 24,
            onClick: function () {
                props.onClickDrawerOpen({
                    salesHistory: {
                        type: 'ticket',
                        data: (props.customerId
                            ? { customer: { id: props.customerId } }
                            : { customer: { type: 'customer' } })
                    }
                });
            },
            label: '선불액∙정기권 판매'
        }
    ];
    return (React.createElement(Card, { style: { padding: '16px' } },
        React.createElement(Row, { justify: "space-around", gutter: 8 }, buttons.map(function (item, index) {
            return (React.createElement(StyledCol, { key: index, onClick: item.onClick },
                React.createElement(GrayCircle, null,
                    React.createElement(Icon.Icon, { name: item.icon, size: item.size })),
                React.createElement(TextComponent, { children: item.label, type: "caption2" })));
        }))));
};
var GrayCircle = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  background-color: ", ";\n  width: 44px;\n  height: 46px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background-color: ", ";\n  width: 44px;\n  height: 46px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), COLORS.gray7);
var StyledCol = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  &: hover {\n    & svg {\n      color: ", ";\n    }\n  }\n"], ["\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  &: hover {\n    & svg {\n      color: ", ";\n    }\n  }\n"])), COLORS.primary1);
var Card = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  border-radius: 10px;\n  border: solid 1px ", ";\n  width: cacl(100% - 4px);\n  margin-bottom: 14px;\n  margin-left: 2px;\n  margin-right: 2px;\n  margin-top: 2px;\n"], ["\n  border-radius: 10px;\n  border: solid 1px ", ";\n  width: cacl(100% - 4px);\n  margin-bottom: 14px;\n  margin-left: 2px;\n  margin-right: 2px;\n  margin-top: 2px;\n"])), COLORS.gray5);
var CardTitle = function (props) {
    return (React.createElement(StyledCardTitle, null,
        React.createElement(TextComponent, { children: props.title, type: "subtitle1" })));
};
var StyledCardTitle = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  padding: 14px;\n  border-bottom: 1px solid ", ";\n"], ["\n  padding: 14px;\n  border-bottom: 1px solid ", ";\n"])), COLORS.gray7);
var CardBody = function (props) {
    return React.createElement(StyledCardBody, null, props.content);
};
var StyledCardBody = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  padding: 10px 14px;\n  position: relative;\n  min-height: 200px;\n"], ["\n  padding: 10px 14px;\n  position: relative;\n  min-height: 200px;\n"])));
var HelpGraybox = styled(Graybox).attrs({ width: '100%', height: '48px' })(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  padding-left: 12px;\n  margin-bottom: 5px;\n  & > a {\n    width: 100%;\n  }\n"], ["\n  padding-left: 12px;\n  margin-bottom: 5px;\n  & > a {\n    width: 100%;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
