var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Popconfirm from 'antd/lib/popconfirm';
import React from 'react';
import { COLORS } from 'renderer/styles';
import { TextComponent, Icon } from 'renderer/components';
import styled from 'styled-components';
export var CustomPopconfirm = function (props) {
    var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
    var handleConfirm = function () {
        if (props.onConfirm) {
            props.onConfirm();
        }
        handleClose();
    };
    var handleCancel = function () {
        if (props.onCancel) {
            props.onCancel();
        }
        handleClose();
    };
    var handleClose = function () {
        setVisible(false);
    };
    var title = (React.createElement("div", null,
        React.createElement(TextComponent, { children: props.title, type: "headline3" }),
        props.message && React.createElement(TextComponent, { children: props.message, color: COLORS.gray1 })));
    var onCancel = props.onCancel, onConfirm = props.onConfirm, okButtonProps = props.okButtonProps, cancelButtonProps = props.cancelButtonProps, rest = __rest(props, ["onCancel", "onConfirm", "okButtonProps", "cancelButtonProps"]);
    return (React.createElement(StyledPopconfirm, { title: title, visible: visible, okButtonProps: __assign(__assign({}, okButtonProps), { children: props.confirm }), cancelButtonProps: __assign(__assign({}, cancelButtonProps), { children: props.cancel }), cancelText: props.cancel, okText: props.confirm, onConfirm: handleConfirm, onCancel: handleCancel }, React.cloneElement(props.children, { onClick: function () { return setVisible(true); } })));
};
export var DeletePopconfirm = function (props) {
    var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
    var handleConfirm = function () {
        if (props.onConfirm) {
            props.onConfirm();
        }
        setVisible(false);
    };
    var handleCancel = function () {
        if (props.onCancel) {
            props.onCancel();
        }
        setVisible(false);
    };
    var onCancel = props.onCancel, onConfirm = props.onConfirm, okButtonProps = props.okButtonProps, rest = __rest(props, ["onCancel", "onConfirm", "okButtonProps"]);
    return (React.createElement(StyledPopconfirm, { title: props.title, open: visible, onOpenChange: props.onVisibleChange, okButtonProps: __assign(__assign({}, okButtonProps), { children: props.confirm }), cancelButtonProps: { children: props.cancel }, cancelText: props.cancel, okText: props.confirm, onConfirm: handleConfirm, onCancel: handleCancel },
        React.createElement("div", { onClick: function () { return setVisible(true); } }, props.children || React.createElement(DeleteDiv, { children: React.createElement(Icon, { name: "trash", color: COLORS.gray1 }) }))));
};
var DeleteDiv = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n\n  & > svg {\n    margin-left: 10px;\n  }\n"], ["\n  cursor: pointer;\n\n  & > svg {\n    margin-left: 10px;\n  }\n"])));
var StyledPopconfirm = styled(Popconfirm)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2;
