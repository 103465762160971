var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Col, Row } from 'antd/lib/grid';
import React from 'react';
import { BigTitleModal, EntireLoader, Marginer, AntPrimaryGhostButton, TextComponent, UnderlineClickableText, GUIDE_NAMES, SettingTemplate, HookFormInputs, PhoneNumberNormalizer, NoticeSentenceWithLink, AntButton } from 'renderer/components';
import { MessageTemplateQuery, StorePaymentMethodQuery, StoreQuery } from 'renderer/queries';
import { useWindowSize, openWindow, createUUID } from 'renderer/utils';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles/colors';
import { KRWFomatToString } from 'renderer/components';
import { SMS_FEE, LMS_FEE, MMS_FEE, ATS_FEE, FTS_FEE, FMS_FEE } from 'renderer/constants/env';
import { ChargeDrawer } from 'renderer/containers/app/NewStore/StorePointCenter';
import { useForm } from 'react-hook-form';
import Modal from 'antd/lib/modal';
import { REGEX_PHONE } from 'renderer/constants';
import { formmatPhoneNumber } from '../../../containers/admin/migrationUtils';
export var SenderInfoSetting = function (props) {
    var store = StoreQuery.getMyStoreInCache();
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var isPC = useWindowSize().isPC;
    var storeQuery = MessageTemplateQuery.useGetMessageSettings(storeId, { enabled: !!storeId });
    return storeId ? (React.createElement(SettingTemplate, { title: "\uBB38\uC790 \uBC1C\uC2E0\uC815\uBCF4 \uB4F1\uB85D", guideName: GUIDE_NAMES.문자발신정보설정, description: "\uBB38\uC790 \uC11C\uBE44\uC2A4 \uC774\uC6A9\uC744 \uC6D0\uD558\uC2DC\uBA74 \uBC1C\uC2E0\uBC88\uD638\uB97C \uB4F1\uB85D\uD558\uACE0 \uD3EC\uC778\uD2B8\uB97C \uCDA9\uC804\uD574\uC8FC\uC138\uC694. \uC54C\uB9BC\uD1A1/\uCE5C\uAD6C\uD1A1\uC744 \uC774\uC6A9\uD558\uBA74 \uCD5C\uB300 50%\uC774\uC0C1 \uC800\uB834\uD558\uAC8C \uBB38\uC790\uB97C \uBCF4\uB0BC \uC218 \uC788\uC5B4\uC694!", body: React.createElement(Wrapper, null,
            React.createElement(SettingRow, { isPC: isPC, title: "\uBC1C\uC2E0 \uBC88\uD638", rightElement: (storeQuery.data || storeQuery.error) && React.createElement(SenderDiv, { store: storeQuery.data, isPC: isPC }) }),
            React.createElement(SettingRow, { isPC: isPC, title: "\uC54C\uB9BC\uD1A1/\uCE5C\uAD6C\uD1A1 \uC124\uC815", rightElement: storeQuery.data && React.createElement(AlimtalkSettings, { store: storeQuery.data }) }),
            React.createElement(SettingRow, { isPC: isPC, title: "\uBB38\uC790 \uD3EC\uC778\uD2B8", rightElement: storeQuery.data && React.createElement(PointDiv, { store: storeQuery.data }) })) })) : (React.createElement(EntireLoader, { open: true }));
};
var SenderDiv = function (props) {
    var store = props.store;
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var messageInfo = store === null || store === void 0 ? void 0 : store.messageSendInfo;
    var senderNumber = messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.senderNumber;
    var isRegistered = !!senderNumber;
    var form = useForm();
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var merchant_uid, phone, redirect;
        return __generator(this, function (_a) {
            merchant_uid = createUUID();
            phone = values.phone;
            redirect = "".concat(location.origin, "/app/verification?redirect=").concat(encodeURI(location.pathname), "&merchant_uid=").concat(merchant_uid, "&phone=").concat(phone);
            // http://localhost:8080/app/reservations?redirect=/app/reservations?modal=settings&type=senderInfo
            IMP.certification({
                merchant_uid: merchant_uid,
                phone: phone,
                m_redirect_url: redirect,
                popup: false // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨,
            }, function (rsp) {
                // callback
                if (rsp === null || rsp === void 0 ? void 0 : rsp.success) {
                    location.href = "".concat(redirect, "&imp_uid=").concat(rsp.imp_uid, "&success=true");
                    // 인증 성공 시 로직,
                }
                else {
                    alert('본인인증에 실패했습니다.');
                }
            });
            return [2 /*return*/];
        });
    }); }, function (errors) { });
    var formValues = form.watch('phone');
    var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
    return (React.createElement("div", null,
        React.createElement(Row, { align: "middle" },
            isRegistered && React.createElement(TextComponent, { children: senderNumber }),
            React.createElement(TextComponent, { children: isRegistered ? '등록완료' : '미등록', color: isRegistered ? COLORS.success : COLORS.warning, marginRight: 20, marginLeft: isRegistered ? 20 : 0 }),
            React.createElement(AntPrimaryGhostButton, { children: "\uBC1C\uC2E0\uBC88\uD638 \uB4F1\uB85D/\uBCC0\uACBD", onClick: function () { return setVisible(true); } })),
        React.createElement(TextComponent, { type: "caption1", marginTop: 6, children: "\uBC1C\uC2E0\uBC88\uD638\uB97C \uB4F1\uB85D\uC744 \uC644\uB8CC\uD574\uC57C \uBB38\uC790\uBC1C\uC1A1 \uAE30\uB2A5\uC744 \uC774\uC6A9\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.", color: COLORS.gray2 }),
        React.createElement("div", { style: { width: '600px', marginTop: '10px' } },
            React.createElement(NoticeSentenceWithLink, { title: "\uB4F1\uB85D\uD55C \uBC1C\uC2E0\uBC88\uD638\uAC00 \uCC28\uB2E8\uC11C\uBE44\uC2A4\uC5D0 \uAC00\uC785\uB418\uC5C8\uB294\uC9C0 \uD655\uC778\uD574\uC8FC\uC138\uC694 >", url: "https://oxidized-duke-790.notion.site/6ed3c6caef8e4a4c85dc3d211c2de88d" })),
        React.createElement(Modal, { centered: true, visible: visible, okButtonProps: {
                children: '본인인증'
            }, onOk: onSubmit, onCancel: function () { return setVisible(false); }, title: React.createElement(TextComponent, { children: "\uBC1C\uC2E0\uBC88\uD638 \uB4F1\uB85D", type: "headline2" }), children: React.createElement("div", null,
                React.createElement(TextComponent, { type: "16m", marginBottom: 10, marginTop: 10, color: COLORS.gray1, children: '본인인증이 가능한 휴대폰번호를 입력해주세요.' }),
                React.createElement("form", { onSubmit: form.handleSubmit(onSubmit) },
                    React.createElement(HookFormInputs.Text, { controlProps: {
                            control: form.control,
                            name: 'phone',
                            rules: {
                                required: { value: true, message: '휴대폰번호를 입력해주세요.' },
                                pattern: {
                                    value: REGEX_PHONE,
                                    message: '올바른 번호를 입력해주세요.'
                                },
                                validate: function (value) {
                                    if (formmatPhoneNumber(value) === formmatPhoneNumber(senderNumber)) {
                                        return '현재 등록된 번호입니다.';
                                    }
                                    else {
                                        true;
                                    }
                                }
                            }
                        }, inputProps: {
                            size: 'large',
                            autoFocus: true,
                            normalizer: PhoneNumberNormalizer,
                            type: 'tel'
                        } })),
                React.createElement(TextComponent, { type: "16m", marginBottom: 10, marginTop: 10, color: COLORS.gray1, children: '유선번호를 발신번호로 등록하고자 하시면 하단의 채팅상담을 통해 신청해주세요.' })) })));
};
var AlimtalkSettings = function (props) {
    var _a = React.useState(), modal = _a[0], setModal = _a[1];
    var renderContent = function (type) {
        switch (type) {
            case 'join':
                return (React.createElement("div", { style: { padding: '20px' } },
                    React.createElement(TextComponent, { type: "subtitle1", children: "\uC544\uB798 \uC21C\uC11C\uC5D0 \uB530\uB77C \uCE74\uCE74\uC624 \uC54C\uB9BC\uD1A1/\uCE5C\uAD6C\uD1A1 \uC2E0\uCCAD\uC744 \uC9C4\uD589\uD574\uC8FC\uC138\uC694.", marginBottom: 20 }),
                    React.createElement(TextComponent, { children: "1) \uCE74\uCE74\uC624 \uCC44\uB110\uC744 \uAC1C\uC124\uD574\uC8FC\uC138\uC694.", marginBottom: 4 }),
                    React.createElement(UnderlineClickableText, { color: COLORS.primary1, children: "\uCE74\uCE74\uC624\uD1A1 \uCC44\uB110 \uB9CC\uB4E4\uAE30", marginBottom: 12, onClick: function () {
                            return openWindow('https://accounts.kakao.com/login/kakaobusiness?continue=https%3A%2F%2Fbusiness.kakao.com%2Fdashboard%2F', '카카오채널신청');
                        } }),
                    React.createElement(TextComponent, { children: "2) \uAC1C\uC124\uB41C \uCE74\uCE74\uC624\uD1A1 \uCC44\uB110\uC774 \uBE44\uC9C0\uB2C8\uC2A4 \uC778\uC99D\uC744 \uBC1B\uC544\uC57C \uD569\uB2C8\uB2E4.", marginBottom: 4 }),
                    React.createElement(UnderlineClickableText, { color: COLORS.primary1, children: "\uBE44\uC9C0\uB2C8\uC2A4 \uCC44\uB110 \uC2E0\uCCAD \uAC00\uC774\uB4DC \uB2E4\uC6B4\uB85C\uB4DC", marginBottom: 12, onClick: function () {
                            return openWindow('https://t1.daumcdn.net/biz/DM/%EB%B9%84%EC%A6%88%EB%8B%88%EC%8A%A4%20%EC%B1%84%EB%84%90%20%EC%8B%A0%EC%B2%AD%EA%B0%80%EC%9D%B4%EB%93%9C_ver1.0_%EC%99%B8%EB%B6%80%EC%9A%A9.pdf', '비지니스채널 신청');
                        } }),
                    React.createElement(TextComponent, { children: "3) \uC0C1\uC810\uC758 \u2018\uCC44\uB110\uBA85\u2018\uACFC \u2018\uAC80\uC0C9\uC6A9 \uC544\uC774\uB514\u2019 \uD655\uC778\uC774 \uD544\uC694\uD569\uB2C8\uB2E4. [\uCE74\uCE74\uC624\uCC44\uB110\uAD00\uB9AC\uC790\uC571] > [\uAD00\uB9AC] \uD654\uBA74\uC744 \uCEA1\uCCD0\uD574 \uCC44\uD305\uC73C\uB85C \uC804\uB2EC\uD574\uC8FC\uC138\uC694. \uC54C\uB9BC\uD1A1 \uC5F0\uB3D9\uC744 \uC774\uC5B4\uC11C \uB3C4\uC640\uB4DC\uB824\uC694.", marginBottom: 8 }),
                    React.createElement(UnderlineClickableText, { color: COLORS.primary1, children: "\uAC80\uC0C9\uC6A9 \uC544\uC774\uB514 \uD655\uC778 \uBC29\uBC95", marginBottom: 12, onClick: function () {
                            return openWindow('https://oxidized-duke-790.notion.site/1b58cfcb24f64f798313fed2c9d71c58', '검색용 아이디 확인 방법"');
                        } }),
                    React.createElement(TextComponent, { children: "\uACE0\uAC1D\uC13C\uD130 : 1660-1890 \uD3C9\uC77C \uC624\uC804 10\uC2DC~\uC624\uD6C4 6\uC2DC (\uC8FC\uB9D0,\uACF5\uD734\uC77C \uD734\uBB34)", marginBottom: 8 })));
            case 'info':
                var renderPricing = function (label, price, size) {
                    return (React.createElement(Row, { justify: "space-between" },
                        React.createElement(Col, null,
                            React.createElement(TextComponent, { children: label, type: "headline3" }),
                            React.createElement(TextComponent, { children: size, type: "caption2", color: COLORS.gray1 })),
                        React.createElement(Col, null,
                            React.createElement(TextComponent, { children: "".concat(price, "\uC6D0"), type: "headline3" }))));
                };
                return (React.createElement("div", { style: { padding: '20px' } },
                    React.createElement(TextComponent, { type: "subtitle1", children: "\uC815\uBCF4\uC548\uB0B4\uB97C \uC704\uD55C \uC54C\uB9BC\uD1A1", marginBottom: 11 }),
                    React.createElement(TextComponent, { type: "body2", color: COLORS.gray1, children: "\uC608\uC57D, \uACB0\uC81C \uB4F1 \uC815\uBCF4\uC131 \uBB38\uC790\uB294 \uC2A4\uD338 \uC2A4\uD2B8\uB808\uC2A4 \uC5C6\uB294 \uC54C\uB9BC\uD1A1\uC73C\uB85C \uBC1C\uC1A1\uD558\uC138\uC694.\uB354 \uC800\uB834\uD55C \uAC00\uACA9\uC73C\uB85C \uD48D\uBD80\uD55C \uBA54\uC138\uC9C0\uB97C \uBCF4\uB0BC \uC218 \uC788\uC2B5\uB2C8\uB2E4. (\uC54C\uB9BC\uD1A1\uC740 \uC790\uB3D9\uBB38\uC790 \uBC1C\uC1A1\uB9CC \uAC00\uB2A5\uD569\uB2C8\uB2E4.)", marginBottom: 20 }),
                    React.createElement(TextComponent, { type: "subtitle1", children: "\uB9C8\uCF00\uD305\uC744 \uC704\uD55C \uCE5C\uAD6C\uD1A1", marginBottom: 11 }),
                    React.createElement(TextComponent, { type: "body2", color: COLORS.gray1, children: "\uBC29\uBB38\uC720\uB3C4, \uD560\uC778 \uC774\uBCA4\uD2B8 \uC548\uB0B4 \uB4F1 \uAD11\uACE0\uC131 \uBB38\uC790\uB294 \uCE5C\uAD6C\uD1A1\uC73C\uB85C \uBC1C\uC1A1\uD558\uC138\uC694. \uD6A8\uC728\uC801\uC778 \uBB38\uC790 \uBC1C\uC1A1\uC73C\uB85C  \uB9C8\uCF00\uD305\uD6A8\uACFC\uB97C \uADF9\uB300\uD654 \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.\n              (\uCE5C\uAD6C\uB97C \uB9FA\uC740 \uC774\uC6A9\uC790\uB97C \uB300\uC0C1\uC73C\uB85C \uAD11\uACE0\uC131 \uBB38\uC790 \uBC1C\uC1A1\uC774 \uAC00\uB2A5\uD569\uB2C8\uB2E4.)", marginBottom: 20 }),
                    React.createElement(TextComponent, { type: "subtitle1", children: "\uBB38\uC790 VS \uCE74\uCE74\uC624\uD1A1 \uAC00\uACA9\uBE44\uAD50", marginBottom: 11 }),
                    React.createElement(PricingTable, null,
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                React.createElement(TextComponent, { children: "\uBB38\uC790", type: "headline3", textAlign: "center" })),
                            React.createElement("td", null,
                                React.createElement(TextComponent, { children: "\uCE74\uCE74\uC624\uD1A1", type: "headline3", textAlign: "center" }))),
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                renderPricing('SMS', SMS_FEE, '90bytes'),
                                renderPricing('LMS', LMS_FEE, '2000bytes'),
                                renderPricing('MMS', MMS_FEE, '2000bytes')),
                            React.createElement("td", null,
                                renderPricing('알림톡', ATS_FEE, '1000자'),
                                renderPricing('친구톡', FTS_FEE, '1000자'),
                                renderPricing('친구톡(사진)', FMS_FEE, '1000자'))))));
        }
    };
    var store = props.store;
    var messageInfo = store === null || store === void 0 ? void 0 : store.messageSendInfo;
    var senderNumber = messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.senderNumber;
    var isRegistered = !!senderNumber;
    var kakaoData = store.kakaoSendInfo;
    var enableKakaoAlimtalk = !!kakaoData;
    return (React.createElement("div", null,
        React.createElement(TextComponent, { children: enableKakaoAlimtalk ? (kakaoData === null || kakaoData === void 0 ? void 0 : kakaoData.plusFriendID) || (kakaoData === null || kakaoData === void 0 ? void 0 : kakaoData.searchName) : '미등록', color: enableKakaoAlimtalk ? COLORS.success : COLORS.warning, marginBottom: 10 }),
        React.createElement(Row, { align: "middle" },
            React.createElement(AntPrimaryGhostButton, { disabled: !isRegistered, children: "\uC54C\uB9BC\uD1A1/\uCE5C\uAD6C\uD1A1 \uC2E0\uCCAD", onClick: function () { return setModal('join'); } }),
            React.createElement(Marginer, { width: 5 }),
            React.createElement(AntPrimaryGhostButton, { disabled: !isRegistered, children: "\uC54C\uB9BC\uD1A1/\uCE5C\uAD6C\uD1A1 \uC548\uB0B4", onClick: function () { return setModal('info'); } })),
        React.createElement(TextComponent, { type: "caption1", marginTop: 6, children: isRegistered
                ? enableKakaoAlimtalk
                    ? '카카오채널을 변경하고자 하시면 1:1 문의로 신청해 주세요.'
                    : '채널등록을 위해서 카카오 비지니스채널을 준비 후 1:1 문의로 신청해 주세요.'
                : '발신번호 인증절차를 완료해야 알림톡/친구톡을 신청 및 사용하실 수 있습니다.', color: COLORS.gray2 }),
        React.createElement(BigTitleModal, { centered: true, visible: !!modal, onCancel: function (_) { return setModal(undefined); }, footer: null, title: !modal ? '' : modal === 'join' ? '카카오 알림톡/친구톡 신청' : '카카오 알림톡/친구톡 안내', children: renderContent(modal) })));
};
var PointDiv = function (props) {
    var _a;
    var store = props.store;
    var _b = React.useState(false), visible = _b[0], setVisible = _b[1];
    var storePointQuery = StorePaymentMethodQuery.useStorePoint(store.id, { enabled: !!store.id });
    return (React.createElement("div", null,
        React.createElement(ChargeDrawer, { store: store, visible: visible, onClose: function () { return setVisible(false); } }),
        React.createElement(Row, { align: "middle" },
            React.createElement(TextComponent, { children: KRWFomatToString({ value: (_a = storePointQuery.data) === null || _a === void 0 ? void 0 : _a.amount }), marginRight: 16 }),
            React.createElement(AntButton, { children: "\uCDA9\uC804", onClick: function () { return setVisible(true); }, size: "small" })),
        React.createElement(TextComponent, { type: "caption1", marginTop: 6, children: "\uBB38\uC790, \uC54C\uB9BC\uD1A1/\uCE5C\uAD6C\uD1A1\uC758 \uAC74\uB2F9 \uC774\uC6A9\uB8CC\uB294 \u2018\uBB38\uC790 \uD3EC\uC778\uD2B8\u2019\uC5D0\uC11C \uCC28\uAC10\uB429\uB2C8\uB2E4.", color: COLORS.gray2 }),
        React.createElement(TextComponent, { type: "caption1", marginTop: 6, children: "SMS\uB294 ".concat(SMS_FEE, "\uC6D0, LMS\uB294 ").concat(LMS_FEE, "\uC6D0, MMS\uB294 ").concat(MMS_FEE, "\uC6D0\uC785\uB2C8\uB2E4."), color: COLORS.gray2 }),
        React.createElement(TextComponent, { type: "caption1", marginTop: 6, children: "\uC54C\uB9BC\uD1A1\uC740 ".concat(ATS_FEE, "\uC6D0, \uCE5C\uAD6C\uD1A1\uC740 ").concat(FTS_FEE, "\uC6D0, \uCE5C\uAD6C\uD1A1(\uC0AC\uC9C4)\uB294 ").concat(FMS_FEE, "\uC6D0\uC785\uB2C8\uB2E4."), color: COLORS.gray2 })));
};
var PricingTable = styled.table(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  border: solid 1px var(--gray-6);\n\n  & td:first-child {\n    border-right: solid 1px var(--gray-6);\n  }\n\n  & td {\n    padding: 16px;\n  }\n"], ["\n  width: 100%;\n  border: solid 1px var(--gray-6);\n\n  & td:first-child {\n    border-right: solid 1px var(--gray-6);\n  }\n\n  & td {\n    padding: 16px;\n  }\n"])));
var SettingRow = function (props) {
    return (React.createElement(Row, { gutter: 16, style: { marginBottom: '35px' } },
        React.createElement(Col, { span: props.isPC ? 4 : 24 },
            React.createElement(TextComponent, { children: props.title, color: COLORS.gray2 })),
        React.createElement(Col, { span: props.isPC ? 20 : 24 }, props.rightElement)));
};
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2;
