export var loadDynamicScript = function (url, id, callback) {
    var existingScript = document.getElementById('scriptId');
    if (!existingScript) {
        var script = document.createElement('script');
        script.src = url; // URL for the third-party library being loaded.
        script.id = id; // e.g., googleMaps or stripe
        document.head.appendChild(script);
        script.onload = function () {
            if (callback)
                callback();
        };
    }
    if (existingScript && callback)
        callback();
};
