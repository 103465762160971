var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Form from 'antd/lib/form';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import React from 'react';
import { AntdForm, DrawerComponent, DrawerFooter, GUIDE_NAMES, MoreActionIntable, SortableTables, TextComponent } from 'renderer/components';
import { EXTENDED_SALES_METHODS } from 'renderer/models';
import { CustomerGroupQuery, StoreQuery } from 'renderer/queries';
import { COLORS } from 'renderer/styles';
import { SettingTemplate } from './elements';
import Button from 'antd/lib/button';
import minBy from 'lodash/minBy';
export var CustomerGroupSetting = function (props) {
    var _a, _b, _c;
    var form = Form.useForm()[0];
    var hasUpdatePerm = (_a = props.permission) === null || _a === void 0 ? void 0 : _a.update;
    var store = StoreQuery.getMyStoreInCache();
    var _d = React.useState(undefined), drawer = _d[0], setDrawer = _d[1];
    var groupQuery = CustomerGroupQuery.useCustomerGroups(props.storeId, { enabled: !!props.storeId });
    var defaultPointRate = function () {
        var pointRate = {};
        Object.keys(store.paymentMethods).map(function (key) {
            if (key === EXTENDED_SALES_METHODS.SHARED_PREPAID_POINT) {
                return;
            }
            pointRate[key] = ((drawer === null || drawer === void 0 ? void 0 : drawer.pointRate) && (drawer === null || drawer === void 0 ? void 0 : drawer.pointRate[key])) || 0;
        });
        return pointRate;
    };
    var initialValues = __assign(__assign({}, drawer), { name: drawer === null || drawer === void 0 ? void 0 : drawer.name, pointRate: defaultPointRate() });
    React.useEffect(function () {
        if (drawer) {
            form.setFieldsValue(initialValues);
        }
    }, [drawer]);
    var createGroupMutate = CustomerGroupQuery.useCreateCustomerGroupMutation();
    var updateGroupMutate = CustomerGroupQuery.useUpdateCustomerGroupMutation();
    var deleteGroupMutate = CustomerGroupQuery.useDeleteCustomerGroupMutation();
    var handleGroupChange = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            console.log('values', values);
            if (drawer.id) {
                updateGroupMutate.mutateAsync({
                    storeId: props.storeId,
                    payload: [__assign(__assign(__assign({}, drawer), values), { storeId: props.storeId, id: drawer.id })]
                });
                notification.success({
                    message: '고객등급이 수정되었습니다.'
                });
            }
            else {
                createGroupMutate.mutateAsync(__assign(__assign(__assign({}, drawer), values), { storeId: props.storeId }));
                notification.success({
                    message: '고객등급이 생성되었습니다.'
                });
            }
            setDrawer(undefined);
            return [2 /*return*/];
        });
    }); };
    var handleDelete = function (group) {
        Modal.confirm({
            cancelText: '아니오',
            okText: '예',
            title: '해당 등급에 해당된 고객은 기본등급으로 전환됩니다. 삭제하시겠어요?',
            onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, deleteGroupMutate.mutateAsync({ storeId: props.storeId, id: group.id })];
                        case 1:
                            _a.sent();
                            notification.success({
                                message: '고객등급이 삭제되었습니다.'
                            });
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _a.sent();
                            notification.error({
                                message: error_1.message
                            });
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); }
        });
    };
    var fields = [
        {
            element: (React.createElement(TextComponent, { type: "headline3", color: COLORS.gray1, marginTop: 24, marginBottom: 30 }, "\uB4F1\uAE09\uBCC4 \uC124\uC815"))
        },
        {
            type: 'text',
            itemProps: {
                name: 'name',
                label: '등급명',
                rules: [{ type: 'string', required: true, min: 1, max: 10 }]
            },
            inputProps: { placeholder: '등급명', autoFocus: true, disabled: (drawer === null || drawer === void 0 ? void 0 : drawer.priority) === 0 }
        },
        {
            element: (React.createElement(TextComponent, { type: "headline3", color: COLORS.gray1, marginTop: 30, marginBottom: 30 }, "\uC801\uB9BD\uB960 \uC124\uC815"))
        }
    ].concat(Object.keys(store.paymentMethods)
        .map(function (key) {
        if (key === EXTENDED_SALES_METHODS.SHARED_PREPAID_POINT) {
            return;
        }
        var data = store.paymentMethods[key];
        if (data.active) {
            return {
                type: 'number',
                itemProps: {
                    name: ['pointRate', key],
                    label: data.name,
                    rules: [{ type: 'number', required: true, min: 0, max: 100 }]
                },
                inputProps: { placeholder: '0~100 사이 입력', suffix: '%' }
            };
        }
        else {
            return null;
        }
    })
        .filter(Boolean));
    var handleSortEnd = function (newItems) { return __awaiter(void 0, void 0, void 0, function () {
        var newData, basicItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newData = newItems.map(function (item) { return item.dataSource && item.dataSource[0]; }).filter(Boolean);
                    basicItem = minBy(groupQuery.data, function (item) { return item.id; });
                    return [4 /*yield*/, updateGroupMutate.mutateAsync({
                            storeId: props.storeId,
                            payload: [basicItem]
                                .concat([groupQuery.data[0]].concat(newData.filter(function (item) { return item.id !== basicItem.id; })))
                                .map(function (item, index) { return (__assign(__assign({}, item), { priority: index })); })
                                .filter(Boolean)
                        })];
                case 1:
                    _a.sent();
                    notification.success({
                        message: '고객등급이 순서변경이 완료되었습니다.'
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var getTableItem = function (item) {
        return {
            bordered: false,
            onRow: function (record) { return ({
                onClick: function () { return (!hasUpdatePerm ? notification.info({ message: '수정 권한이 없습니다.' }) : setDrawer(record)); }
            }); },
            id: item.id,
            // type: group.type,
            onSortEnd: handleSortEnd,
            columns: [
                {
                    dataIndex: 'value',
                    key: 'address',
                    align: 'left',
                    title: item.name,
                    render: function (value, record, index) {
                        return {
                            children: item.getPointRateString(store)
                        };
                    }
                },
                {
                    dataIndex: 'action',
                    key: 'actions',
                    align: 'right',
                    width: '20px',
                    title: (React.createElement(MoreActionIntable, { actions: [
                            {
                                icon: 'edit3',
                                title: '수정',
                                disabled: !hasUpdatePerm,
                                onClick: function () { return setDrawer(item); }
                            },
                            {
                                icon: 'trash',
                                title: '삭제',
                                disabled: !hasUpdatePerm || item.priority === 0,
                                onClick: function () { return handleDelete(item); }
                            }
                        ] }))
                }
            ],
            dataSource: [item]
        };
    };
    return (React.createElement(SettingTemplate, { guideName: GUIDE_NAMES.고객등급설정, body: React.createElement(React.Fragment, null,
            React.createElement(SortableTables, { onSortEnd: handleSortEnd, tables: ((_b = groupQuery.data) === null || _b === void 0 ? void 0 : _b.map(function (item, index) {
                    if (index === 0) {
                        return __assign(__assign({}, getTableItem(item)), { disableParentSort: true });
                    }
                }).filter(Boolean)) || [] }),
            React.createElement(SortableTables, { onSortEnd: handleSortEnd, tables: ((_c = groupQuery.data) === null || _c === void 0 ? void 0 : _c.map(function (item, index) {
                    if (index === 0) {
                        return;
                    }
                    else {
                        return getTableItem(item);
                    }
                }).filter(Boolean)) || [] }),
            React.createElement(DrawerComponent, { title: "\uACE0\uAC1D \uB4F1\uAE09 ".concat((drawer === null || drawer === void 0 ? void 0 : drawer.id) ? '수정' : '생성'), open: !!drawer, onClose: function () { return setDrawer(undefined); }, footer: React.createElement(DrawerFooter, { onCancel: function () { return setDrawer(undefined); }, onConfirm: form.submit, confirmText: "".concat((drawer === null || drawer === void 0 ? void 0 : drawer.id) ? '수정' : '생성') }) },
                React.createElement(AntdForm, { formProps: {
                        form: form,
                        initialValues: initialValues,
                        onFinish: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                handleGroupChange(values);
                                return [2 /*return*/];
                            });
                        }); }
                    }, onSubmit: handleGroupChange, fields: fields }))), title: "\uACE0\uAC1D \uB4F1\uAE09 \uC124\uC815", action: React.createElement(Button, { type: "primary", children: '새 등급 등록', onClick: function () { return setDrawer({ storeId: props.storeId }); } }), description: "\uACE0\uAC1D\uC5D0 \uB530\uB77C \uB4F1\uAE09\uC744 \uB2E4\uB974\uAC8C \uC124\uC815\uD574\uBCF4\uC138\uC694. \uB4F1\uAE09\uBCC4\uB85C \uC801\uB9BD\uB960\uC744 \uB2E4\uB974\uAC8C \uC124\uC815\uD560 \uC218 \uC788\uC5B4\uC694!" }));
};
