var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Modal from 'antd/lib/modal';
import React from 'react';
import { COLORS } from 'renderer/styles';
import { createGlobalStyle } from 'styled-components';
import Button from 'antd/lib/button';
export var AntModalFunc = function (props) {
    var func = Modal[props.type || 'info'];
    return func(__assign({ centered: true }, props));
};
export var AntConfirmWithSuccessIcon = function (props) {
    var func = Modal[props.type || 'info'];
    var cancelButtonProps = props.cancelButtonProps;
    var okButtonProps = props.okButtonProps;
    return func(__assign({ centered: true, footer: function (_, _a) {
            var OkBtn = _a.OkBtn;
            return (React.createElement(React.Fragment, null,
                React.createElement(Button, __assign({}, cancelButtonProps)),
                React.createElement(OkBtn, __assign({}, okButtonProps))));
        } }, props));
};
export var AntModalGlobalStyled = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .ant-modal-confirm-btns {\n        justify-content: flex-end;\n\n        & > button {\n            flex: 0;\n            \n            &.ant-btn-primary:not(.ant-btn-dangerous) {\n                background-color: ", ";\n                &:hover {\n                    background-color: ", " !important;\n                }\n                &:active {\n                    background-color: ", " !important;\n                }\n            }\n        }\n    }\n"], ["\n    .ant-modal-confirm-btns {\n        justify-content: flex-end;\n\n        & > button {\n            flex: 0;\n            \n            &.ant-btn-primary:not(.ant-btn-dangerous) {\n                background-color: ", ";\n                &:hover {\n                    background-color: ", " !important;\n                }\n                &:active {\n                    background-color: ", " !important;\n                }\n            }\n        }\n    }\n"])), COLORS.primary1, COLORS.primary3, COLORS.primary3);
var templateObject_1;
