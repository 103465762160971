var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { CustomerRelationModel } from 'renderer/models';
import { BigTitleModal, HookFormInputs, TableComponent } from 'renderer/components';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CustomerQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import { Layout } from 'common-ui';
import Button from 'antd/lib/button';
export var CustomerRelationModal = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g = React.useState(!!props.customerRelation), visible = _g[0], setVisible = _g[1];
    React.useEffect(function () {
        var visible = !!props.customerRelation;
        setVisible(visible);
        if (visible) {
            form.reset(props.customerRelation);
        }
    }, [props.customerRelation]);
    var form = useForm({
        mode: 'onChange',
        defaultValues: props.customerRelation
    });
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props.onSubmit(__assign(__assign({}, values), { otherCustomer: otherCustomerQuery.data }))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, function (errors) { });
    var otherCustomerId = form.watch('otherCustomerId');
    var mainCustomer = ((_d = CustomerQuery.useCustomerDetail({
        id: Number((_a = props.customerRelation) === null || _a === void 0 ? void 0 : _a.mainCustomerId),
        storeId: props.storeId
    }, { enabled: !!(((_b = props.customerRelation) === null || _b === void 0 ? void 0 : _b.mainCustomerId) && !((_c = props.customerRelation) === null || _c === void 0 ? void 0 : _c.mainCustomer)) })) === null || _d === void 0 ? void 0 : _d.data) || ((_e = props.customerRelation) === null || _e === void 0 ? void 0 : _e.mainCustomer);
    var otherCustomerQuery = CustomerQuery.useCustomerDetail({ id: otherCustomerId, storeId: props.storeId }, { enabled: !!otherCustomerId });
    var isUpdated = !!((_f = props.customerRelation) === null || _f === void 0 ? void 0 : _f.id);
    var values = form.watch();
    return (React.createElement(BigTitleModal, { centered: true, title: "\uACE0\uAC1D \uC5F0\uACB0\uD558\uAE30", width: "360px", visible: visible, onCancel: function () { return props.onClose(); }, footer: isUpdated ? (React.createElement(Layout.FluidInline, { justify: "start", stretch: "start" },
            React.createElement(Layout.Inline, { justify: "start" },
                React.createElement(Button, { type: "primary", danger: true, children: "\uC0AD\uC81C", onClick: function () { var _a; return props.onDelete((_a = props.customerRelation) === null || _a === void 0 ? void 0 : _a.id); } })),
            React.createElement(Layout.Inline, { justify: "end", gutter: 4 },
                React.createElement(Button, { children: "\uB2EB\uAE30", onClick: props.onClose }),
                React.createElement(Button, { type: "primary", children: "\uC800\uC7A5", onClick: onSubmit })))) : (React.createElement(Layout.FluidColumns, { gutter: 8, columns: 2 },
            React.createElement(Layout.Column, { span: 1 },
                React.createElement(Button, { children: "\uB2EB\uAE30", style: { width: '100%' }, onClick: props.onClose })),
            React.createElement(Layout.Column, { span: 1 },
                React.createElement(Button, { type: "primary", children: "\uD655\uC778", style: { width: '100%' }, onClick: onSubmit })))), onOk: onSubmit, children: React.createElement("div", { style: { paddingLeft: '20px', paddingRight: '20px' } },
            React.createElement(StyledForm, null,
                React.createElement(HookFormInputs.CustomerSelector, { label: "\uC5F0\uACB0\uD560 \uACE0\uAC1D\uBA85*", isHorizontal: true, controlProps: {
                        name: 'otherCustomerId',
                        control: form.control,
                        rules: {
                            required: '연결할 고객을 선택해주세요.'
                        }
                    }, inputProps: {
                        placeholder: '고객명(필수)',
                        autoFocus: true,
                        hideDetailButton: true,
                        onChange: function (customerId) {
                            if (customerId === Number(mainCustomer === null || mainCustomer === void 0 ? void 0 : mainCustomer.id)) {
                                notification.warning({ message: '본인은 등록할 수 없습니다.' });
                                form.setValue('otherCustomerId', '');
                            }
                        }
                    } }),
                React.createElement(HookFormInputs.Text, { label: "\uBCF8\uC778\uC758 \uAD00\uACC4\uBA85*", isHorizontal: true, controlProps: {
                        name: 'mainCustomerTitle',
                        control: form.control,
                        rules: {
                            required: '연결할 고객을 선택해주세요.',
                            maxLength: {
                                value: 6,
                                message: '관계명은 6자 이하로 작성해주세요.'
                            }
                        }
                    }, inputProps: {
                        placeholder: '본인의 관계명을 입력해주세요.',
                        autoFocus: true
                    } }),
                React.createElement(HookFormInputs.Text, { label: "\uC5F0\uACB0\uD560 \uACE0\uAC1D\uC758 \uAD00\uACC4\uBA85*", isHorizontal: true, controlProps: {
                        name: 'otherCustomerTitle',
                        control: form.control,
                        rules: {
                            required: '연결할 고객의 관계명을 입력해주세요.',
                            maxLength: {
                                value: 6,
                                message: '관계명은 6자 이하로 작성해주세요.'
                            }
                        }
                    }, inputProps: {
                        placeholder: '연결할 고객의 관계명을 입력',
                        autoFocus: true
                    } }),
                React.createElement(HookFormInputs.SelectInput, { label: '선불액 ∙ 정기권 공유*', isHorizontal: true, memoDeps: [values === null || values === void 0 ? void 0 : values.mainCustomerTitle, values === null || values === void 0 ? void 0 : values.otherCustomerTitle], inputProps: {
                        placeholder: '공유 설정',
                        style: { width: '100%', maxWidth: '100%' },
                        options: [
                            { label: '서로공유', value: CustomerRelationModel.SHARE_OPTIONS.ALL },
                            {
                                label: "".concat(values.mainCustomerTitle || '본인 관계명', " \uAC83\uB9CC \uACF5\uC720"),
                                value: CustomerRelationModel.SHARE_OPTIONS.MAIN
                            },
                            {
                                label: "".concat(values.otherCustomerTitle || '연결할 고객관계명', " \uAC83\uB9CC \uACF5\uC720"),
                                value: CustomerRelationModel.SHARE_OPTIONS.SUB
                            },
                            { label: "\uACF5\uC720\uC548\uD568", value: CustomerRelationModel.SHARE_OPTIONS.NONE }
                        ]
                    }, controlProps: {
                        name: 'shareOption',
                        control: form.control,
                        rules: {
                            required: '선불액∙ 정기권 공유을 입력해주세요..'
                        }
                    } }),
                React.createElement(HookFormInputs.Text, { label: '메모', isHorizontal: true, controlProps: {
                        name: 'memo',
                        control: form.control
                    }, inputProps: {
                        placeholder: '메모 입력'
                    } })),
            React.createElement(TableComponent, { columns: [
                    {
                        title: '고객명',
                        key: 'name',
                        render: function (value) {
                            return value.name;
                        }
                    },
                    {
                        title: '관계명',
                        key: 'relationName',
                        render: function (value) {
                            return value.relationName;
                        }
                    },
                    {
                        title: '선불액∙ 정기권 공유',
                        key: 'isShare',
                        render: function (value) {
                            return value.shareOption;
                        }
                    }
                ], rowKey: function (row) { return row.key; }, dataSource: [
                    {
                        name: (mainCustomer === null || mainCustomer === void 0 ? void 0 : mainCustomer.name) || '신규고객',
                        relationName: values.mainCustomerTitle,
                        shareOption: values.shareOption === CustomerRelationModel.SHARE_OPTIONS.NONE
                            ? '공유안함'
                            : values.shareOption === CustomerRelationModel.SHARE_OPTIONS.ALL
                                ? '서로공유'
                                : values.shareOption === CustomerRelationModel.SHARE_OPTIONS.MAIN
                                    ? '공유'
                                    : '공유안함'
                    },
                    otherCustomerQuery &&
                        otherCustomerQuery.data && {
                        name: otherCustomerQuery.data.name,
                        relationName: values.otherCustomerTitle,
                        shareOption: values.shareOption === CustomerRelationModel.SHARE_OPTIONS.NONE
                            ? '공유안함'
                            : values.shareOption === CustomerRelationModel.SHARE_OPTIONS.ALL
                                ? '서로공유'
                                : values.shareOption === CustomerRelationModel.SHARE_OPTIONS.SUB
                                    ? '공유'
                                    : '공유안함'
                    }
                ].filter(Boolean) })) }));
};
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > div.ant-row {\n    margin-top: 10px;\n  }\n\n  margin-top: 10px;\n  margin-bottom: 20px;\n"], ["\n  & > div.ant-row {\n    margin-top: 10px;\n  }\n\n  margin-top: 10px;\n  margin-bottom: 20px;\n"])));
var templateObject_1;
