import React from 'react';
import { EntireLoader } from 'renderer/components';
export var EntireLoaderContext = React.createContext({});
export var EntireLoaderProvider = function (props) {
    var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
    var show = React.useCallback(function () {
        if (!visible) {
            setVisible(true);
        }
    }, []);
    var hide = React.useCallback(function () {
        setVisible(false);
    }, []);
    return (React.createElement(EntireLoaderContext.Provider, { value: {
            hide: hide,
            show: show,
            visible: visible
        } }, props.children));
};
export var useEntireLoader = function () {
    return React.useContext(EntireLoaderContext);
};
export var EntireLoaderForProvider = function () {
    var visible = React.useContext(EntireLoaderContext).visible;
    return React.createElement(EntireLoader, { open: visible });
};
