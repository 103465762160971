var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { times } from 'renderer/utils';
import { COLORS } from '../../styles/colors';
import { Row, Col } from 'antd/lib/grid';
import { useSpring, animated } from 'react-spring';
export var Stepper = function (props) {
    return (React.createElement(Wrapper, { gutter: 8 }, times(props.total).map(function (index) {
        return React.createElement(Step, { key: index, color: props.color || COLORS.success, isCurrent: index === props.current });
    })));
};
var Wrapper = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var Step = function (props) {
    var style = useSpring({ width: props.isCurrent ? '100%' : '0%' });
    return (React.createElement(StepWrapper, { flex: 1 },
        React.createElement("div", { className: "container" },
            React.createElement(animated.div, { className: "fill", style: style }))));
};
var StepWrapper = styled(Col)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 6px;\n\n  & .container {\n    position: relative;\n    width: 100%;\n    height: 6px;\n    background: ", ";\n  }\n  & .fill {\n    position: absolute;\n    top: 0;\n    left: 0;\n    // width: 100%;\n    height: 100%;\n    background: ", ";\n  }\n"], ["\n  height: 6px;\n\n  & .container {\n    position: relative;\n    width: 100%;\n    height: 6px;\n    background: ", ";\n  }\n  & .fill {\n    position: absolute;\n    top: 0;\n    left: 0;\n    // width: 100%;\n    height: 100%;\n    background: ", ";\n  }\n"])), COLORS.gray6, COLORS.success);
var templateObject_1, templateObject_2;
