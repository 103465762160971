var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { TextComponent, DrawerFooter, DrawerComponent, Icon, AntLeftIconButton } from 'renderer/components';
import { LOCAL_STORAGE_KEY_TABLE_COLUMNS } from 'renderer/constants';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import Switch from 'antd/lib/switch';
import { Row } from 'antd/lib/grid';
import { IMAGES } from 'renderer/assets';
import { useHover, useLocalStorage, isEmpty } from 'renderer/utils';
export var DragHandlerIcon = function (props) {
    var _a = useHover(), hoverRef = _a[0], isHovered = _a[1];
    return React.createElement(IconImage, { ref: hoverRef, src: props.isHovered || isHovered ? IMAGES.icon_drag_hover : IMAGES.icon_drag });
};
var SortableItem = SortableElement(function (_a) {
    var value = _a.value, onChangeActive = _a.onChangeActive;
    var _b = useHover(), hoverRef = _b[0], isHovered = _b[1];
    return (React.createElement(ListItemWrapper, { ref: hoverRef, key: "item-".concat(value.columnKey), children: React.createElement(React.Fragment, null,
            React.createElement(Row, { align: "middle", wrap: false },
                React.createElement(DragHandlerIcon, { isHovered: !!isHovered }),
                React.createElement(TextComponent, { marginLeft: 10, children: value.title, color: COLORS.gray1 })),
            React.createElement(GreenSwitch, { checked: value.isActive, onChange: function (checked) {
                    onChangeActive(__assign(__assign({}, value), { isActive: checked }));
                } })) }));
});
var IconImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: grab;\n"], ["\n  cursor: grab;\n"])));
var SortableList = SortableContainer(function (_a) {
    var items = _a.items, onChangeActive = _a.onChangeActive;
    return (React.createElement("div", null,
        React.createElement(HeaderWrapper, { children: React.createElement(React.Fragment, null,
                React.createElement(TextComponent, { children: "\uB370\uC774\uD130 \uC774\uB984", color: COLORS.gray3 }),
                React.createElement(TextComponent, { children: "\uB178\uCD9C", color: COLORS.gray3 })) }),
        items.map(function (value, index) { return (React.createElement(SortableItem, { key: "item-".concat(value.columnKey), index: index, value: value, onChangeActive: onChangeActive })); })));
});
var GreenSwitch = styled(Switch)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &.ant-switch-checked {\n    background-color: ", ";\n  }\n"], ["\n  &.ant-switch-checked {\n    background-color: ", ";\n  }\n"])), COLORS.success);
var ListItemWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 43px;\n  width: 100%;\n  padding: 11px 23px 11px 23px;\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 5px;\n  cursor: grab;\n  z-index: 9999999;\n"], ["\n  height: 43px;\n  width: 100%;\n  padding: 11px 23px 11px 23px;\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 5px;\n  cursor: grab;\n  z-index: 9999999;\n"])));
var HeaderWrapper = styled(ListItemWrapper)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: var(--gray-7);\n  padding: 11px 23px 11px 40px;\n  cursor: default;\n"], ["\n  background-color: var(--gray-7);\n  padding: 11px 23px 11px 40px;\n  cursor: default;\n"])));
var getLocalstorageKey = function (tableId) {
    return "".concat(LOCAL_STORAGE_KEY_TABLE_COLUMNS, "_").concat(tableId);
};
export var TableColumnSetter = function (props) {
    var defaultColumns = props.defaultColumns, tableId = props.tableId, trigger = props.trigger;
    var initialValues = defaultColumns.filter(Boolean).map(function (column) { return ({
        columnKey: "".concat(column.key),
        title: column.title,
        isActive: true
    }); });
    var _a = useLocalStorage(getLocalstorageKey(tableId), initialValues, {
        store: true,
        manager: true
    }), storedValue = _a[0], setStoredValue = _a[1];
    var _b = React.useState(storedValue), columns = _b[0], setColumns = _b[1];
    var _c = React.useState(!props.visible), visible = _c[0], setVisible = _c[1];
    // FIXME: 테스트가 필요함 title이 변경되었거나, column키가 추가 혹은 삭제되었을때 이를 로컬스토리지에 업데이트
    React.useEffect(function () {
        var hasChanged = false;
        var newStoredValue = storedValue
            .map(function (item) {
            var target = initialValues.filter(Boolean).find(function (value) { return value.columnKey === item.columnKey; });
            // 컬럼이 삭제되었을 경우
            if (!target) {
                hasChanged = true;
                return undefined;
            }
            else {
                // 제목이 변경된 경우
                if (item.title !== target.title) {
                    hasChanged = true;
                    return __assign(__assign({}, item), { title: target.title });
                }
            }
            return item;
        })
            .filter(Boolean);
        initialValues.map(function (value) {
            var target = storedValue.filter(Boolean).find(function (item) { return item.columnKey === value.columnKey; });
            if (!target) {
                hasChanged = true;
                newStoredValue = newStoredValue.concat(__assign(__assign({}, value), { isActive: true }));
            }
        });
        if (hasChanged) {
            setStoredValue(newStoredValue);
            setColumns(newStoredValue);
        }
    }, [initialValues]);
    React.useEffect(function () {
        if (visible !== props.visible) {
            setVisible(props.visible);
        }
    }, [props.visible]);
    var handleClick = function () {
        setVisible(!visible);
    };
    var handleSortEnd = function (_a) {
        var oldIndex = _a.oldIndex, newIndex = _a.newIndex;
        if (oldIndex !== newIndex) {
            setColumns(arrayMove(columns, oldIndex, newIndex));
        }
    };
    var handleChangeActive = function (newValue) {
        setColumns(columns.map(function (item) { return (item.columnKey === newValue.columnKey ? newValue : item); }));
    };
    var handleSave = function () {
        setStoredValue(columns);
        props.handleChange(columns);
        handleClose();
    };
    var handleClose = function () {
        setVisible(false);
        if (props.onCloseSetter) {
            props.onCloseSetter();
        }
    };
    return (React.createElement(React.Fragment, null,
        props.trigger && React.cloneElement(props.trigger, { onClick: handleClick }),
        React.createElement(DrawerComponent, { open: visible, title: "\uD45C \uB178\uCD9C\uC815\uBCF4 \uC124\uC815", onClose: handleClose, footer: React.createElement(DrawerFooter, { confirmText: "\uC800\uC7A5", onConfirm: handleSave, onCancel: handleClose }), children: React.createElement("div", null,
                React.createElement(TextComponent, { type: "headline3", color: COLORS.gray1, children: "\uB178\uCD9C \uBC0F \uC21C\uC11C \uC124\uC815" }),
                React.createElement(TextComponent, { marginTop: 10, marginBottom: 36, color: COLORS.gray3, children: "\uD45C\uC5D0 \uB178\uCD9C\uC744 \uC6D0\uD558\uB294 \uB370\uC774\uD130\uB97C \uC124\uC815\uD558\uACE0, \uC21C\uC11C\uB97C \uC815\uD560 \uC218 \uC788\uC5B4\uC694. \uC67C\uCABD \uC544\uC774\uCF58\uC744 \uD074\uB9AD\uD574 \uC21C\uC11C\uB97C \uC62E\uACA8\uBCF4\uC138\uC694." }),
                React.createElement(SortableList, { items: columns, onSortEnd: handleSortEnd, onChangeActive: handleChangeActive })) })));
};
export var useTableColumnGetter = function (tableId, defaultColumns) {
    if (tableId) {
        var _a = useLocalStorage(getLocalstorageKey(tableId), defaultColumns || undefined, {
            store: true,
            manager: true
        }), result = _a[0], setResult = _a[1];
        if (!result || isEmpty(result)) {
            setResult(defaultColumns);
        }
        return [!result || isEmpty(result) ? defaultColumns : result, setResult];
    }
    else {
        return [null, null];
    }
};
export var TableSetterDefaultTrigger = function (props) {
    return (React.createElement(AntLeftIconButton, { children: React.createElement(Icon, { name: "settings", size: 18, color: COLORS.black }), size: "large", style: { marginLeft: '8px' }, onClick: props.onClick }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
