var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import React from 'react';
import { COLORS } from 'renderer/styles';
import styled from 'styled-components';
import { ClickableIcon, ClickableText, Icon, AntPrimaryGhostButton, TextComponent, AntLeftIconButton } from 'renderer/components';
import { Row } from 'antd/lib/grid';
import Checkbox from 'antd/lib/checkbox';
import { addOrRemove, isEmpty, sum, uniq } from 'renderer/utils';
import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';
export var CustomDropdownButton = function (props) {
    var _a;
    return (React.createElement(CustomDropdown, { items: props.items },
        React.createElement(AntPrimaryGhostButton, { style: { padding: '4px 0px' } },
            React.createElement(Row, { align: "middle", justify: "center", wrap: false }, (_a = props.buttonProps) === null || _a === void 0 ? void 0 :
                _a.children,
                React.createElement(Icon, { name: "up-chevron", style: { marginLeft: '5px' } })))));
};
export var CustomDropdownContainButton = function (props) {
    var _a;
    return (React.createElement(CustomDropdown, { items: props.items },
        React.createElement(Button, { type: "primary" },
            React.createElement(Row, { align: "middle", justify: "center", wrap: false }, (_a = props.buttonProps) === null || _a === void 0 ? void 0 :
                _a.children,
                React.createElement(Icon, { name: "up-chevron", style: { marginLeft: '5px' } })))));
};
export var CustomDropdown = function (props) {
    var items = props.items, children = props.children, rest = __rest(props, ["items", "children"]);
    var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
    return (React.createElement(Dropdown, __assign({ overlay: React.createElement(CustomDropdownMenu, { items: props.items, onClick: function () { return setVisible(!visible); } }), placement: "bottomRight", trigger: ['click'], visible: visible, onVisibleChange: function (visible) {
            setVisible(visible);
        } }, rest), props.children));
};
export var CustomDropdownMenu = function (props) {
    var items = props.items;
    return (React.createElement(StyledMenu, { mode: "vertical", onClick: function (ev) {
            if (props.onClick) {
                props.onClick();
            }
        } }, items.map(function (action, index) {
        var disabled = action.disabled;
        return (React.createElement(Menu.Item, { disabled: disabled, onClick: action.onClick, key: index, icon: action.icon ? (typeof action.icon === 'string' ? (React.createElement(Icon, { size: 16, name: action.icon, color: disabled ? COLORS.gray4 : action.color || COLORS.gray1 })) : (action.icon)) : undefined },
            React.createElement(TextComponent, { color: action.color || COLORS.gray1 }, action.label)));
    })));
};
var StyledMenu = styled(Menu)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 4px;\n  border: solid 1px var(--gray-6);\n  & li {\n    height: 32px !important;\n    align-items: center;\n    display: flex;\n  }\n\n  & .ant-menu-item-selected {\n    background-color: ", " !important;\n\n    &:hover {\n      ", "\n    }\n  }\n"], ["\n  border-radius: 4px;\n  border: solid 1px var(--gray-6);\n  & li {\n    height: 32px !important;\n    align-items: center;\n    display: flex;\n  }\n\n  & .ant-menu-item-selected {\n    background-color: ", " !important;\n\n    &:hover {\n      ", "\n    }\n  }\n"])), COLORS.white, COLORS.primaryFocus);
var StyledMenuItem = styled(Menu.Item)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 32px;\n  ", "\n\n  & > img {\n    opacity: ", ";\n  }\n"], ["\n  height: 32px;\n  ", "\n\n  & > img {\n    opacity: ", ";\n  }\n"])), function (props) {
    return !props.disabled &&
        "\n        &:hover {\n          background-color: ".concat(COLORS.gray7, ";\n          color: ").concat(COLORS.gray1, " !important;\n        }\n    ");
}, function (props) { return (props.disabled ? 0.4 : 1); });
export var CheckboxDropdownContent = function (props) {
    var groups = props.groups, onChange = props.onChange;
    var _a = React.useState(props.values || []), values = _a[0], setValues = _a[1];
    React.useEffect(function () {
        if (values !== props.values) {
            setValues(props.values || []);
        }
    }, [props.values]);
    var handleChange = function (key, isGroup) {
        var _a;
        var newValues = addOrRemove(values, key);
        var isRemoved = ((_a = props.values) === null || _a === void 0 ? void 0 : _a.length) > newValues.length;
        var target = isGroup
            ? groups.find(function (group) { return group.value === key; })
            : groups.find(function (group) { var _a; return !!((_a = group.items) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.value === key; })); });
        var items = (target && target.items) || [];
        if (isGroup) {
            if (isRemoved) {
                newValues = newValues.filter(function (item) { return !items.map(function (item) { return item.value; }).includes(item); });
            }
            else {
                newValues = uniq(newValues.concat(items === null || items === void 0 ? void 0 : items.map(function (item) { return item.value; })));
            }
        }
        else {
            var selectedItems = newValues.filter(function (value) { return items.map(function (item) { return item.value; }).includes(value); });
            if (selectedItems.length === items.length) {
                newValues = uniq(__spreadArray([target.value], newValues, true));
            }
            else {
                newValues = newValues.filter(function (item) { return item !== target.value; });
            }
        }
        onChange(newValues);
    };
    var isAllCount = sum(props.groups.filter(Boolean).map(function (group) {
        var _a;
        if (isEmpty(group === null || group === void 0 ? void 0 : group.items)) {
            return 1;
        }
        else {
            return ((_a = group === null || group === void 0 ? void 0 : group.items) === null || _a === void 0 ? void 0 : _a.length) + 1;
        }
    }));
    var isAll = isAllCount === values.length;
    var isActive = values.length > 0;
    var _b = React.useState(false), visible = _b[0], setVisible = _b[1];
    return (React.createElement(CalendarFilterTooltip, { onClose: props.onCloseTooltip, open: props.tooltipOpen },
        React.createElement(Dropdown, { onOpenChange: function (newVisible) {
                setVisible(newVisible);
            }, open: visible, trigger: ['click'], dropdownRender: function () { return (React.createElement(DropdownOverlayWrapper, null,
                React.createElement(ClickableText, { marginBottom: 5, children: isAll ? '선택해제' : '전체선택', onClick: function () {
                        if (isAll) {
                            onChange([]);
                        }
                        else {
                            var values_1 = [];
                            groups.filter(Boolean).map(function (group) {
                                values_1 = values_1.concat(group.value).concat(((group === null || group === void 0 ? void 0 : group.items) || []).map(function (item) { return item.value; }));
                            });
                            onChange(uniq(values_1));
                        }
                    }, color: COLORS.primary1, type: "body2" }), groups === null || groups === void 0 ? void 0 :
                groups.filter(Boolean).map(function (group, index) {
                    var groupChecked = values.includes(group.value);
                    return (React.createElement("div", { key: "group_".concat(group.value, "_").concat(index) },
                        React.createElement(StyledCheckbox, { value: group.value, onChange: function (ev) { return handleChange(group.value, true); }, checked: groupChecked, children: React.createElement(CheckboxRow, null,
                                group.left,
                                React.createElement(TextComponent, { marginLeft: 5 },
                                    " ",
                                    group.label)) }),
                        group.items ? (React.createElement(SubItems, null, group.items.filter(Boolean).map(function (item) {
                            var checked = values.includes(item.value);
                            return (React.createElement(StyledCheckbox, { value: item.value, onChange: function (ev) { return handleChange(item.value, false); }, checked: checked, key: "items_".concat(item.value, "_").concat(index), children: React.createElement(CheckboxRow, null,
                                    item.left,
                                    React.createElement(TextComponent, { marginLeft: 5 },
                                        " ",
                                        item.label)) }));
                        }))) : (React.createElement("div", null))));
                }))); } },
            React.createElement(Row, { align: "middle" },
                React.createElement(AntLeftIconButton, { size: "small", children: "".concat(props.label, " ").concat(values.length ? "(".concat(values.length, ")") : ''), icon: React.createElement(Icon, { name: "down-chevron" }), style: { marginRight: '8px' }, type: isActive ? 'primary' : 'default', ghost: isActive })))));
};
export var CalendarFilterTooltip = function (props) {
    return (React.createElement(Tooltip, { title: React.createElement(Row, { align: "middle" },
            React.createElement(TextComponent, { type: "body2", color: COLORS.white, children: "\uD544\uD130\uAC12 \uC801\uC6A9\uB41C \uC608\uC57D\uB9CC \uC870\uD68C\uB429\uB2C8\uB2E4." }),
            React.createElement(ClickableIcon, { name: "X", size: 24, onClick: props.onClose })), open: props.open }, props.children));
};
var StyledCheckbox = styled(Checkbox)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &.ant-checkbox-wrapper + .ant-checkbox-wrapper {\n    margin-left: 0px !important;\n  }\n"], ["\n  &.ant-checkbox-wrapper + .ant-checkbox-wrapper {\n    margin-left: 0px !important;\n  }\n"])));
var CheckboxRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
var SubItems = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: 20px;\n  display: flex;\n  flex-direction: column;\n"], ["\n  margin-left: 20px;\n  display: flex;\n  flex-direction: column;\n"])));
var DropdownOverlayWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-radius: 4px;\n  border: solid 1px var(--gray-6);\n  background-color: ", ";\n  padding: 10px;\n"], ["\n  border-radius: 4px;\n  border: solid 1px var(--gray-6);\n  background-color: ", ";\n  padding: 10px;\n"])), COLORS.white);
export var AntDropdown = Dropdown;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
