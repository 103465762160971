var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Checkbox from 'antd/lib/checkbox/Checkbox';
import styled, { css } from 'styled-components';
export var CheckboxComponent = styled(Checkbox)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) {
    return props.backgroundColor && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      & .ant-checkbox-checked .ant-checkbox-inner {\n        background-color: ", ";\n        border-color: ", ";\n      }\n    "], ["\n      & .ant-checkbox-checked .ant-checkbox-inner {\n        background-color: ", ";\n        border-color: ", ";\n      }\n    "])), props.backgroundColor, props.backgroundColor);
});
var templateObject_1, templateObject_2;
