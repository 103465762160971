var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { notification } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { StorePaymentMethodQuery } from 'renderer/queries';
import { BigTitleModal, HookFormInputs, TextComponent, useEntireLoader } from 'renderer/components';
import dayjs from 'dayjs';
import { checkBizNumber } from 'renderer/utils';
import styled from 'styled-components';
export var CreditCardRegister = function (props) {
    var form = useForm({
        mode: 'onBlur'
    });
    React.useEffect(function () {
        if (props.visible) {
            form.reset();
        }
    }, [props.visible]);
    var loader = useEntireLoader();
    var createMethod = StorePaymentMethodQuery.useCreatePaymehtMethod();
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload_1, result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    loader.show();
                    payload_1 = {};
                    Object.keys(values).map(function (key) {
                        payload_1[key] = values[key] && values[key].replace(/\s/g, '');
                        if (key === 'expiry') {
                            var values_1 = payload_1[key].split('/');
                            payload_1[key] = "20".concat(values_1[1]).concat(values_1[0]);
                        }
                    });
                    return [4 /*yield*/, createMethod.mutateAsync(__assign({ storeId: props.storeId }, payload_1))];
                case 1:
                    result = _a.sent();
                    notification.success({ message: '결제 카드 등록이 완료되었습니다.' });
                    form.reset();
                    loader.hide();
                    props.onClose(result);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    loader.hide();
                    notification.error({ message: error_1.message || '카드 등록 중 에러가 발생했습니다.' });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    return (React.createElement(BigTitleModal, { centered: true, title: "\uC0C8 \uCE74\uB4DC \uB4F1\uB85D", visible: props.visible, okText: "\uCD94\uAC00", onCancel: function () { return props.onClose(); }, onOk: onSubmit, children: React.createElement(StyledForm, null,
            React.createElement(TextComponent, { children: "\uD305\uCEE4\uBDF0\uB294 \uC5B4\uB5A0\uD55C \uACB0\uC81C\uC815\uBCF4\uB3C4 \uC9C1\uC811 \uC800\uC7A5\uD558\uAC70\uB098 \uC811\uADFC\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. \uAC80\uC99D\uB41C \uD0C0\uC0AC \uACB0\uC81C\uC2DC\uC2A4\uD15C\uC744 \uC774\uC6A9\uD558\uACE0 \uC788\uC73C\uBA70 \uBAA8\uB4E0 \uCE74\uB4DC\uC815\uBCF4\uB294 \uC554\uD638\uD654\uB418\uC5B4 \uD574\uB2F9 \uACB0\uC81C \uD68C\uC0AC\uC5D0\uC11C \uAD00\uB9AC\uB418\uB2C8 \uC548\uC2EC\uD558\uC138\uC694." }),
            React.createElement(HookFormInputs.Text, { label: '카드번호', controlProps: {
                    name: 'cardNumber',
                    control: form.control,
                    rules: {
                        required: '카드번호를 입력해주세요.',
                        validate: function (value) {
                            value = value.replace(/\s/g, '');
                            if (/^[0-9]{4}[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{3,4}$/.test(value)) {
                                return true;
                            }
                            else {
                                return '올바른 카드번호를 입력해주세요.';
                            }
                            return true;
                        }
                    }
                }, inputProps: {
                    placeholder: '•••• •••• •••• ••••',
                    autoFocus: true,
                    type: 'tel',
                    normalizer: function (value) {
                        return "".concat(value)
                            .substring(0, 22)
                            .replace(/[^\d]/g, '')
                            .replace(/(.{4})/g, '$1  ')
                            .trim();
                    }
                } }),
            React.createElement(HookFormInputs.Text, { label: '카드 유효기간(MM/YY)', inputProps: {
                    placeholder: 'MM/YY',
                    type: 'tel',
                    normalizer: function (value) {
                        if (value.length > 2) {
                            return "".concat(value)
                                .replace(/[^\d]/g, '')
                                .replace(/(\d{2})/g, '$1/')
                                .substring(0, 5)
                                .trim();
                        }
                        else {
                            return "".concat(value).replace(/[^\d]/g, '').trim();
                        }
                    }
                }, controlProps: {
                    name: 'expiry',
                    control: form.control,
                    rules: {
                        required: '유효기간을 입력해주세요.',
                        validate: function (value) {
                            var values = value.split('/');
                            value = "20".concat(values[1]).concat(values[0]);
                            var date = dayjs("".concat(value, "30 00:00:00"));
                            if (!/20[0-4]\d(0[1-9]|1[0-2])/g.test(value)) {
                                return '올바른 유효기간을 입력해주세요.';
                            }
                            if (dayjs().isAfter(date.endOf('month'))) {
                                return '유효기간이 만료된 카드입니다.';
                            }
                            else {
                                return true;
                            }
                        }
                    }
                } }),
            React.createElement(HookFormInputs.Text, { label: '생년월일 YYMMDD 또는 사업자번호 10자리', controlProps: {
                    name: 'birth',
                    control: form.control,
                    rules: {
                        required: '생년월일(6자리) 또는 사업자번호를 입력해주세요.',
                        validate: function (value) {
                            var isValidBirth = value.length === 6 && /((0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))/.test(value);
                            if (isValidBirth || checkBizNumber(value)) {
                                return true;
                            }
                            else {
                                return '올바른 생년월일 또는 10자리 사업자 번호를 입력해주세요.';
                            }
                        }
                    }
                }, inputProps: {
                    placeholder: 'YYMMDD',
                    autoComplete: 'off',
                    type: 'tel',
                    normalizer: function (value) { return value.substring(0, 10).trim(); }
                } }),
            React.createElement(HookFormInputs.Text, { label: '카드 비밀번호 앞 두자리 XX', inputProps: {
                    placeholder: '**',
                    autoComplete: 'new-password',
                    type: 'tel',
                    normalizer: function (value) { return "".concat(value).substring(0, 2).trim(); }
                }, controlProps: {
                    name: 'pwd2digit',
                    control: form.control,
                    rules: {
                        required: '비밀번호 앞 2자리를 입력해주세요.',
                        validate: function (value) {
                            if (!/^\d{2}$/.test(value)) {
                                return '올바른 비밀번호를 입력해주세요.';
                            }
                            return true;
                        }
                    }
                } })) }));
};
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n  & > * {\n    margin-bottom: 15px;\n  }\n"], ["\n  padding: 20px;\n  & > * {\n    margin-bottom: 15px;\n  }\n"])));
var templateObject_1;
