var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Col } from 'antd/lib/grid';
import Row from 'antd/lib/row';
import React from 'react';
import { GuideIcon, TextComponent } from 'renderer/components';
import { COLORS } from 'renderer/styles';
import styled from 'styled-components';
export var SettingTemplate = function (props) {
    return (React.createElement(SettingTemplateWrapper, { className: props.className },
        React.createElement(Row, { justify: "space-between", align: "middle" },
            React.createElement(Col, null,
                React.createElement(Row, null,
                    React.createElement(TextComponent, { children: props.title, type: "headline3", marginRight: 10 }),
                    props.guideName && React.createElement(GuideIcon, { guideName: props.guideName }))),
            React.createElement(Col, null, props.action)),
        props.description ? (typeof props.description === 'string' ? (React.createElement(TextComponent, { marginTop: 10, marginBottom: 20, children: props.description, color: COLORS.gray2, type: "subtitle2" })) : (props.description)) : (React.createElement("div", null)),
        props.body));
};
var SettingTemplateWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var templateObject_1;
