var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import React from 'react';
import { AntButton, ZIndexer } from 'renderer/components';
import Drawer from 'antd/lib/drawer';
import styled from 'styled-components';
export var DrawerComponent = function (props) {
    return props.open || props.visible ? (React.createElement(ZIndexer, null, function (_a) {
        var zIndex = _a.zIndex;
        return React.createElement(StyledDrawer, __assign({ width: 360 }, props, { zIndex: zIndex }));
    })) : (React.createElement("div", null));
};
var StyledDrawer = styled(Drawer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & .ant-drawer-title {\n    margin-top: 0px;\n  }\n  & .ant-drawer-close {\n    position: absolute;\n    right: 0px;\n  }\n"], ["\n  & .ant-drawer-title {\n    margin-top: 0px;\n  }\n  & .ant-drawer-close {\n    position: absolute;\n    right: 0px;\n  }\n"])));
export var DrawerFooter = function (props) {
    return (React.createElement(Row, { justify: props.justify || 'start' },
        props.leftExtra,
        React.createElement(Col, { offset: 1 }, props.cancelButton ? (props.cancelButton) : (React.createElement(AntButton, { ghost: true, type: "primary", onClick: props.onCancel }, props.cancelText || '취소'))),
        React.createElement(Col, { offset: 1 }, props.confirmButton ? (props.confirmButton) : (React.createElement(AntButton, { type: "primary", onClick: props.onConfirm }, props.confirmText || '등록'))),
        props.extra));
};
var templateObject_1;
