var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as Styles from './styles.css';
import Card from 'antd/lib/card';
import React from 'react';
import clsx from 'clsx';
import { commonCssTypography, TextComponent } from 'renderer/components';
import { typographyByName } from 'renderer/components/Typography';
import Row from 'antd/lib/row';
import { IMAGES } from 'renderer/assets';
import { useLocalStorage } from 'renderer/utils';
import styled, { css } from 'styled-components';
import { COLORS, MediaQuery } from 'renderer/styles';
export var CollapsibleCardComponent = function (props) {
    var isCollapisble = props.isCollapisble, localStorageKey = props.localStorageKey, rest = __rest(props, ["isCollapisble", "localStorageKey"]);
    var _a = isCollapisble && localStorageKey
        ? useLocalStorage(localStorageKey, false, { store: true, manager: true })
        : React.useState(false), collapsed = _a[0], setCollapsed = _a[1];
    var title = typeof props.title === 'string' ? (React.createElement(CardTitle, { title: props.title, isCollapisble: isCollapisble, onChangeCollapsed: function () {
            setCollapsed(!collapsed);
        }, collapsed: collapsed })) : (props.title);
    return (React.createElement(Card, __assign({ size: "small" }, rest, { className: clsx(Styles.antCardWrapper, collapsed && isCollapisble && Styles.collapsed, rest.className), title: title })));
};
export var CardTitle = function (props) {
    return (React.createElement(Row, { justify: "space-between" },
        React.createElement(TextComponent, { type: "body1" }, props.title),
        props.isCollapisble && (React.createElement("img", { src: IMAGES.arraow_up, className: Styles.pointer, onClick: props.onChangeCollapsed }))));
};
export var CardWithSubmit = function (props) {
    return React.createElement(StyledCardWithSubmit, __assign({}, props));
};
export var StyledCardWithSubmit = styled(Card)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  &.ant-card {\n    border: 1px solid ", ";\n    ", "\n  }\n\n  & .ant-card-head {\n    ", "\n  }\n\n  & .ant-card-head-title {\n    ", "\n    ", "\n  }\n\n  & .ant-card-body {\n    padding: 36px 26px 26px;\n    ", "\n  }\n  & .ant-card-actions {\n    & > li {\n      padding: 16px 24px;\n      margin: 0px;\n    }\n  }\n"], ["\n  &.ant-card {\n    border: 1px solid ", ";\n    ", "\n  }\n\n  & .ant-card-head {\n    ", "\n  }\n\n  & .ant-card-head-title {\n    ", "\n    ", "\n  }\n\n  & .ant-card-body {\n    padding: 36px 26px 26px;\n    ", "\n  }\n  & .ant-card-actions {\n    & > li {\n      padding: 16px 24px;\n      margin: 0px;\n    }\n  }\n"])), COLORS.gray6, MediaQuery.tabletWithMobile(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      height: 100%;\n    "], ["\n      height: 100%;\n    "]))), MediaQuery.tabletWithMobile(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      display: none;\n    "], ["\n      display: none;\n    "]))), commonCssTypography, css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ", "\n    "])), typographyByName('headline2')), MediaQuery.tabletWithMobile(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      padding: 16px;\n      flex:1;\n    "], ["\n      padding: 16px;\n      flex:1;\n    "]))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
