var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { TextComponent, BizNumberNormalizer, GUIDE_NAMES, Marginer, PhoneNumberNormalizer, HookFormInputs, SettingTemplate, AntButton } from 'renderer/components';
import { isEmpty } from 'renderer/utils';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { COLORS } from 'renderer/styles';
import { CategoryQuery, StoreQuery } from 'renderer/queries';
import { BizNumberValidator, REGEX_PHONE } from 'renderer/constants';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import notification from 'antd/lib/notification';
export var BasicInfo = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var updateStore = StoreQuery.useUpdateStore();
    var categoryQuery = CategoryQuery.useCategories();
    var address = typeof ((_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.address) === 'object' ? (_d = (_c = props.store) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.address : (_e = props.store) === null || _e === void 0 ? void 0 : _e.address;
    var methods = useForm({
        mode: 'onChange',
        defaultValues: __assign(__assign({}, props.store), { address: __assign(__assign({}, address), { detailAddress: (_f = props.store) === null || _f === void 0 ? void 0 : _f.address.detailAddress }), categories: (_h = (_g = props.store) === null || _g === void 0 ? void 0 : _g.categories) === null || _h === void 0 ? void 0 : _h.map(function (category) { return category.id; }) })
    });
    React.useEffect(function () {
        var _a, _b, _c;
        methods.reset(__assign(__assign({}, props.store), { address: __assign(__assign({}, address), { detailAddress: (_a = props.store) === null || _a === void 0 ? void 0 : _a.address.detailAddress }), categories: (_c = (_b = props.store) === null || _b === void 0 ? void 0 : _b.categories) === null || _c === void 0 ? void 0 : _c.map(function (category) { return category.id; }) }));
    }, [props.store]);
    var onSubmit = methods.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var address, detailAddress, location;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!((_a = props.permission) === null || _a === void 0 ? void 0 : _a.update)) {
                        return [2 /*return*/, notification.error({
                                message: '상점관리 수정 권한이 없습니다. 관리자계정을 통해 권한을 허용해주세요. '
                            })];
                    }
                    address = values.address;
                    detailAddress = values === null || values === void 0 ? void 0 : values.address.detailAddress;
                    if (address.location) {
                        location = address.location;
                    }
                    if (address.latLng) {
                        location = address.latLng;
                    }
                    address = typeof (address === null || address === void 0 ? void 0 : address.address) === 'object' ? address.address : address;
                    address = __assign(__assign({}, address), { detailAddress: detailAddress });
                    return [4 /*yield*/, updateStore.mutateAsync(__assign(__assign({}, values), { address: address, location: location }))];
                case 1:
                    _b.sent();
                    notification.success({ message: '상점 정보 수정완료' });
                    return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        notification.error({ message: '상점 정보 수정 중 에러발생' });
    });
    var submitable = isEmpty(methods.formState.errors) && ((_j = methods.formState) === null || _j === void 0 ? void 0 : _j.isDirty);
    return (React.createElement(SettingTemplate, { title: "\uC0C1\uC810 \uAE30\uBCF8 \uC124\uC815", guideName: GUIDE_NAMES.상점정보관리, description: "\uD504\uB85C\uADF8\uB7A8\uC744 \uC0AC\uC6A9 \uC804 \uD544\uC218\uB85C \uD544\uC694\uD55C \uC124\uC815\uC785\uB2C8\uB2E4.", body: React.createElement("div", null,
            React.createElement(Row, { gutter: 16, style: { width: '100%' } },
                React.createElement(Col, { lg: { span: 16 }, sm: { span: 24 } },
                    React.createElement(StyledForm, { onSubmit: methods.handleSubmit(onSubmit) },
                        React.createElement("div", null,
                            React.createElement(HookFormInputs.Text, { label: "\uC0C1\uC810\uBA85", controlProps: {
                                    control: methods.control,
                                    name: 'name',
                                    rules: { required: { value: true, message: '상점명 입력해주세요.' } }
                                }, inputProps: {
                                    size: 'large'
                                } })),
                        React.createElement("div", null,
                            React.createElement(HookFormInputs.Address, { label: "\uC8FC\uC18C(\uC0AC\uC5C5\uC7A5 \uC18C\uC7AC\uC9C0)", controlProps: {
                                    control: methods.control,
                                    name: 'address',
                                    rules: { required: { value: true, message: '주소를 입력해주세요.' } }
                                }, inputProps: {
                                    size: 'large'
                                } }),
                            React.createElement(Marginer, { height: 5 }),
                            React.createElement(HookFormInputs.Text, { controlProps: {
                                    control: methods.control,
                                    name: 'address.detailAddress',
                                    rules: { required: { value: true, message: '상세주소를 입력해주세요.' } }
                                }, inputProps: {
                                    size: 'large'
                                } })),
                        React.createElement("div", null,
                            React.createElement(HookFormInputs.SelectInput, { label: "\uC0C1\uC810 \uCE74\uD14C\uACE0\uB9AC", inputProps: {
                                    style: { width: '100%' },
                                    size: 'large',
                                    mode: 'multiple',
                                    placeholder: '담당자 선택',
                                    options: (_k = categoryQuery.data) === null || _k === void 0 ? void 0 : _k.map(function (item) { return ({
                                        value: item.id,
                                        label: "".concat(item.group, "-").concat(item.item)
                                    }); }).filter(Boolean)
                                }, memoDeps: [categoryQuery.data], controlProps: {
                                    control: methods.control,
                                    name: 'categories',
                                    rules: {
                                        required: { value: true, message: '카테고리를 선택해주세요.' },
                                        maxLength: { value: 5, message: '카테고리는 5개까지 선택할 수 있습니다.' },
                                        validate: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                if ((value === null || value === void 0 ? void 0 : value.length) > 5) {
                                                    return [2 /*return*/, '카테고리는 5개까지 선택할 수 있습니다.'];
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); }
                                    }
                                } })),
                        React.createElement("div", null,
                            React.createElement(HookFormInputs.Text, { label: "\uC720\uC120\uBC88\uD638", controlProps: {
                                    control: methods.control,
                                    name: 'tel',
                                    rules: {
                                        pattern: {
                                            value: /^(0[2-8][0-5]?|01[01346-9])-?([1-9]{1}[0-9]{2,3})-?([0-9]{4})$/,
                                            message: '올바른 번호를 입력해주세요.'
                                        }
                                    }
                                }, inputProps: {
                                    placeholder: '유선번호를 입력해주세요.',
                                    size: 'large'
                                } })),
                        React.createElement("div", null,
                            React.createElement(HookFormInputs.Text, { label: "\uD734\uB300\uD3F0", controlProps: {
                                    control: methods.control,
                                    name: 'phone',
                                    rules: {
                                        required: { value: true, message: '휴대폰번호를 입력해주세요.' },
                                        pattern: {
                                            value: REGEX_PHONE,
                                            message: '올바른 번호를 입력해주세요.'
                                        }
                                    }
                                }, inputProps: {
                                    size: 'large',
                                    normalizer: PhoneNumberNormalizer
                                } })),
                        React.createElement("div", null,
                            React.createElement(HookFormInputs.Text, { label: "\uC0AC\uC5C5\uC790 \uB4F1\uB85D\uBC88\uD638", controlProps: {
                                    control: methods.control,
                                    name: 'businessLicenseNumber',
                                    rules: {
                                        validate: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                                            var error_1;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        _a.trys.push([0, 2, , 3]);
                                                        return [4 /*yield*/, BizNumberValidator(value, props.store)];
                                                    case 1:
                                                        _a.sent();
                                                        return [2 /*return*/, true];
                                                    case 2:
                                                        error_1 = _a.sent();
                                                        return [2 /*return*/, error_1];
                                                    case 3: return [2 /*return*/];
                                                }
                                            });
                                        }); }
                                    }
                                }, inputProps: {
                                    size: 'large',
                                    normalizer: BizNumberNormalizer
                                } }),
                            React.createElement(TextComponent, { type: "body2", children: "\uC720\uB8CC\uD50C\uB79C \uCCB4\uD5D8, \uBB38\uC790 \uBC1C\uC1A1 \uC11C\uBE44\uC2A4\uB97C \uC774\uC6A9\uD558\uC2DC\uB824\uBA74 \uC0AC\uC5C5\uC790 \uB4F1\uB85D\uBC88\uD638\uB97C \uB4F1\uB85D\uD574\uC57C \uD569\uB2C8\uB2E4.", color: COLORS.gray2 }))))),
            React.createElement(AntButton, { type: "primary", children: "\uBCC0\uACBD\uC0AC\uD56D \uC800\uC7A5", disabled: !submitable, onClick: onSubmit })) }));
};
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > * {\n    margin-bottom: 10px;\n  }\n"], ["\n  & > * {\n    margin-bottom: 10px;\n  }\n"])));
var templateObject_1;
