var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { DrawerComponent, AntdForm, DrawerFooter, TextComponent, Icon, ResponsiveWithPlusIcon, ColorTag, AntPrimaryTextButton, GUIDE_NAMES, CardWithTabs, checkPermissionForOnClick, SettingTemplate, KRWFomatToString, CheckboxWithAll, typographyByName, VitualRenderSortableTables } from 'renderer/components';
import { MANAGER_PERMISSION_ACTIONS, MANAGER_PERMISSION_KEYS, MANAGER_PERMISSION_PRIORITY } from 'renderer/models';
import { convertMinutesToYYYYMMDDHHmm, isArray, useWindowSize } from 'renderer/utils';
import dayjs from 'dayjs';
import { ProductQuery, ManagerQuery, NaverLambdaQuery } from 'renderer/queries';
import Row from 'antd/lib/row';
import Form from 'antd/lib/form';
import styled, { css } from 'styled-components';
import { COLORS } from 'renderer/styles';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import Modal from 'antd/lib/modal';
import Col from 'antd/lib/col';
import notification from 'antd/lib/notification';
export var ProductSetting = function (props) {
    var _a, _b, _c;
    var _d = React.useState(), drawer = _d[0], setDrawer = _d[1];
    var isPC = useWindowSize().isPC;
    var loginManager = ManagerQuery.getLoginManagerInCache();
    var commonParams = {
        key: MANAGER_PERMISSION_KEYS.PRODUCT_LIST,
        priority: MANAGER_PERMISSION_PRIORITY.ALL
    };
    var hasCreatePerm = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission(__assign(__assign({}, commonParams), { action: MANAGER_PERMISSION_ACTIONS.CREATE }));
    var hasUpdatePerm = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission(__assign(__assign({}, commonParams), { action: MANAGER_PERMISSION_ACTIONS.UPDATE }));
    var hasDeletePerm = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission(__assign(__assign({}, commonParams), { action: MANAGER_PERMISSION_ACTIONS.DELETE }));
    var handleClose = function () {
        // Modal.confirm({ title: '정말??', content: '읭??' });
        setDrawer(null);
    };
    var storeId = (_a = props.store) === null || _a === void 0 ? void 0 : _a.id;
    var productGroupQuery = ProductQuery.useProducts({ storeId: storeId, paranoid: true }, { enabled: !!storeId });
    React.useEffect(function () {
        if (storeId) {
            productGroupQuery.refetch();
        }
    }, []);
    var handleOrder = function (records) { return __awaiter(void 0, void 0, void 0, function () {
        var record, isGroup, items, data, group, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!checkPermissionForOnClick(hasUpdatePerm, '상품 수정 권한이 없습니다.')) {
                        return [2 /*return*/];
                    }
                    record = records[0];
                    if (!record) {
                        return [2 /*return*/];
                    }
                    isGroup = !!(record === null || record === void 0 ? void 0 : record.columns);
                    items = (_a = productGroupQuery.data) === null || _a === void 0 ? void 0 : _a.groups;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, , 7]);
                    if (!isGroup) return [3 /*break*/, 3];
                    data = records.map(function (item, index) { return ({ id: item.id, priority: index, type: item.type }); });
                    return [4 /*yield*/, updateGroupMutate.mutateAsync({ payload: data, storeId: storeId })];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    group = items[items.findIndex(function (item) { return item.id === record.productGroupId; })];
                    if (!(group && group.products)) return [3 /*break*/, 5];
                    return [4 /*yield*/, updateProductMutate.mutateAsync({
                            payload: records.map(function (item, index) { return (__assign(__assign({}, item), { priority: index })); }),
                            storeId: storeId
                        })];
                case 4:
                    _b.sent();
                    _b.label = 5;
                case 5:
                    notification.success({ message: '상품순서가 변경되었습니다.' });
                    return [3 /*break*/, 7];
                case 6:
                    error_1 = _b.sent();
                    console.log('error', error_1);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function (record) {
        if (!checkPermissionForOnClick(hasDeletePerm, '상품 삭제 권한이 없습니다.')) {
            return;
        }
        if (record.hasActiveTicket) {
            return notification.warning({
                message: '판매된 정기권이 있어 연결된 상품메뉴 삭제가 불가능합니다. 판매된 정기권을 모두 소진한 후 상품을 삭제해주세요.'
            });
        }
        var message = record.isGroup
            ? record.hasTemplate
                ? '1차 상품 분류를 삭제하시면 하위 상품들도 모두 삭제되며 연결된 정기권메뉴도 함께 삭제됩니다. 삭제하시겠습니까?'
                : '1차 상품 분류를 삭제하시면 하위 상품들도 모두 삭제됩니다. 삭제하시겠습니까?'
            : record.hasTemplate
                ? '상품메뉴를 삭제하면 연결된 정기권메뉴도 삭제됩니다. 상품을 삭제하시겠습니까?'
                : '상품을 삭제하시겠습니까?';
        Modal.confirm({
            cancelText: '아니오',
            okText: '예',
            title: '상품 삭제',
            centered: true,
            zIndex: 9999,
            content: message,
            onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!record.isGroup) return [3 /*break*/, 2];
                            return [4 /*yield*/, deleteGroupMutate.mutateAsync({ id: record.id, storeId: storeId })];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, deleteProductMutate.mutateAsync({ id: record.id, storeId: storeId })];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            handleClose();
                            notification.success({ message: '상품이 삭제되었습니다.' });
                            return [2 /*return*/];
                    }
                });
            }); }
        });
    };
    var naverOptionUpdate = NaverLambdaQuery.useUpdateNaverOption();
    var createGroupMutate = ProductQuery.useCreateProductGroup();
    var updateGroupMutate = ProductQuery.useUpdateProductGroups();
    var deleteGroupMutate = ProductQuery.useDeleteProductGroup();
    var createProductMutate = ProductQuery.useCreateProduct();
    var updateProductMutate = ProductQuery.useUpdateProducts();
    var deleteProductMutate = ProductQuery.useDeleteProduct();
    var clearNaverCache = NaverLambdaQuery.useDeleteNaverStoreCache();
    var handleSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var isUpdate, product, showUpdateModal, time, date, product_1, newData_1, hasTemplate, group, hasTemplate;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    isUpdate = (_a = drawer.data) === null || _a === void 0 ? void 0 : _a.id;
                    if (isUpdate) {
                        if (!checkPermissionForOnClick(hasUpdatePerm, '상품 수정 권한이 없습니다.')) {
                            return [2 /*return*/];
                        }
                    }
                    product = (_c = (_b = productGroupQuery.data) === null || _b === void 0 ? void 0 : _b.products) === null || _c === void 0 ? void 0 : _c.find(function (item) { var _a; return item.id === ((_a = drawer.data) === null || _a === void 0 ? void 0 : _a.id); });
                    if ((product === null || product === void 0 ? void 0 : product.hasActiveTicket) && (data === null || data === void 0 ? void 0 : data.demandType) === 'inside') {
                        return [2 /*return*/, notification.warning({
                                message: '판매된 정기권이 있어 연결된 상품메뉴의 용도변경이 불가능합니다. 판매된 정기권을 모두 소진한 후 상품 용도를 변경 해주세요.'
                            })];
                    }
                    showUpdateModal = function (func, hasTemplate, product) {
                        Modal.confirm({
                            cancelText: '아니오',
                            okText: '예',
                            title: '상품 변경',
                            zIndex: 9999,
                            centered: true,
                            content: hasTemplate && (data === null || data === void 0 ? void 0 : data.demandType) === 'inside'
                                ? '상품메뉴의 용도를 내수소모로 변경하면 연결된 정기권메뉴도 삭제됩니다. 상품용도를 변경 하시겠습니까?'
                                : hasTemplate
                                    ? '상품메뉴명 변경시 연결된 정기권메뉴명도 변경됩니다. 고객에게 이미 판매된 정기권의 이름은 변경되지 않습니다. '
                                    : '상품을 변경하시겠습니까?',
                            onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var smartplace, prevProduct, options_1, businessId, optionId, error_2;
                                var _a, _b, _c, _d, _e;
                                return __generator(this, function (_f) {
                                    switch (_f.label) {
                                        case 0:
                                            _f.trys.push([0, 5, , 6]);
                                            return [4 /*yield*/, func()];
                                        case 1:
                                            _f.sent();
                                            smartplace = (_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.preference) === null || _b === void 0 ? void 0 : _b.smartplace;
                                            if (!(product && smartplace && !((_c = smartplace.blockSettings) === null || _c === void 0 ? void 0 : _c.allowPartialDuplicate))) return [3 /*break*/, 4];
                                            prevProduct = (_e = (_d = productGroupQuery.data) === null || _d === void 0 ? void 0 : _d.products) === null || _e === void 0 ? void 0 : _e.find(function (item) { return item.id === product.id; });
                                            options_1 = smartplace === null || smartplace === void 0 ? void 0 : smartplace.options;
                                            businessId = smartplace === null || smartplace === void 0 ? void 0 : smartplace.bookingBusinessId;
                                            if (!(product.time !== prevProduct.time)) return [3 /*break*/, 4];
                                            optionId = Object.keys(options_1).find(function (key) { return Number(options_1[key].agencyKey) === product.id; });
                                            if (!optionId) return [3 /*break*/, 4];
                                            return [4 /*yield*/, naverOptionUpdate.mutateAsync({
                                                    businessId: businessId,
                                                    optionId: Number(optionId),
                                                    serviceDuration: Math.min(300, product.time)
                                                })];
                                        case 2:
                                            _f.sent();
                                            return [4 /*yield*/, clearNaverCache.mutateAsync({ storeId: storeId })];
                                        case 3:
                                            _f.sent();
                                            _f.label = 4;
                                        case 4:
                                            notification.success({ message: '상품정보 변경이 완료되었습니다.' });
                                            handleClose();
                                            return [3 /*break*/, 6];
                                        case 5:
                                            error_2 = _f.sent();
                                            return [3 /*break*/, 6];
                                        case 6: return [2 /*return*/];
                                    }
                                });
                            }); }
                        });
                    };
                    if (!data.productGroupId) return [3 /*break*/, 4];
                    time = 0;
                    if (data.time !== 0) {
                        date = dayjs(data.time);
                        time = date.hour() * 60 + date.minute();
                    }
                    if (!isUpdate) return [3 /*break*/, 1];
                    product_1 = (_e = (_d = productGroupQuery === null || productGroupQuery === void 0 ? void 0 : productGroupQuery.data) === null || _d === void 0 ? void 0 : _d.products) === null || _e === void 0 ? void 0 : _e.find(function (item) { return item.id === drawer.data.id; });
                    newData_1 = __assign(__assign({}, data), { time: time, storeId: storeId, id: drawer.data.id });
                    hasTemplate = product_1 === null || product_1 === void 0 ? void 0 : product_1.hasTemplate;
                    showUpdateModal(function () { return updateProductMutate.mutateAsync({ storeId: storeId, payload: [newData_1] }); }, hasTemplate, newData_1);
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, createProductMutate.mutateAsync(__assign(__assign({}, data), { time: time, storeId: storeId }))];
                case 2:
                    _j.sent();
                    handleClose();
                    notification.success({ message: '상품이 생성되었습니다.' });
                    _j.label = 3;
                case 3: return [3 /*break*/, 7];
                case 4:
                    if (!isUpdate) return [3 /*break*/, 5];
                    group = (_g = (_f = productGroupQuery === null || productGroupQuery === void 0 ? void 0 : productGroupQuery.data) === null || _f === void 0 ? void 0 : _f.groups) === null || _g === void 0 ? void 0 : _g.find(function (item) { return item.id === drawer.data.id; });
                    hasTemplate = !!((_h = group === null || group === void 0 ? void 0 : group.products) === null || _h === void 0 ? void 0 : _h.find(function (item) { return item.hasTemplate; }));
                    showUpdateModal(function () {
                        return updateGroupMutate.mutateAsync({ storeId: storeId, payload: [__assign(__assign({}, data), { storeId: storeId, id: drawer.data.id })] });
                    }, hasTemplate);
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, createGroupMutate.mutateAsync(__assign(__assign({}, data), { storeId: storeId }))];
                case 6:
                    _j.sent();
                    handleClose();
                    notification.success({ message: '상품이 생성되었습니다.' });
                    _j.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var _e = React.useState('service'), activeTab = _e[0], setActiveTab = _e[1];
    var topAction = [
        {
            icon: 'plus',
            title: '1차 분류 상품 등록',
            onClick: function () { return setDrawer({ grade: 'group', data: { type: activeTab || 'service' } }); }
        },
        {
            icon: 'plus',
            title: '2차 분류 상품 등록',
            onClick: function () { return setDrawer({ grade: 'product' }); }
        }
    ];
    var tableProps = {
        hasDeletePerm: hasDeletePerm,
        onDelete: handleDelete,
        hasUpdatePerm: hasUpdatePerm,
        hasCreatePerm: hasCreatePerm,
        onOpenDrawer: function (data) { return setDrawer(data); },
        isPC: isPC,
        type: 'service',
        handleOrder: handleOrder,
        groups: (_b = productGroupQuery.data) === null || _b === void 0 ? void 0 : _b.groups,
        dataUpdatedAt: productGroupQuery.dataUpdatedAt
    };
    return (React.createElement(SettingTemplateWithTab, { title: '상품관리', description: "", guideName: GUIDE_NAMES.상품관리, action: React.createElement(Dropdown, { menu: {
                onClick: function (_a) {
                    var key = _a.key;
                    if (checkPermissionForOnClick(hasCreatePerm, '상품 생성 권한이 없습니다. ')) {
                        topAction[key].onClick();
                    }
                },
                items: topAction.map(function (action, index) {
                    return {
                        icon: React.createElement(Icon, { name: action.icon }),
                        action: action.onClick,
                        key: "".concat(index),
                        label: action.title
                    };
                })
            }, trigger: ['click'], placement: "bottomRight" },
            React.createElement(ResponsiveWithPlusIcon, { children: "\uC0C1\uD488 \uB4F1\uB85D" })), body: React.createElement(React.Fragment, null,
            React.createElement(StyledCardWithTabs, { preventBodyScroll: true, tabList: [
                    { key: 'service', tab: '시술' },
                    { key: 'product', tab: '제품' }
                ], onTabChange: function (tab) {
                    setActiveTab(tab);
                }, activeTabKey: activeTab, children: {
                    service: React.createElement(ProductSortableTable, __assign({}, tableProps, { type: "service" })),
                    product: React.createElement(ProductSortableTable, __assign({}, tableProps, { type: "product" }))
                } }),
            React.createElement(ProductFormDrawer, { drawer: drawer, onClose: handleClose, onSubmit: handleSubmit, onDelete: handleDelete, groups: (_c = productGroupQuery.data) === null || _c === void 0 ? void 0 : _c.groups })) }));
};
var ProductSortableTable = function (props) {
    var _a, _b;
    var isPC = props.isPC, hasDeletePerm = props.hasDeletePerm, hasUpdatePerm = props.hasUpdatePerm, hasCreatePerm = props.hasCreatePerm;
    var isService = props.type === 'service';
    var columns = getColumns(isService, isPC);
    var actions = [
        { icon: 'plus', title: '2차 분류 상품 등록', grade: 'product' },
        { icon: 'edit3', title: '1차 분류 상품명 수정', grade: 'group' },
        { icon: 'trash', title: '1차 분류 상품 삭제', grade: 'delete' }
    ];
    return (React.createElement(VitualRenderSortableTables, { onSortEnd: props.handleOrder, tables: (_b = (_a = props.groups) === null || _a === void 0 ? void 0 : _a.filter(function (group) { return group.type === props.type; })) === null || _b === void 0 ? void 0 : _b.map(function (group) {
            return {
                bordered: false,
                onRow: function (record) { return ({
                    onClick: function () { return props.onOpenDrawer({ grade: 'product', data: record }); }
                }); },
                scroll: isService ? null : { x: 1024 },
                id: group.id,
                type: group.type,
                onSortEnd: props.handleOrder,
                columns: columns === null || columns === void 0 ? void 0 : columns.map(function (column, index) {
                    if (index === 0) {
                        if (isService) {
                            return __assign(__assign({}, column), { key: "".concat(group.id, "_").concat(index), title: React.createElement(TextComponent, { children: group.name, type: "subtitle3", color: COLORS.gray3 }) });
                        }
                        else {
                            return __assign(__assign({}, column), { key: "".concat(group.id, "_").concat(index), render: function () { return group.name; } });
                        }
                    }
                    else {
                        return column;
                    }
                }).concat({
                    width: 40,
                    fixed: 'right',
                    title: (React.createElement(Row, { justify: "end" },
                        React.createElement(MoreActionIntable, { actions: actions.map(function (action) {
                                var disabled = action.grade === 'product'
                                    ? !hasCreatePerm
                                    : action.grade === 'delete'
                                        ? !hasDeletePerm
                                        : action.grade === 'group'
                                            ? !hasUpdatePerm
                                            : false;
                                var onClick;
                                if (action.grade === 'delete') {
                                    onClick = function () { return props.onDelete(group); };
                                }
                                else if (action.grade === 'group') {
                                    onClick = function () { return props.onOpenDrawer({ grade: 'group', data: group }); };
                                }
                                else if (action.grade === 'product') {
                                    onClick = function () {
                                        return props.onOpenDrawer({
                                            grade: 'product',
                                            data: { productGroupId: group.id }
                                        });
                                    };
                                }
                                return __assign(__assign({}, action), { disabled: disabled, onClick: onClick });
                            }) })))
                }),
                dataSource: group.products || []
            };
        }) }));
};
export var MoreActionIntable = function (_a) {
    var actions = _a.actions;
    return (React.createElement(StyledButton, { type: 'text', overlay: React.createElement(StyledMenu, null, actions.map(function (action, index) {
            var color = !action.disabled ? COLORS.gray1 : COLORS.gray6;
            return (React.createElement(Menu.Item, { key: "".concat(index, "_").concat(action.title), disabled: action.disabled, onClick: action.onClick },
                React.createElement(Row, { align: "middle" },
                    React.createElement(Icon, { color: color, name: action.icon }),
                    React.createElement(TextComponent, { children: action.title, color: color, marginLeft: 12 }))));
        })), trigger: ['click'], placement: "bottomRight", icon: React.createElement(Icon, { name: "more-horizontal", size: 24, color: COLORS.gray1 }) }));
};
var StyledButton = styled(Dropdown.Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 40px;\n\n  & > button {\n    display: flex;\n    align-items: center;\n    height: 20px;\n\n    &:first-child {\n      display: none;\n    }\n  }\n"], ["\n  width: 40px;\n\n  & > button {\n    display: flex;\n    align-items: center;\n    height: 20px;\n\n    &:first-child {\n      display: none;\n    }\n  }\n"])));
export var ProductFormDrawer = function (props) {
    var _a, _b, _c;
    if (!props.drawer) {
        return React.createElement("div", null);
    }
    var productGroup = (_a = props.groups) === null || _a === void 0 ? void 0 : _a.find(function (group) { var _a, _b; return group.id === ((_b = (_a = props === null || props === void 0 ? void 0 : props.drawer) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.productGroupId); });
    var form = Form.useForm()[0];
    var drawer = props.drawer;
    var data = drawer === null || drawer === void 0 ? void 0 : drawer.data;
    var demandType = (data === null || data === void 0 ? void 0 : data.demandType) || 'all';
    var _d = React.useState((productGroup === null || productGroup === void 0 ? void 0 : productGroup.type) || 'service'), type = _d[0], setType = _d[1];
    var isProduct = type === 'product';
    var purpose = demandType !== 'all' ? [demandType] : ['sale', 'inside'];
    var initialValues = (data === null || data === void 0 ? void 0 : data.time)
        ? __assign(__assign({}, data), { time: dayjs(data.timeYYYYMMDDHHmm), purpose: purpose }) : __assign(__assign({}, data), { time: dayjs(convertMinutesToYYYYMMDDHHmm(0)), purpose: purpose });
    var getDrawerTitle = function () {
        if (!drawer) {
            return;
        }
        var text = (data === null || data === void 0 ? void 0 : data.id) ? '수정' : '등록';
        switch (drawer.grade) {
            case 'group':
                return "1\uCC28 \uBD84\uB958 \uC0C1\uD488 ".concat(text);
            case 'product':
                return "2\uCC28 \uBD84\uB958 \uC0C1\uD488 ".concat(text);
        }
    };
    React.useEffect(function () {
        form.resetFields();
        if (productGroup) {
            setType(productGroup === null || productGroup === void 0 ? void 0 : productGroup.type);
        }
    }, [drawer]);
    var fields = drawer && drawer.grade === 'group'
        ? [
            {
                type: 'select',
                itemProps: {
                    name: 'type',
                    label: '카테고리',
                    rules: [{ type: 'string', required: true }]
                },
                inputProps: {
                    disabled: !!(data === null || data === void 0 ? void 0 : data.id),
                    placeholder: '카테고리를 선택해주세요.',
                    autoFocus: true,
                    options: [
                        { label: '시술', value: 'service' },
                        { label: '제품', value: 'product' }
                    ]
                }
            },
            {
                type: 'text',
                itemProps: {
                    name: 'name',
                    label: '1차 분류명',
                    rules: [{ type: 'string', required: true, min: 1, max: 20 }]
                },
                inputProps: { placeholder: '1차 분류명', autoFocus: true }
            }
        ]
        : [
            {
                type: 'select',
                itemProps: {
                    name: 'productGroupId',
                    label: '1차 분류명',
                    rules: [{ type: 'number', required: true }]
                },
                inputProps: {
                    placeholder: '1차 분류명',
                    onChange: function (value) {
                        var _a;
                        var currentProductGroup = (_a = props.groups) === null || _a === void 0 ? void 0 : _a.find(function (group) { return group.id === value; });
                        setType(currentProductGroup.type);
                    },
                    options: (_c = (_b = props.groups) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return !(productGroup === null || productGroup === void 0 ? void 0 : productGroup.type) || item.type === (productGroup === null || productGroup === void 0 ? void 0 : productGroup.type); })) === null || _c === void 0 ? void 0 : _c.map(function (group) { return ({
                        label: "".concat((group === null || group === void 0 ? void 0 : group.type) === 'service' ? '(시술)' : '(제품)', " ").concat(group.name),
                        value: group.id
                    }); })
                }
            },
            {
                type: 'text',
                itemProps: {
                    name: 'name',
                    label: '2차 분류명',
                    rules: [{ type: 'string', required: true, min: 1, max: 50 }]
                },
                inputProps: { placeholder: '2차 분류명 입력', autoFocus: true, autoComplete: 'off' }
            },
            {
                type: 'timepicker',
                itemProps: {
                    name: 'time',
                    label: '상품 시간',
                    rules: [{ required: true }]
                },
                // inputProps: { placeholder: '00:00', autoFocus: true, mask: '00:00' }
                inputProps: { placeholder: '00:00', format: 'HH:mm', minuteStep: 10, showNow: false, inputReadOnly: true }
            },
            !isProduct && {
                type: 'number',
                itemProps: {
                    name: 'price',
                    label: '상품 금액',
                    rules: [{ type: 'number', required: true, min: 0, max: 999999999 }]
                },
                inputProps: {
                    placeholder: '금액 입력',
                    formatter: function (value) { return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ','); },
                    parser: function (value) { return value.replace(/\$\s?|(,*)/g, ''); }
                }
            },
            isProduct && {
                type: 'number',
                itemProps: {
                    name: 'purchasePrice',
                    label: '입고가',
                    rules: [{ type: 'number', required: true, min: 0, max: 999999999 }]
                },
                inputProps: {
                    placeholder: '입고가 입력',
                    formatter: function (value) { return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ','); },
                    parser: function (value) { return value.replace(/\$\s?|(,*)/g, ''); }
                }
            },
            isProduct && {
                type: 'number',
                itemProps: {
                    name: 'price',
                    label: '소비자가(상품금액)',
                    rules: [{ type: 'number', required: true, min: 0, max: 999999999 }]
                },
                inputProps: {
                    placeholder: '소비자가(상품금액) 입력',
                    formatter: function (value) { return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ','); },
                    parser: function (value) { return value.replace(/\$\s?|(,*)/g, ''); }
                }
            },
            isProduct && {
                type: 'number',
                itemProps: {
                    name: 'safetyStock',
                    label: '안전재고',
                    extra: (React.createElement("div", null,
                        React.createElement(TextComponent, { children: "* \uB9E4\uC7A5\uC6B4\uC601\uC5D0 \uD544\uC694\uD55C \uCD5C\uC18C \uC218\uB7C9\uC785\uB2C8\uB2E4. ", color: COLORS.gray2, type: "caption1" }),
                        React.createElement(TextComponent, { children: "\uD604\uC7AC\uACE0\uB294 \u2018\uC7AC\uACE0\uAD00\uB9AC\u2019 - \u2018\uC785\uCD9C\uACE0\uB4F1\uB85D\u2019\uC744 \uC774\uC6A9\uD574\uC8FC\uC138\uC694.", color: COLORS.gray2, type: "caption1" }))),
                    rules: [{ type: 'number', required: true, min: 0, max: 999999999 }]
                },
                inputProps: {
                    placeholder: '안전재고 입력',
                    formatter: function (value) { return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ','); },
                    parser: function (value) { return value.replace(/\$\s?|(,*)/g, ''); }
                }
            },
            isProduct && {
                element: (React.createElement(StyledCheckboxWithAll, { label: "\uC6A9\uB3C4*", itemProps: {
                        name: 'purpose',
                        rules: [{ required: true, message: '용도를 선택해주세요.' }]
                    }, form: form, dataKey: "purpose", items: [
                        { value: 'sale', label: '판매' },
                        { value: 'inside', label: '내수' }
                    ] }))
            },
            isProduct && {
                type: 'textarea',
                itemProps: {
                    name: 'description',
                    label: '메모',
                    rules: [{ type: 'string' }]
                },
                inputProps: { placeholder: '메모를 입력해주세요.' }
            }
        ].filter(Boolean);
    return (React.createElement(DrawerComponent, { title: getDrawerTitle(), open: !!drawer, onClose: props.onClose, footer: React.createElement(DrawerFooter, { onCancel: props.onClose, onConfirm: form.submit, confirmText: (data === null || data === void 0 ? void 0 : data.id) ? '수정' : '등록', extra: (data === null || data === void 0 ? void 0 : data.productGroupId) &&
                (data === null || data === void 0 ? void 0 : data.id) &&
                props.onDelete && (React.createElement(Col, { flex: 1 },
                React.createElement(Row, { justify: "end" },
                    React.createElement(AntPrimaryTextButton, { icon: React.createElement(Icon, { name: "trash" }), children: "\uC0C1\uD488\uC0AD\uC81C", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, props.onDelete(data)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } })))) }) },
        React.createElement(AntdForm, { formProps: {
                layout: 'vertical',
                labelCol: {
                    span: 24
                },
                wrapperCol: {
                    span: 24
                },
                form: form,
                initialValues: initialValues,
                onFinish: function (values) {
                    if (drawer.grade === 'group') {
                        props.onSubmit(values);
                    }
                    else {
                        var purpose_1 = values.purpose, rest = __rest(values, ["purpose"]);
                        props.onSubmit(__assign(__assign({}, rest), { demandType: !isArray(purpose_1) ? null : purpose_1.length === 2 ? 'all' : purpose_1[0] }));
                    }
                }
            }, allowEnterSubmit: true, onSubmit: props.onSubmit, fields: fields })));
};
var StyledMenu = styled(Menu)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 4px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n"], ["\n  border-radius: 4px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n"])));
var StyledCardWithTabs = styled(CardWithTabs)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 0;\n  padding: 0px;\n\n  & .ant-card-head {\n    padding-right: 20px;\n    padding-left: 20px;\n  }\n  & .ant-card-body {\n    padding-left: 20px;\n    padding-top: 20px;\n  }\n"], ["\n  border: 0;\n  padding: 0px;\n\n  & .ant-card-head {\n    padding-right: 20px;\n    padding-left: 20px;\n  }\n  & .ant-card-body {\n    padding-left: 20px;\n    padding-top: 20px;\n  }\n"])));
var SettingTemplateWithTab = styled(function (props) { return React.createElement(SettingTemplate, __assign({}, props)); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  & > .ant-row {\n    &:first-child {\n      padding-left: 15px;\n      padding-right: 15px;\n      padding-top: 6px;\n      padding-bottom: 6px;\n      border-bottom: 1px solid ", ";\n    }\n  }\n"], ["\n  & > .ant-row {\n    &:first-child {\n      padding-left: 15px;\n      padding-right: 15px;\n      padding-top: 6px;\n      padding-bottom: 6px;\n      border-bottom: 1px solid ", ";\n    }\n  }\n"])), COLORS.gray6);
var StyledCheckboxWithAll = styled(CheckboxWithAll)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  & > .ant-form-item:first-child {\n    margin-bottom: -10px !important;\n\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n"], ["\n  & > .ant-form-item:first-child {\n    margin-bottom: -10px !important;\n\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n"])), css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        ", "\n      "], ["\n        ", "\n      "])), typographyByName('caption1')));
var getColumns = function (isService, isPC) {
    var serviceColumns = isPC
        ? [
            {
                title: '상품명',
                dataIndex: 'name',
                key: 'name',
                // className
                width: '50%',
                align: 'left',
                render: function (value, record) {
                    var hasTemplate = record.hasTemplate;
                    return {
                        children: !hasTemplate ? (React.createElement(TextComponent, { type: "caption1", marginRight: 5 }, value)) : (React.createElement(Row, null,
                            React.createElement(TextComponent, { type: "caption1", marginRight: 5 }, value),
                            record.hasTemplate && React.createElement(ColorTag, { children: "\uC815\uAE30\uAD8C", color: COLORS.info }))),
                        props: {
                            colSpan: 1
                        }
                    };
                }
            },
            {
                dataIndex: 'time',
                key: 'age',
                width: '25%',
                title: ' ',
                render: function (_, record) {
                    return {
                        children: record.timeString,
                        props: {
                            colSpan: 1
                        }
                    };
                }
            },
            {
                dataIndex: 'price',
                width: '25%',
                key: 'address',
                align: 'right',
                title: ' ',
                render: function (_, record) {
                    return {
                        children: record.priceString,
                        props: {
                            colSpan: 1
                        }
                    };
                }
            }
        ]
        : [
            {
                title: '상품명',
                dataIndex: 'name',
                key: 'name',
                // className
                width: '50%',
                align: 'left',
                render: function (value, record) {
                    return {
                        children: (React.createElement("div", null,
                            React.createElement(RowDiv, null,
                                React.createElement(TextComponent, { type: "caption1", marginRight: 5 }, value),
                                record.hasActiveTicket && React.createElement(ColorTag, { children: "\uC815\uAE30\uAD8C", color: COLORS.info })),
                            React.createElement(RowDiv, { "$justify": "space-between" },
                                React.createElement(TextComponent, { children: record.timeString, type: "caption1" }),
                                React.createElement(TextComponent, { children: record.priceString, type: "caption1" })))),
                        props: {
                            colSpan: 3
                        }
                    };
                }
            },
            {
                dataIndex: 'time',
                key: 'age',
                width: '25%',
                title: ' ',
                render: function () {
                    return {
                        children: React.createElement("div", null),
                        props: {
                            colSpan: 0
                        }
                    };
                }
            },
            {
                dataIndex: 'price',
                width: '25%',
                key: 'address',
                align: 'right',
                title: ' ',
                render: function () {
                    return {
                        children: React.createElement("div", null),
                        props: {
                            colSpan: 0
                        }
                    };
                }
            }
        ];
    return isService
        ? serviceColumns
        : [
            {
                dataIndex: 'groupName',
                key: 'groupName',
                title: '1차 분류명',
                render: function (_, record) {
                    return (React.createElement(RowDiv, null,
                        React.createElement(TextComponent, { type: "caption1", marginRight: 5 }, record.groupName),
                        record.hasTemplate && React.createElement(ColorTag, { children: "\uC815\uAE30\uAD8C", color: COLORS.info })));
                }
            },
            {
                dataIndex: 'name',
                key: 'name',
                title: '2차 분류명',
                render: function (value, record) {
                    return (React.createElement(Row, null,
                        React.createElement(TextComponent, { type: "caption1", marginRight: 5 }, value),
                        record.hasTemplate && React.createElement(ColorTag, { children: "\uC815\uAE30\uAD8C", color: COLORS.info })));
                }
            },
            {
                title: '상품 시간',
                dataIndex: 'time',
                key: 'time',
                render: function (_, record) {
                    return record.timeString;
                }
            },
            {
                title: '입고가',
                dataIndex: 'purchasePrice',
                key: 'purchasePrice',
                render: function (value) {
                    return KRWFomatToString({ value: value || 0 });
                }
            },
            {
                title: '소비자가(상품금액)',
                dataIndex: 'price',
                key: 'price',
                render: function (value) {
                    return KRWFomatToString({ value: value || 0 });
                }
            },
            {
                title: '안전재고',
                dataIndex: 'safetyStock',
                key: 'safetyStock',
                render: function (value) {
                    return "".concat(value, "\uAC1C");
                }
            },
            {
                title: '용도',
                dataIndex: 'demandType',
                key: 'demandType',
                render: function (value) {
                    if (value === 'all') {
                        return '판매O 내수O';
                    }
                    else if (value === 'inside') {
                        return '판매X 내수O';
                    }
                    else if (value === 'sale') {
                        return '판매O 내수X';
                    }
                }
            },
            {
                title: '메모',
                dataIndex: 'description',
                key: 'description',
                render: function (value) {
                    return value ? "".concat(value) : '';
                }
            }
        ];
};
var RowDiv = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n"])), function (_a) {
    var $justify = _a.$justify;
    return $justify || 'unset';
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
