var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Row, Col } from 'antd/lib/grid';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { getDocuments } from 'renderer/utils/firebase';
import styled from 'styled-components';
import { useWindowSize, useLocalStorage, encodeBase64, openWindow, BrowserDetect, isPlussharpMobileApp, EventTracker } from 'renderer/utils';
import * as dayjs from 'dayjs';
import { API_STAGE, DATE_FORMAT, HEADER_HEIGHT, LOCAL_STORAGE_KEY_HIDE_BANNER_VISIBLE, PATH_APP_MARKETING, PATH_APP_STORE_SUBSCRIPTIONS } from 'renderer/constants';
import { ManagerModel } from 'renderer/models';
import { ScrollBar, AntPrimaryGhostButton, TextComponent, UnderlineClickableText, Icon, BigTitleModal, AntButton } from 'renderer/components';
import { COLORS } from 'renderer/styles';
import { IMAGES } from 'renderer/assets/images/images';
import { getWithExpiry, setWithExpiry } from 'renderer/utils/localstorage';
import { useNavigate } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRecoilState } from 'recoil';
import { Atoms } from 'renderer/stores';
import { ManagerQuery, StorePaymentMethodQuery, StoreQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import { BlogQuestions } from 'renderer/components/Typeform';
import { checkBizNoAndUpdate } from 'renderer/containers/app/NewStore/Subscription';
import Form from 'antd/lib/form';
import useTimezoneOffset from 'renderer/utils/hooks/timezone';
import { where } from 'firebase/firestore';
export var NewAgreementPopup = function (props) {
    var _a, _b, _c;
    var latestAgreedAt = (_b = (_a = props.manager) === null || _a === void 0 ? void 0 : _a.terms) === null || _b === void 0 ? void 0 : _b.privacyTermsAgreedAt;
    var hide = (latestAgreedAt && dayjs(latestAgreedAt).isAfter(dayjs('2023-05-02'), 'day')) ||
        ((_c = props.manager) === null || _c === void 0 ? void 0 : _c.role) !== ManagerModel.MANAGER_ROLE.STORE_ADMIN;
    var _d = React.useState(false), checked = _d[0], setChecked = _d[1];
    var managerUpdate = ManagerQuery.useUpdateManager();
    var sotreUpdate = StoreQuery.useUpdateStore();
    return hide ? (React.createElement("div", null)) : (React.createElement(BigTitleModal, { centered: true, open: !hide, closable: false, width: 360, title: "\uC57D\uAD00 \uB3D9\uC758", footer: React.createElement(Button, { type: "primary", children: "\uD655\uC778", disabled: !checked, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, managerUpdate.mutateAsync({
                                id: props.manager.id,
                                storeId: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id,
                                terms: __assign(__assign({}, props.manager.terms), { privacyTermsAgreedAt: new Date() })
                            })];
                        case 1:
                            _c.sent();
                            return [4 /*yield*/, sotreUpdate.mutateAsync({
                                    id: (_b = props.store) === null || _b === void 0 ? void 0 : _b.id,
                                    terms: __assign(__assign({}, props.store.terms), { privacyTermsAgreedAt: new Date() })
                                })];
                        case 2:
                            _c.sent();
                            notification.success({ message: '약관동의가 완료되었습니다 🎉' });
                            return [2 /*return*/];
                    }
                });
            }); } }), children: React.createElement("div", { style: { padding: '20px', paddingTop: '0px' } },
            React.createElement(TextComponent, { children: "\uAC1C\uC778\uC815\uBCF4\uCC98\uB9AC\uBC29\uCE68 \uBCC0\uACBD\uC5D0 \uB530\uB978 \uC57D\uAD00 \uB3D9\uC758", type: "headline2", marginBottom: 10 }),
            React.createElement(TextComponent, { children: "2023\uB144 5\uC6D4 4\uC77C\uBD80\uD130 \uD305\uCEE4\uBDF0 \uC11C\uBE44\uC2A4\uC758 \uAC1C\uC778\uC815\uBCF4\uCC98\uB9AC\uBC29\uCE68\uC774 \uBCC0\uACBD\uB418\uC5B4 \uC57D\uAD00 \uB3D9\uC758\uAC00 \uD544\uC694\uD569\uB2C8\uB2E4.", type: "subtitle2", marginBottom: 20 }),
            React.createElement(AgreementWrapper, null,
                React.createElement(Row, { align: "middle" },
                    React.createElement(UnderlineClickableText, { marginBottom: 5, onClick: function () {
                            openWindow('https://help.thinkofyou.kr/805ef0ef-780e-472d-944e-d12a4290d1e8', '개인정보 이용방침');
                        }, children: React.createElement("span", null,
                            "\uAC1C\uC778\uC815\uBCF4\uCC98\uB9AC\uBC29\uCE68 \uC774\uC6A9\uC57D\uAD00 \uC804\uBB38\uBCF4\uAE30",
                            React.createElement("img", { src: IMAGES.icon_link_out, style: { color: COLORS.primary1, marginLeft: '5px' } })), type: "body1", color: COLORS.primary1, marginRight: 10 })),
                React.createElement(TextComponent, { children: "\uC774\uC6A9\uC790\uB294 \uC704\uC758 \uAC1C\uC778\uC815\uBCF4\uCC98\uB9AC\uBC29\uCE68 \uBCC0\uACBD\uC5D0 \uB300\uD55C \uB3D9\uC758\uB97C \uAC70\uBD80\uD560 \uAD8C\uB9AC\uAC00 \uC788\uC2B5\uB2C8\uB2E4. \uADF8\uB7EC\uB098 \uB3D9\uC758\uB97C \uAC70\uBD80\uD560 \uACBD\uC6B0, \uD305\uCEE4\uBDF0 \uC11C\uBE44\uC2A4 \uC774\uC6A9\uC5D0 \uC81C\uD55C\uC744 \uBC1B\uC744 \uC218 \uC788\uC2B5\uB2C8\uB2E4.", color: COLORS.gray3, type: "caption1" })),
            React.createElement(Row, { align: "middle", style: { marginTop: '10px', width: '100%' } },
                React.createElement(StyledCheckbox, { checked: checked, onChange: function (e) {
                        setChecked(e.target.checked);
                    } },
                    React.createElement(TextComponent, { children: "\uBCC0\uACBD\uB41C \uC57D\uAD00 \uB0B4\uC6A9\uC744 \uC774\uD574\uD588\uC73C\uBA70 \uB3D9\uC758\uD569\uB2C8\uB2E4.", marginBottom: 70 })))) }));
};
var AgreementWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 320px;\n  padding: 14px;\n  border-radius: 2px;\n  border: solid 1px ", ";\n  background-color: ", ";\n"], ["\n  width: 320px;\n  padding: 14px;\n  border-radius: 2px;\n  border: solid 1px ", ";\n  background-color: ", ";\n"])), COLORS.gray6, COLORS.white);
export var NoticePopupContainer = function (props) {
    var docs = getDocuments("popups-".concat(API_STAGE), [where('isActive', '==', true)]);
    var isPC = useWindowSize().isPC;
    React.useEffect(function () {
        docs
            .then(function (data) {
            var items = data.docs
                .map(function (doc) {
                if (doc.exists) {
                    return __assign(__assign({}, doc.data()), { id: doc.id });
                }
            })
                .filter(Boolean);
            setPopups(items);
        })
            .catch(function (error) { });
        return function () { return setPopups([]); };
    }, []);
    var _a = React.useState([]), popups = _a[0], setPopups = _a[1];
    var todayString = dayjs().format('YYYYMMDD');
    return (React.createElement(React.Fragment, null, popups.map(function (popup) {
        return React.createElement(NoticePopup, { notice: popup, key: popup.id, isPC: isPC, todayString: todayString });
    })));
};
export var NoticePopup = function (props) {
    var _a, _b, _c, _d, _e;
    var notice = props.notice;
    var imageURL = props.isPC ? (_a = props.notice.content) === null || _a === void 0 ? void 0 : _a.pcImageURL : (_b = props.notice.content) === null || _b === void 0 ? void 0 : _b.mobileImageURL;
    var width = props.isPC ? 596 : 340;
    var _f = useLocalStorage("LOCAL_STORAGE_KEY_POPUPS_ID_".concat(notice.id, "_").concat(props.todayString), false), isHiddenInADay = _f[0], setIsHiddenInADay = _f[1];
    var _g = React.useState(true), visible = _g[0], setVisible = _g[1];
    var isShown = function () {
        return !isHiddenInADay && visible && (props.isPC ? notice.isShownPC : notice.isShownMobile);
    };
    var footerJustify = ((_c = props.notice) === null || _c === void 0 ? void 0 : _c.availableTodayHide) ? 'space-between' : 'end';
    return isShown() ? (React.createElement(StyledModal, { centered: true, open: true, width: width, closable: true, onCancel: function () {
            setVisible(false);
        }, footer: null, children: React.createElement(NoticePopupWrapper, { style: { backgroundColor: COLORS.white, width: '100%' } },
            React.createElement("img", { src: imageURL, style: { width: "".concat(width, "px") } }),
            React.createElement(Row, { style: { backgroundColor: COLORS.white, padding: '12px 24px', width: '100%' }, justify: footerJustify },
                React.createElement(Button, { children: "\uC624\uB298 \uD558\uB8E8 \uADF8\uB9CC\uBCF4\uAE30", onClick: function (e) {
                        setIsHiddenInADay(true);
                        setVisible(false);
                    } }),
                React.createElement(Row, null, (_e = (_d = props.notice) === null || _d === void 0 ? void 0 : _d.action) === null || _e === void 0 ? void 0 : _e.map(function (action) {
                    return (React.createElement(Button, { children: action.title, type: "primary", onClick: function () { return openWindow(action.pcUrl, action.title); } }));
                })))) })) : (React.createElement("div", null));
};
export var WillExpireSubscriptionPopup = function (props) {
    var _a;
    var WILL_SHOW_DAY = 10;
    var subscription = props.store.subscription;
    var shouldVisible = ((_a = props.store) === null || _a === void 0 ? void 0 : _a.hasSubscription) && (subscription === null || subscription === void 0 ? void 0 : subscription.status) === 'TRIAL' && (subscription === null || subscription === void 0 ? void 0 : subscription.remainDay) < WILL_SHOW_DAY;
    var WILL_SHOW_POPUP_KEY = 'WILL_SHOW_POPUP_KEY';
    var hideInLocal = getWithExpiry(WILL_SHOW_POPUP_KEY) === true;
    var _b = React.useState(!hideInLocal), visible = _b[0], setVisible = _b[1];
    var _c = useWindowSize(), isPC = _c.isPC, width = _c.width;
    var images = [IMAGES.plan_inst1, IMAGES.plan_inst2, IMAGES.plan_inst3];
    var modalWidth = width * 0.8;
    var navigate = useNavigate();
    var handleClose = function () {
        setWithExpiry(WILL_SHOW_POPUP_KEY, true, 1000 * 60 * 60 * 12);
        setVisible(false);
    };
    return shouldVisible ? (React.createElement(WillExpireSubscriptionPopupModal, { centered: true, width: modalWidth > 1024 ? 1024 : modalWidth, visible: visible, closable: false, footer: React.createElement(Row, { gutter: 8, justify: "center" },
            React.createElement(AntPrimaryGhostButton, { size: "large", children: "\uB2EB\uAE30", onClick: handleClose }),
            React.createElement(Button, { type: "primary", size: "large", children: "\uC5F0\uC7A5\uD558\uAE30", onClick: function () {
                    handleClose();
                    navigate(PATH_APP_STORE_SUBSCRIPTIONS);
                } })), children: React.createElement(Wrapper, null,
            React.createElement(TextComponent, { type: "headline2", children: "30\uC77C \uBB34\uB8CC\uCCB4\uD5D8 \uAE30\uAC04 \uB9CC\uB8CC \uC784\uBC15", marginBottom: 8 }),
            React.createElement(Row, null,
                React.createElement(TextComponent, { children: "\uACE0\uAC1D\uB2D8\uC758 30\uC77C \uBB34\uB8CC\uCCB4\uD5D8 \uC774\uC6A9\uAE30\uAC04\uC774", textAlign: "center" })),
            React.createElement(Row, null,
                React.createElement(TextComponent, { children: "".concat(subscription === null || subscription === void 0 ? void 0 : subscription.remainDay, "\uC77C \uD6C4 \uB9CC\uB8CC"), color: COLORS.primary1, textAlign: "center" }),
                React.createElement(TextComponent, { children: "\uC608\uC815\uC785\uB2C8\uB2E4" })),
            React.createElement(Row, null,
                React.createElement(TextComponent, { children: "\uC720\uB8CC\uD50C\uB79C\uC744 \uACC4\uC18D \uC774\uC6A9\uD558\uACE0 \uC2F6\uC73C\uC2DC\uB2E4\uBA74", textAlign: "center" })),
            React.createElement(Row, null,
                React.createElement(TextComponent, { children: "\uB9CC\uB8CC\uC77C(".concat(dayjs(subscription.expiredAt).format(DATE_FORMAT), ")"), color: COLORS.primary1, textAlign: "center" }),
                React.createElement(TextComponent, { children: "\uC804\uAE4C\uC9C0", textAlign: "center" })),
            React.createElement(TextComponent, { children: "\uC720\uB8CC\uD50C\uB79C\uC744 \uC5F0\uC7A5\uD574\uC8FC\uC138\uC694!", marginBottom: 20, textAlign: "center" }),
            React.createElement(Row, { gutter: 8 }, images.map(function (image, index) {
                return (React.createElement(Col, { key: index, span: isPC ? 8 : 24 },
                    React.createElement(Image, { src: image })));
            }))) })) : (React.createElement("div", null));
};
export var StickBanner = function (props) {
    var _a;
    var _b = React.useState(false), visible = _b[0], setVisible = _b[1];
    var _c = React.useState(false), isCopied = _c[0], setIsCopied = _c[1];
    var url = "".concat((_a = window.location) === null || _a === void 0 ? void 0 : _a.origin, "?redirect=").concat(encodeURIComponent("/signup"), "&code=").concat(encodeURIComponent(encodeBase64(props.store.id)));
    var _d = useRecoilState(Atoms.hideStickBannerState), hideBanner = _d[0], updateHideBannerState = _d[1];
    return (!hideBanner && (React.createElement(StickBannerWrapper, null,
        React.createElement(Row, { justify: "space-between", style: { marginLeft: '10px', marginRight: '10px', width: '100%' } },
            React.createElement(UnderlineClickableText, { style: { flex: 1 }, textAlign: "center", color: COLORS.primary1, children: "[\uCD94\uCC9C \uC774\uBCA4\uD2B8] \uCE5C\uAD6C \uCD08\uB300\uD558\uACE0 50\uC77C \uD61C\uD0DD\uBC1B\uC73C\uC138\uC694! >", onClick: function () {
                    setVisible(true);
                    setIsCopied(false);
                } }),
            React.createElement(Icon, { name: "x", size: 24, onClick: function () {
                    // TODO: effects_UNSTABLE 활용?
                    setWithExpiry(LOCAL_STORAGE_KEY_HIDE_BANNER_VISIBLE, 'true', 1000 * 60 * 60 * 24);
                    updateHideBannerState(true);
                } })),
        React.createElement(RecommendedEventModal, { visible: visible, footer: null, centered: true, onCancel: function () { return setVisible(false); }, closable: false, children: React.createElement(RecommendedEventModalBody, null,
                React.createElement(RecommendedEventModalClose, { children: React.createElement(Icon, { name: "x", color: COLORS.white, size: 24 }), onClick: function () { return setVisible(false); } }),
                React.createElement("img", { src: IMAGES.recommend_friend }),
                React.createElement("div", { className: "content" },
                    React.createElement(TextComponent, { children: "\uD305\uCEE4\uBDF0 \uCD94\uCC9C\uC774\uBCA4\uD2B8", type: "headline1", textAlign: "center", marginBottom: 10 }),
                    React.createElement("p", null,
                        "\uCE5C\uAD6C\uC5D0\uAC8C \uD305\uCEE4\uBDF0\uC744 \uCD94\uCC9C\uD574\uC8FC\uC138\uC694! \uC544\uB798 \uCD94\uCC9C\uC778\uB9C1\uD06C\uB97C \uD1B5\uD574 \uC2E0\uADDC\uD68C\uC6D0\uAC00\uC785\uC744 \uC644\uB8CC\uD558\uBA74",
                        ' ',
                        React.createElement("span", { style: { color: COLORS.primary1 } }, "\uCD94\uCC9C\uC778\uACFC \uC2E0\uADDC\uD68C\uC6D0 \uBAA8\uB450 \uC720\uB8CC\uD50C\uB79C \uC774\uC6A9\uAE30\uAC04 50\uC77C\uC744 \uCD94\uAC00 \uC9C0\uAE09\uD574\uB4DC\uB9BD\uB2C8\uB2E4."),
                        "\uCD08\uB300\uD558\uB294 \uCE5C\uAD6C\uAC00 \uB9CE\uC544\uC9C8\uC218\uB85D \uC774\uC6A9\uAE30\uAC04\uC774 \uB354 \uAE38\uC5B4\uC9C4\uB2E4\uB294 \uC0AC\uC2E4! \uADF8\uB7FC \uC9C0\uAE08 \uCE5C\uAD6C\uC5D0\uAC8C \uCD94\uCC9C\uD574\uBCFC\uAE4C\uC694?\uD83D\uDE09"),
                    React.createElement(TextComponent, { children: "\uC774\uBCA4\uD2B8 \uAE30\uAC04 : 2021.11.30 (\uD654) ~ \uBCC4\uB3C4 \uACF5\uC9C0\uC77C\uAE4C\uC9C0", type: "caption1" }),
                    React.createElement(CopyToClipboard, { text: url, onCopy: function () {
                            setIsCopied(true);
                            setTimeout(function () {
                                setIsCopied(false);
                            }, 2000);
                        } },
                        React.createElement(Button, { children: isCopied ? '링크복사 완료 👌' : '추천인링크 복사하기', type: "primary", size: "large" })),
                    React.createElement(TextComponent, { color: COLORS.gray3, children: "\u2022 \uBCF8 \uC774\uBCA4\uD2B8\uB294 30\uC77C \uBB34\uB8CC\uCCB4\uD5D8 & \uC720\uB8CC\uD50C\uB79C \uACB0\uC81C \uACE0\uAC1D\uC744 \uB300\uC0C1\uC73C\uB85C \uC9C4\uD589\uB429\uB2C8\uB2E4.", type: "caption1" }),
                    React.createElement(TextComponent, { color: COLORS.gray3, children: "\u2022 \uCD94\uCC9C \uCC98\uC218\uB294 \uC81C\uD55C\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.", type: "caption1" }),
                    React.createElement(TextComponent, { color: COLORS.gray3, children: "\u2022 \uCD94\uCC9C\uC778\uB9C1\uD06C\uB97C \uD1B5\uD574 \uAC00\uC785\uD55C \uACE0\uAC1D\uC5D0\uAC8C\uB9CC \uD61C\uD0DD\uC744 \uC9C0\uAE09\uD569\uB2C8\uB2E4.", type: "caption1" }),
                    React.createElement(TextComponent, { color: COLORS.gray3, children: "\u2022 \uCD94\uCC9C\uC744 \uBC1B\uC740 \uACE0\uAC1D\uC774 \uD68C\uC6D0\uAC00\uC785 \uD6C4 30\uC77C \uBB34\uB8CC\uCCB4\uD5D8\uC744 \uC2DC\uC791\uD558\uBA74 \uC591\uCABD\uC5D0 \uD61C\uD0DD\uC774 \uC790\uB3D9\uC9C0\uAE09\uB429\uB2C8\uB2E4.", type: "caption1" }),
                    React.createElement(TextComponent, { color: COLORS.gray3, children: "\u2022 \uD61C\uD0DD\uC744 \uC704\uD574 \uBD80\uC815\uCC38\uC5EC \uC2DC \uC81C\uACF5\uB41C \uD61C\uD0DD\uC774 \uCDE8\uC18C\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4.", type: "caption1" }))) }))));
};
export var BlogPromotionPopup = function (props) {
    var shouldShowInLocal = getWithExpiry('SHOULD_SHOW_BLOG_POPUP');
    var isPC = useWindowSize().isPC;
    var _a = React.useState(!shouldShowInLocal || shouldShowInLocal !== 'false'), visible = _a[0], setVisible = _a[1];
    var handleClose = function () {
        setVisible(false);
        setWithExpiry('SHOULD_SHOW_BLOG_POPUP', 'false', 1000 * 60 * 60 * 24);
    };
    var store = props.store, manager = props.manager;
    var _b = React.useState(false), typeformVisible = _b[0], setTypeformVisible = _b[1];
    var navigate = useNavigate();
    return (React.createElement(React.Fragment, null,
        React.createElement(RecommendedEventModal, { open: visible, footer: null, centered: true, onCancel: handleClose, closable: false, maskClosable: false, children: React.createElement(RecommendedEventModalBody, null,
                React.createElement(RecommendedEventModalClose, { children: React.createElement(Icon, { name: "x", color: COLORS.black, size: 24 }), onClick: handleClose }),
                React.createElement(ScrollBar, { style: { maxHeight: '80vh' }, options: { scrollbars: { autoHide: 'never' }, overflowBehavior: { x: 'hidden' } } },
                    React.createElement("img", { src: IMAGES.blog_marketing_8, style: { width: '100%' } }),
                    React.createElement("div", { className: "content" },
                        React.createElement(TextComponent, { children: "\uBE14\uB85C\uADF8 \uCCB4\uD5D8\uC5C5\uCCB4\uC640 \uC7A5\uAE30\uACC4\uC57D\uC744 \uB9FA\uC5B4\uC57C \uD574\uC11C", type: "headline3" }),
                        React.createElement(TextComponent, { children: "\uD55C \uBC88\uC5D0 \uBAA9\uB3C8\uC774 \uB098\uAC00\uC168\uC8E0?", type: "headline3", marginBottom: 10 }),
                        React.createElement(TextComponent, { children: "\uC6D4 \uB2E8\uC704(\uCD5C\uC18C 1\uBA85, \uD3EC\uC2A4\uD305 \uAC74\uB2F9 33,000\uC6D0) \uACC4\uC57D\uC73C\uB85C \uBE44\uC6A9 \uBD80\uB2F4 \uC5C6\uC774" }),
                        React.createElement(TextComponent, { children: "\uBE14\uB85C\uADF8 \uCCB4\uD5D8\uB2E8\uC744 \uC6B4\uC601\uD558\uC138\uC694!", marginBottom: 20 }),
                        React.createElement(TextComponent, { children: "\uBE44\uC2FC \uB3C8\uB9CC \uBC1B\uC544 \uAC00\uACE0 \uC57D\uC18D\uB41C \uC778\uC6D0\uB9CC\uD07C", type: "headline3" }),
                        React.createElement(TextComponent, { children: "\uCCB4\uD5D8\uB2E8\uC744 \uBC1B\uC544\uBCF4\uC2DC\uC9C0 \uBABB\uD558\uC168\uC8E0?", type: "headline3", marginBottom: 10 }),
                        React.createElement(TextComponent, { children: "\uC5C5\uACC4 \uCD5C\uCD08 \uD6C4\uBD88\uC81C \uC2DC\uC2A4\uD15C \uB3C4\uC785!" }),
                        React.createElement(TextComponent, { children: "\uD3EC\uC2A4\uD305\uC774 \uB418\uC9C0 \uC54A\uC73C\uBA74 \uC815\uC0B0\uBC1B\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4!", marginBottom: 20 }))),
                React.createElement("div", { className: "content" },
                    React.createElement(AntPrimaryGhostButton, { children: "\uC11C\uBE44\uC2A4 \uB0B4\uC6A9 \uB354 \uC790\uC138\uD788 \uBCF4\uAE30", onClick: function () {
                            navigate(PATH_APP_MARKETING);
                            handleClose();
                        } }),
                    React.createElement(ApplyBetaButton, { children: "\uC11C\uBE44\uC2A4 \uC2E0\uCCAD\uD558\uAE30", color: "primary", type: "primary", onClick: function () {
                            if (isPC) {
                                setTypeformVisible(true);
                            }
                            else {
                                openWindow("https://wxjt0yi7he5.typeform.com/to/eoIgKl7J?store_id=".concat(store.id, "&manager_id=").concat(manager.id, "&store_name=").concat(store.name, "&phone=").concat(store.phone, "&manager_name=").concat(manager.displayName), '블로그체험단 신청');
                            }
                            handleClose();
                        } }))) }),
        props.store && props.manager && typeformVisible && (React.createElement(BlogQuestions, { store: props.store, manager: props.manager, visible: typeformVisible, onClose: function () { return setTypeformVisible(false); } }))));
};
export var TimezoneAlert = function (props) {
    var shouldShowInLocal = getWithExpiry('SHOULD_SHOW_TIMEZONE_ALERT');
    var visibleFromLocal = !shouldShowInLocal || shouldShowInLocal !== 'false';
    var _a = React.useState(visibleFromLocal), visible = _a[0], setVisible = _a[1];
    var handleClose = function () {
        var expiry = 1000;
        if (checked) {
            expiry = 1000 * 60 * 60 * 24 * 7;
        }
        setWithExpiry('SHOULD_SHOW_TIMEZONE_ALERT', 'false', expiry);
        setVisible(false);
    };
    var _b = React.useState(false), checked = _b[0], setChecked = _b[1];
    var _c = useTimezoneOffset(), diffFromKorean = _c.diffFromKorean, timezone = _c.timezone;
    return (React.createElement(BigTitleModal, { title: "\uD574\uC678\uC2DC\uAC04 \uBCC0\uACBD \uC548\uB0B4", open: visible && diffFromKorean && diffFromKorean !== 0, footer: null, centered: true, onCancel: handleClose, zIndex: 9999, children: React.createElement("div", { style: { marginTop: '0px', margin: '24px', paddingBottom: '24px' } },
            React.createElement(TextComponent, { children: "\uD604\uC7AC \uC2DC\uAC04\uB300 \uAE30\uC900\uC740 \u2018".concat(timezone, "\u2019\uC785\uB2C8\uB2E4."), type: "subtitle1", marginBottom: 8 }),
            React.createElement(TextComponent, { children: "\uCCB4\uB958 \uAD6D\uAC00\uC758 \uD604\uC9C0 \uC2DC\uAC04\uC744 \uC790\uB3D9 \uD45C\uC2DC\uD558\uAE30 \uB54C\uBB38\uC5D0 \uD604\uC7AC \uD305\uCEE4\uBDF0 \uB2EC\uB825\uACFC \uC608\uC57D\uC5D0 \uD45C\uC2DC\uB418\uB294 \uC2DC\uAC04\uC774 \uD55C\uAD6D\uACFC \uB2E4\uB97C \uC218 \uC788\uC2B5\uB2C8\uB2E4. ", type: "body2", color: COLORS.gray2, marginBottom: 8 }),
            React.createElement(TextComponent, { children: "\u2705 \uC608\uC57D\uC2DC\uAC04\uC774 \uBCC0\uACBD\uB41C\uAC83\uC740 \uC544\uB2C8\uB2C8 \uAC71\uC815\uD558\uC9C0\uB9C8\uC138\uC694!\n            \uD734\uB300\uD3F0\uC758 [\uB0A0\uC9DC \uBC0F \uC2DC\uAC04] \uBA54\uB274\uB97C \uB300\uD55C\uBBFC\uAD6D\uC73C\uB85C \uC124\uC815\uD558\uACE0 \uD305\uCEE4\uBDF0\uB97C \uC774\uC6A9\uD574 \uC8FC\uC2DC\uBA74 \uC2DC\uAC04 \uCC28\uC774 \uC5C6\uC774 \uD3B8\uB9AC\uD558\uAC8C \uC0AC\uC6A9 \uAC00\uB2A5\uD569\uB2C8\uB2E4.", type: "body2", marginBottom: 16, color: COLORS.gray1 }),
            React.createElement(TextComponent, { children: "1)\uC0BC\uC131\uD3F0(\uC548\uB4DC\uB85C\uC774\uB4DC)\uB97C \uC0AC\uC6A9 \uC911\uC778 \uACBD\uC6B0", type: "caption1", color: COLORS.gray2 }),
            React.createElement(TextComponent, { marginBottom: 8, color: COLORS.gray1, children: "[\uC124\uC815]-[\uC77C\uBC18]-[\uB0A0\uC9DC \uBC0F \uC2DC\uAC04] \uBA54\uB274\uC5D0\uC11C [\uC2DC\uAC04\uB300 \uC790\uB3D9 \uC124\uC815] \uC635\uC158 OFF\uD558\uACE0 [\uC2DC\uAC04\uB300 \uC120\uD0DD] \uC635\uC158\uC744 \uB300\uD55C\uBBFC\uAD6D\uC73C\uB85C \uBCC0\uACBD", type: "caption1" }),
            React.createElement(TextComponent, { children: "12)\uC544\uC774\uD3F0\uC744 \uC0AC\uC6A9 \uC911\uC778 \uACBD\uC6B0", type: "caption1", color: COLORS.gray2 }),
            React.createElement(TextComponent, { marginBottom: 20, color: COLORS.gray1, children: "[\uC124\uC815]-[\uC77C\uBC18]-[\uB0A0\uC9DC \uBC0F \uC2DC\uAC04] \uBA54\uB274\uC5D0\uC11C [\uC790\uB3D9\uC73C\uB85C \uC124\uC815] \uC635\uC158 OFF\uD558\uACE0 [\uC2DC\uAC04\uB300] \uC635\uC158\uC744 \uB300\uD55C\uBBFC\uAD6D\uC73C\uB85C \uBCC0\uACBD", type: "caption1" }),
            React.createElement(Checkbox, { checked: checked, onChange: function (e) { return setChecked(e.target.checked); } }, "\uC77C\uC8FC\uC77C\uAC04 \uBCF4\uC9C0\uC54A\uAE30"),
            React.createElement(Row, { justify: 'end' },
                React.createElement(AntButton, { type: "link", onClick: function () {
                        openWindow('https://www.notion.so/ee1e092450fb4d81a55966b702199fbc', '대한민국 시간 설정 가이드');
                    } }, "\uB300\uD55C\uBBFC\uAD6D \uC2DC\uAC04 \uC124\uC815 \uAC00\uC774\uB4DC \uBCF4\uAE30 >"))) }));
};
export var RemoteClassPopup = function (props) {
    var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
    var mutation = StoreQuery.useUpdateStore();
    React.useEffect(function () {
        var _a, _b, _c, _d;
        var shouldShowWelcomeQuestionInLocal = getWithExpiry('SHOULD_SHOW_WELCOME_QUESTION') !== 'false' &&
            !(BrowserDetect.isMobile() && !isPlussharpMobileApp());
        var visible = !!((_a = props.store) === null || _a === void 0 ? void 0 : _a.subscription) &&
            !!((_c = (_b = props.store) === null || _b === void 0 ? void 0 : _b.preference) === null || _c === void 0 ? void 0 : _c.shouldShowWelcomeQuestion) &&
            shouldShowWelcomeQuestionInLocal &&
            dayjs().diff(dayjs((_d = props.store) === null || _d === void 0 ? void 0 : _d.createdAt), 'day') < 10;
        setVisible(visible);
    }, [props.store]);
    var handleClose = function () {
        setVisible(false);
        setWithExpiry('SHOULD_SHOW_WELCOME_QUESTION', 'false', 1000 * 60 * 60 * 24);
    };
    return visible ? (React.createElement(RemoteEventModal, { open: visible, footer: null, centered: true, onCancel: handleClose, closable: false, children: React.createElement(RecommendedEventModalBody, null,
            React.createElement(RecommendedEventModalClose, { children: React.createElement(Icon, { name: "x", color: COLORS.black, size: 24 }), onClick: handleClose }),
            React.createElement(ScrollBar, { style: { maxHeight: '80vh' }, options: { scrollbars: { autoHide: 'never' }, overflowBehavior: { x: 'hidden' } } },
                React.createElement("img", { src: IMAGES.remote_class_image, style: { width: '100%' } })),
            React.createElement("div", { className: "content" },
                React.createElement(ApplyBetaButton, { children: "\uC628\uB77C\uC778\uAD50\uC721 \uC608\uC57D\uD558\uAE30", color: "primary", type: "primary", style: { marginBottom: '0px' }, onClick: function () {
                        var _a, _b;
                        openWindow("https://whattime.co.kr/thinkofyou/remote", '온라인교육 예약하기');
                        mutation.mutate({
                            id: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id,
                            preference: __assign(__assign({}, (_b = props === null || props === void 0 ? void 0 : props.store) === null || _b === void 0 ? void 0 : _b.preference), { shouldShowWelcomeQuestion: false })
                        });
                        // handleClose();
                    } }))) })) : (React.createElement("div", null));
};
export var ApplyTrialPopup = function (props) {
    var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
    var storeQuery = StoreQuery.useStore();
    React.useEffect(function () {
        var _a, _b;
        var shouldShowWelcomeQuestionInLocal = getWithExpiry('SHOULD_SHOW_FREE_TRIAL') !== 'false' && !BrowserDetect.isMobile();
        var visible = !((_a = props.store) === null || _a === void 0 ? void 0 : _a.subscription) &&
            shouldShowWelcomeQuestionInLocal &&
            dayjs().diff(dayjs((_b = props.store) === null || _b === void 0 ? void 0 : _b.createdAt), 'day') < 10;
        setVisible(visible);
    }, [props.store]);
    var handleClose = function () {
        setVisible(false);
        setWithExpiry('SHOULD_SHOW_FREE_TRIAL', 'false', 1000 * 60 * 60 * 24);
    };
    var antForm = Form.useForm()[0];
    var updateStore = StoreQuery.useUpdateStore();
    var createSubscription = StorePaymentMethodQuery.useCreateSubscription();
    return visible ? (React.createElement(RemoteEventModal, { open: visible, footer: null, zIndex: 9999, centered: true, onCancel: handleClose, closable: false, children: React.createElement(RecommendedEventModalBody, null,
            React.createElement(RecommendedEventModalClose, { children: React.createElement(Icon, { name: "x", color: COLORS.white, size: 24 }), onClick: handleClose }),
            React.createElement(ScrollBar, { style: { maxHeight: '80vh' }, options: { scrollbars: { autoHide: 'never' }, overflowBehavior: { x: 'hidden' } } },
                React.createElement("img", { src: IMAGES.free_trial_banner_image, style: { width: '100%' } }),
                React.createElement("div", { style: { margin: '14px 20px' } },
                    React.createElement(TextComponent, { children: "\u2705   \uC608\uC57D, \uACE0\uAC1D \uAD00\uB9AC", type: "body2" }),
                    React.createElement(TextComponent, { children: "\u2705   \uB124\uC774\uBC84\uC608\uC57D \uC5F0\uB3D9", type: "subtitle2" }),
                    React.createElement(TextComponent, { children: "\u2705   \uD68C\uC6D0\uAD8C, \uD3EC\uC778\uD2B8 \uAD00\uB9AC", type: "body2" }),
                    React.createElement(TextComponent, { children: "\u2705   \uB9E4\uCD9C, \uACB0\uC0B0 \uBD84\uC11D", type: "body2" }),
                    React.createElement(Row, { align: "middle" },
                        React.createElement(TextComponent, { children: "\u2705   \uBB38\uC790," }),
                        React.createElement(TextComponent, { children: " \uCE74\uCE74\uC624\uD1A1 \uBC1C\uC1A1", type: "subtitle2" })),
                    React.createElement(TextComponent, { children: "\u2705   \uBB38\uC790 \uC790\uB3D9\uBC1C\uC1A1", type: "subtitle2" }),
                    React.createElement(Row, { align: "middle" },
                        React.createElement(TextComponent, { children: "\u2705   PC," }),
                        React.createElement(TextComponent, { children: " \uBAA8\uBC14\uC77C\uC571, \uD0DC\uBE14\uB9BF \uC9C0\uC6D0", type: "subtitle2" })),
                    React.createElement(TextComponent, { children: "\u2705   \uBC1C\uC2E0\uACE0\uAC1D \uC815\uBCF4\uD45C\uC2DC", type: "subtitle2" }),
                    React.createElement(TextComponent, { children: "\u2705   1\uC778\uC0F5~\uB2E4\uC778\uC0F5 \uC0AC\uC6A9 (\uC9C1\uC6D0 \uC218 \uC81C\uD55C\uC5C6\uC74C)", type: "subtitle2" }),
                    React.createElement(TextComponent, { children: "\uD83D\uDE80   \uD305\uCEE4\uBDF0\uB9C1\uD06C (\uBB34\uB8CC \uBD80\uAC00\uC11C\uBE44\uC2A4)", type: "subtitle2" }),
                    React.createElement(TextComponent, { children: "\uBB34\uB8CC\uCCB4\uD5D8 \uAE30\uAC04\uC774 \uC885\uB8CC\uB418\uC5B4\uB3C4 \uC694\uAE08\uC774 \uACB0\uC81C\uB418\uC9C0 \uC54A\uC73C\uB2C8 \uC548\uC2EC\uD558\uACE0 \uC774\uC6A9\uD558\uC138\uC694!", type: "caption1", color: COLORS.info, marginTop: 10 }))),
            React.createElement("div", { style: { padding: '0px 16px', paddingBottom: '20px' } },
                React.createElement(ApplyBetaButton, { children: React.createElement(Row, { align: "middle", justify: "center" },
                        React.createElement(TextComponent, { children: "30\uC77C \uBB34\uB8CC", type: "subtitle2", color: COLORS.white }),
                        React.createElement(TextComponent, { children: "\uB85C \uC720\uB8CC\uD50C\uB79C \uCCB4\uD5D8\uD558\uAE30", type: "body2", color: COLORS.white })), color: "primary", type: "primary", style: { marginBottom: '0px' }, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var checkComplete, duration, payload, err_1;
                        var _a, _b, _c;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0: return [4 /*yield*/, checkBizNoAndUpdate(props.store, antForm, updateStore)];
                                case 1:
                                    checkComplete = _d.sent();
                                    if (!checkComplete) {
                                        return [2 /*return*/];
                                    }
                                    duration = {
                                        startedAt: dayjs().startOf('day').toDate(),
                                        expiredAt: dayjs().add(30, 'day').endOf('day').toDate()
                                    };
                                    payload = {
                                        storeId: props.store.id,
                                        add: [
                                            __assign(__assign({ storeId: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id, name: '유료 플랜 구독 (무료체험)', status: 'TRIAL', ourProductId: 2 }, duration), { data: {
                                                    productOptionId: -1,
                                                    price: 0,
                                                    vat: 0
                                                } }),
                                            __assign(__assign({ storeId: (_b = props.store) === null || _b === void 0 ? void 0 : _b.id, name: '팅커뷰링크 구독 (무료체험)', status: 'TRIAL', ourProductId: 4 }, duration), { data: {
                                                    productOptionId: -1,
                                                    price: 0,
                                                    vat: 0
                                                } })
                                        ],
                                        remove: [],
                                        same: [],
                                        invoice: {
                                            status: 'opened',
                                            meta: {
                                                storeId: (_c = props.store) === null || _c === void 0 ? void 0 : _c.id
                                            },
                                            description: '유료 플랜 결제',
                                            totalPrice: 0,
                                            totalVat: 0
                                        }
                                    };
                                    _d.label = 2;
                                case 2:
                                    _d.trys.push([2, 5, , 6]);
                                    return [4 /*yield*/, createSubscription.mutateAsync(payload)];
                                case 3:
                                    _d.sent();
                                    return [4 /*yield*/, storeQuery.refetch()];
                                case 4:
                                    _d.sent();
                                    notification.success({ message: '무료체험이 적용되었습니다.' });
                                    return [3 /*break*/, 6];
                                case 5:
                                    err_1 = _d.sent();
                                    notification.error({ message: '무료체험 신청 중 오류가발생했습니다.' });
                                    return [3 /*break*/, 6];
                                case 6:
                                    handleClose();
                                    return [2 /*return*/];
                            }
                        });
                    }); } }))) })) : (React.createElement("div", null));
};
export var OtherSitePromotionPopup = function (props) {
    var shouldShowInLocal = getWithExpiry('SHOULD_SHOW_BLOG_POPUP');
    var _a = React.useState(!shouldShowInLocal || shouldShowInLocal !== 'false'), visible = _a[0], setVisible = _a[1];
    var handleClose = function (isOffToday) {
        setVisible(false);
        if (isOffToday) {
            setWithExpiry('SHOULD_SHOW_BLOG_POPUP', 'false', 1000 * 60 * 60 * 24);
        }
        EventTracker.send('디젤-daily-close', { action: 'close' });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(RecommendedEventModal, { open: visible, footer: null, centered: true, onCancel: function () { return handleClose(false); }, closable: false, maskClosable: false, children: React.createElement(RecommendedEventModalBody, null,
                React.createElement(RecommendedEventModalClose, { children: React.createElement(Icon, { name: "x", color: COLORS.white, size: 32 }), onClick: function () { return handleClose(false); } }),
                React.createElement(ScrollBar, { style: { maxHeight: '80vh' }, options: { scrollbars: { autoHide: 'never' }, overflowBehavior: { x: 'hidden' } } },
                    React.createElement("img", { src: IMAGES.daily_promotion_image, style: { width: '100%', padding: '8px' } }),
                    React.createElement("div", { className: "content" },
                        React.createElement(TextComponent, { type: "headline1", marginBottom: 16 }, "\uC0C8\uB86D\uAC8C \uC2DC\uC791\uD560\uB54C\uB294 \uB370\uC77C\uB9AC \uCEEC\uB7EC\uC824"),
                        React.createElement(TextComponent, { type: "body2", children: "\uC194\uB9AC\uB4DC\uCEEC\uB7EC 60\uC885+\uC2DC\uB7FD 20\uC885+\uAE00\uB9AC\uD130 20\uC885\uC758 \uC2E4\uC18D\uC788\uACE0 \uC54C\uCC2C \uAD6C\uC131\uACFC \uAC10\uC131\uC801\uC778 \uBC14\uD2C0 \uB514\uC790\uC778\uC73C\uB85C \uC5B4\uB5A4 \uC0F5\uC774\uB4E0 \uCC30\uB5A1\uAC19\uC774 \uC798 \uC5B4\uC6B8\uB9AC\uB294 \uB370\uC77C\uB9AC \uCEEC\uB7EC\uC824 \uBA54\uC778\uC824 \uCD94\uAC00, \uBCC0\uACBD\uC744 \uACE0\uBBFC\uC911\uC774\uC2E0 \uC6D0\uC7A5\uB2D8, \uB9E4\uC7A5 \uC624\uD508\uC744 \uC900\uBE44\uC911\uC778 \uC608\uBE44 \uC6D0\uC7A5\uB2D8\uB4E4\uAED8 \uB370\uC77C\uB9AC \uCEEC\uB7EC\uC824\uC744 \uCD94\uCC9C \uB4DC\uB9BD\uB2C8\uB2E4." }),
                        React.createElement(TextComponent, { marginTop: 16, type: "body2", children: "\uB2E4\uCC44\uB85C\uC6B4 \uC77C\uC0C1\uC744 \uB9CC\uB4E4\uC5B4\uC904 \uB370\uC77C\uB9AC \uCEEC\uB7EC\uC824 100\uC885" }))),
                React.createElement("div", { className: "content" },
                    React.createElement(ApplyBetaButton, { style: { marginBottom: '-16px' }, children: "\uB370\uC77C\uB9AC \uC778\uC2A4\uD0C0\uADF8\uB7A8 \uAD6C\uACBD\uAC00\uAE30", color: "primary", type: "primary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                EventTracker.send('디젤-daily-open', { action: 'open' });
                                openWindow("https://www.instagram.com/dailygelnail?igsh=azQwY2tiNmUyZWJv", '데일리 인스타');
                                setVisible(false);
                                return [2 /*return*/];
                            });
                        }); } }),
                    React.createElement(AntButton, { children: "\uC624\uB298\uD558\uB8E8 \uADF8\uB9CC \uBCF4\uAE30", onClick: function () {
                            handleClose(true);
                        } }))) })));
};
var ApplyBetaButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 0px !important;\n"], ["\n  margin-top: 0px !important;\n"])));
export var RecommendedEventModalClose = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  padding: 20px;\n  right: 0px;\n  top: 0px;\n  cursor: pointer;\n  z-index: 9999;\n"], ["\n  position: absolute;\n  padding: 20px;\n  right: 0px;\n  top: 0px;\n  cursor: pointer;\n  z-index: 9999;\n"])));
export var RecommendedEventModalBody = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n\n  & .content {\n    padding: 15px;\n  }\n\n  & button {\n    width: 100%;\n    margin-top: 10px;\n    margin-bottom: 10px;\n  }\n"], ["\n  position: relative;\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n\n  & .content {\n    padding: 15px;\n  }\n\n  & button {\n    width: 100%;\n    margin-top: 10px;\n    margin-bottom: 10px;\n  }\n"])), COLORS.white);
export var STICK_BANNER_HEIGHT = 40;
var StickBannerWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  top: ", "px;\n  width: 100%;\n  height: ", "px;\n  background-color: var(--primary-4);\n  z-index: 4;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  top: ", "px;\n  width: 100%;\n  height: ", "px;\n  background-color: var(--primary-4);\n  z-index: 4;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), HEADER_HEIGHT, STICK_BANNER_HEIGHT);
var Image = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: 10px;\n"], ["\n  width: 100%;\n  margin-bottom: 10px;\n"])));
var NoticePopupWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n"])));
var Wrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  padding-top: 30px;\n  padding-bottom: 20px;\n  padding-left: 25px;\n  padding-right: 25px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  padding-top: 30px;\n  padding-bottom: 20px;\n  padding-left: 25px;\n  padding-right: 25px;\n"])));
var StyledCheckbox = styled(Checkbox)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: white;\n  font-weight: 600;\n"], ["\n  color: white;\n  font-weight: 600;\n"])));
var StyledModal = styled(Modal)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  & .ant-modal-content {\n    background-color: transparent;\n    padding: 0px;\n    box-shadow:\n      0 0px 0px 0px,\n      0 0px 0px 0 rgb(0 0 0 / 0%),\n      0 0px 0px 0px rgb(0 0 0 / 0%);\n  }\n\n  & .ant-modal-body {\n    padding: 0px;\n  }\n"], ["\n  & .ant-modal-content {\n    background-color: transparent;\n    padding: 0px;\n    box-shadow:\n      0 0px 0px 0px,\n      0 0px 0px 0 rgb(0 0 0 / 0%),\n      0 0px 0px 0px rgb(0 0 0 / 0%);\n  }\n\n  & .ant-modal-body {\n    padding: 0px;\n  }\n"])));
var WillExpireSubscriptionPopupModal = styled(Modal)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  max-width: 1024px;\n  & .ant-modal-content {\n    background-color: white;\n    box-shadow:\n      0 0px 0px 0px,\n      0 0px 0px 0 rgb(0 0 0 / 0%),\n      0 0px 0px 0px rgb(0 0 0 / 0%);\n  }\n\n  & .ant-modal-body {\n    padding: 0px;\n    overflow-y: overlay;\n    max-height: 85vh;\n  }\n\n  display: flex;\n\n  & button {\n    width: 130px;\n\n    &:last-child {\n      margin-left: 10px;\n    }\n  }\n"], ["\n  max-width: 1024px;\n  & .ant-modal-content {\n    background-color: white;\n    box-shadow:\n      0 0px 0px 0px,\n      0 0px 0px 0 rgb(0 0 0 / 0%),\n      0 0px 0px 0px rgb(0 0 0 / 0%);\n  }\n\n  & .ant-modal-body {\n    padding: 0px;\n    overflow-y: overlay;\n    max-height: 85vh;\n  }\n\n  display: flex;\n\n  & button {\n    width: 130px;\n\n    &:last-child {\n      margin-left: 10px;\n    }\n  }\n"])));
export var RecommendedEventModal = styled(StyledModal)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  & .ant-modal-content {\n    display: flex;\n    justify-content: center;\n  }\n\n  .ant-modal-body {\n    width: 360px;\n  }\n"], ["\n  & .ant-modal-content {\n    display: flex;\n    justify-content: center;\n  }\n\n  .ant-modal-body {\n    width: 360px;\n  }\n"])));
export var RemoteEventModal = styled(RecommendedEventModal)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  .ant-modal-body {\n    width: 80%;\n    min-width: 360px;\n  }\n"], ["\n  .ant-modal-body {\n    width: 80%;\n    min-width: 360px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
