var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
// eslint-disable-next-line no-unused-vars
import Modal from 'antd/lib/modal';
import styled from 'styled-components';
import { Icon, TextComponent } from 'renderer/components';
import { COLORS } from 'renderer/styles';
import { Lodash } from 'renderer/utils';
export function RouterDialog(props) {
    var path = props.path, trigger = props.trigger, rest = __rest(props, ["path", "trigger"]);
    var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
    // FIXME add history
    var handleClose = function () {
        // naviagate(-1);
        setVisible(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DialogComponent, __assign({ onClose: handleClose }, rest, { visible: visible })),
        props.trigger &&
            React.cloneElement(trigger, {
                onClick: function (ev) {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setVisible(true);
                }
            })));
}
export var DialogComponent = function (props) {
    var title = props.title, content = props.content, actions = props.actions, visible = props.visible, rest = __rest(props, ["title", "content", "actions", "visible"]);
    var handleClose = function () {
        if (props.onClose) {
            props.onClose();
        }
    };
    // FIXME: footer, action정리
    return (React.createElement(ModalComponent, __assign({ title: title, centered: true, visible: visible, footer: React.createElement("div", null, actions &&
            actions.map(function (action) {
                return action;
            })), onCancel: handleClose }, rest), typeof content === 'string'
        ? props.content
        : Lodash.isArray(content)
            ? content.map(function (item, index) {
                return item;
            })
            : content));
};
var ModalComponent = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-modal-title {\n    font-family: AppleSDGothicNeo;\n    font-size: 14px;\n    font-weight: 500;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: normal;\n    color: var(--black);\n    letter-spacing: -0.2px;\n    word-break: break-all;\n    font-size: 18px;\n    font-weight: 700;\n  }\n"], ["\n  .ant-modal-title {\n    font-family: AppleSDGothicNeo;\n    font-size: 14px;\n    font-weight: 500;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: normal;\n    color: var(--black);\n    letter-spacing: -0.2px;\n    word-break: break-all;\n    font-size: 18px;\n    font-weight: 700;\n  }\n"])));
export var ActionPopup = function (props) {
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var handleClick = function (action) {
        handleClose();
        if (action) {
            action();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.cloneElement(props.trigger, { onClick: handleOpen }),
        React.createElement(Backdrop, { open: open, onCancel: handleClose, zIndex: 9999 },
            React.createElement(ActionsWrapper, null, props.actions.map(function (action) {
                return (React.createElement(ActionButtonInPopup, { disabled: action.disabled, onClick: action.disabled ? undefined : function () { return handleClick(action.onClick); }, key: action.label },
                    React.createElement(TextComponent, { children: action.label, type: "subtitle1", style: { flex: 1 } }),
                    React.createElement(Icon, { name: "right-chevron", color: COLORS.gray4, size: 14 })));
            })))));
};
var ActionsWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"], ["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"])));
var ActionButtonInPopup = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 240px;\n  z-index: 9999;\n  height: 68px;\n  border-radius: 2px;\n  background-color: ", ";\n  ", "\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 22px 28px;\n  cursor: ", ";\n  margin-bottom: 10px;\n"], ["\n  width: 240px;\n  z-index: 9999;\n  height: 68px;\n  border-radius: 2px;\n  background-color: ", ";\n  ", "\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 22px 28px;\n  cursor: ", ";\n  margin-bottom: 10px;\n"])), function (props) { return (props.disabled ? COLORS.gray7 : COLORS.white); }, function (props) {
    return props.disabled &&
        "\n    & > div {\n      color: ".concat(COLORS.gray6, " !important;\n    }\n  ");
}, function (props) { return (props.disabled ? 'not-allowed' : 'pointer'); });
export var Backdrop = function (props) {
    return (React.createElement(BackdropModel, __assign({ centered: true, visible: props.visible, footer: null, closeIcon: React.createElement("div", null), onCancel: props.onCancel }, props)));
};
var BackdropModel = styled(Modal)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  .ant-modal-content {\n    background-color: transparent;\n    box-shadow: none;\n  }\n"], ["\n  .ant-modal-content {\n    background-color: transparent;\n    box-shadow: none;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
