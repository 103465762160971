import { loadDynamicScript } from '../utils/script';
export var DaumMap;
(function (DaumMap) {
    DaumMap.Init = function (libraries) {
        if (libraries === void 0) { libraries = ['services']; }
        loadDynamicScript("//dapi.kakao.com/v2/maps/sdk.js?appkey=692672cf27f8d9252dd0d784eefc0c13&libraries=".concat(libraries.join(',')), 'daumMap', function () { });
    };
    DaumMap.Geocoder = function () {
        return new kakao.maps.services.Geocoder();
    };
})(DaumMap || (DaumMap = {}));
