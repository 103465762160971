var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Form from 'antd/lib/form';
import React from 'react';
import { AntdForm, ColorTag, DrawerComponent, DrawerFooter, GUIDE_NAMES, Marginer, MoreActionIntable, SettingTemplate, TableComponent, TextComponent } from 'renderer/components';
import { CustomerQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import { COLORS } from 'renderer/styles';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Row from 'antd/lib/row';
export var CustomerTagSetting = function (props) {
    var _a;
    var form = Form.useForm()[0];
    var _b = React.useState(), drawer = _b[0], setDrawer = _b[1];
    var query = CustomerQuery.useCustomerTags(props.storeId, { enabled: !!props.storeId });
    React.useEffect(function () {
        form.resetFields();
    }, [drawer]);
    var create = CustomerQuery.useCreateCustomerTagMutation();
    var update = CustomerQuery.useUpdateCustomerTagMutation();
    var deleteMutate = CustomerQuery.useDeleteCustomerTagMutation();
    var handleClose = function () {
        setDrawer(undefined);
        form.resetFields();
    };
    var handleDelete = function (tag) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            Modal.confirm({
                cancelText: '아니오',
                okText: '예',
                title: '정말 삭제하시겠어요?',
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 3, , 4]);
                                return [4 /*yield*/, deleteMutate.mutateAsync({ storeId: props.storeId, id: tag.id })];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, notification.success({
                                        message: '태그가 삭제되었습니다.'
                                    })];
                            case 2:
                                _a.sent();
                                return [3 /*break*/, 4];
                            case 3:
                                error_1 = _a.sent();
                                notification.error({
                                    message: error_1.message
                                });
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); }
            });
            return [2 /*return*/];
        });
    }); };
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!drawer.id) return [3 /*break*/, 2];
                    return [4 /*yield*/, update.mutateAsync(__assign(__assign({}, values), { storeId: props.storeId, id: drawer.id }))];
                case 1:
                    _a.sent();
                    notification.success({
                        message: '태그가 수정되었습니다.'
                    });
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, create.mutateAsync(__assign(__assign({}, values), { storeId: props.storeId }))];
                case 3:
                    _a.sent();
                    notification.success({
                        message: '태그가 생성되었습니다.'
                    });
                    _a.label = 4;
                case 4:
                    handleClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var fields = [
        {
            element: (React.createElement(TextComponent, { type: "headline3", color: COLORS.gray1, marginTop: 24, marginBottom: 30 }, "\uD0DC\uADF8 \uB4F1\uB85D"))
        },
        {
            type: 'text',
            itemProps: {
                name: 'name',
                label: '태그명',
                rules: [{ type: 'string', required: true, min: 1, max: 10 }]
            },
            inputProps: { placeholder: '태그명', autoFocus: true }
        },
        {
            type: 'color',
            itemProps: {
                name: 'color',
                label: '대표색상',
                rules: [{ required: true }]
            },
            inputProps: { placeholder: '색상 선택' }
        }
    ];
    var BUTTON_WIDTH = 40;
    return (React.createElement(SettingTemplate, { title: "\uACE0\uAC1D\uD0DC\uADF8 \uC124\uC815", guideName: GUIDE_NAMES.고객태그설정, description: "\uD0DC\uADF8\uB97C \uC124\uC815\uD574 \uACE0\uAC1D\uC758 \uD2B9\uC774\uC0AC\uD56D\uC744 \uBD84\uB958\uD558\uACE0 \uAD00\uB9AC\uD574\uBCF4\uC138\uC694!", action: React.createElement(Button, { type: "primary", children: '새 태그 등록', onClick: function () {
                setDrawer({
                    storeId: props.storeId
                });
            } }), body: React.createElement("div", null,
            React.createElement(Marginer, { height: 20 }),
            React.createElement(TableComponent, { bordered: false, columns: [
                    {
                        dataIndex: 'name',
                        key: 'name',
                        align: 'left',
                        title: '태그',
                        render: function (value, record, index) {
                            return {
                                children: React.createElement(ColorTag, { color: record.color, children: record.name })
                            };
                        }
                    },
                    {
                        dataIndex: 'actions',
                        key: 'actions',
                        align: 'right',
                        title: '',
                        width: BUTTON_WIDTH,
                        render: function (value, record) {
                            return {
                                children: (React.createElement(Row, { justify: "end" },
                                    React.createElement(MoreActionIntable, { actions: [
                                            {
                                                icon: 'edit3',
                                                title: '수정',
                                                // disabled: !hasUpdatePerm,
                                                onClick: function (ev) {
                                                    setDrawer(record);
                                                }
                                            },
                                            {
                                                icon: 'trash',
                                                title: '삭제',
                                                // disabled: !hasUpdatePerm || item.priority === 0,
                                                onClick: function (ev) {
                                                    handleDelete(record);
                                                }
                                            }
                                        ] })))
                            };
                        }
                    }
                ], dataSource: ((_a = query.data) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item; })) || [] }),
            React.createElement(DrawerComponent, { title: "\uD0DC\uADF8 ".concat((drawer === null || drawer === void 0 ? void 0 : drawer.id) ? '수정' : '생성'), visible: !!drawer, onClose: handleClose, footer: React.createElement(DrawerFooter, { onCancel: handleClose, onConfirm: form.submit, confirmText: (drawer === null || drawer === void 0 ? void 0 : drawer.id) ? '수정' : '생성' }) },
                React.createElement(AntdForm, { formProps: {
                        form: form,
                        initialValues: drawer,
                        onFinish: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                handleSubmit(values);
                                return [2 /*return*/];
                            });
                        }); }
                    }, onSubmit: handleSubmit, fields: fields }))) }));
};
