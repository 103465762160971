var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { COLORS } from 'renderer/styles';
import { useForm } from 'react-hook-form';
import { BigTitleModal, HookFormInputs, TextComponent, StyledDivider } from 'renderer/components';
import styled from 'styled-components';
import { CustomerQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import Card from 'antd/lib/card';
import { Col, Row } from 'antd/lib/grid';
export var CustomerMergeModal = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g = React.useState(false), open = _g[0], setOpen = _g[1];
    var form = useForm({
        mode: 'onChange'
    });
    React.useEffect(function () {
        setOpen(props.open);
        if (open) {
            form.reset({ mergedId: null, mergedCustomerName: '' });
        }
    }, [props.open]);
    var storeId = props.storeId;
    var mergedId = form.watch('mergedId');
    var mergedCustomerName = form.watch('mergedCustomerName');
    var mergeCustomerQuery = CustomerQuery.useCustomerDetail({ storeId: storeId, id: mergedId }, { enabled: !!storeId && !!mergedId });
    var merge = CustomerQuery.useMergeCustomerMutation();
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, merge.mutateAsync({
                            mergedId: mergedId,
                            originId: props.customer.id,
                            storeId: storeId
                        })];
                case 1:
                    _a.sent();
                    notification.success({ message: '병합이 완료되었습니다.' });
                    props.onClose();
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    notification.error({ message: err_1.message });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, function (errors) { });
    var mergeCustomer = mergeCustomerQuery === null || mergeCustomerQuery === void 0 ? void 0 : mergeCustomerQuery.data;
    var mergeCustomerName = mergeCustomer === null || mergeCustomer === void 0 ? void 0 : mergeCustomer.name;
    var prepaid = ((_a = props.customer.meta) === null || _a === void 0 ? void 0 : _a.prepaid) || 0;
    var numberOfActiveTicketsWithoutPrepaid = ((_b = props.customer.meta) === null || _b === void 0 ? void 0 : _b.numberOfActiveTicketsWithoutPrepaid) || 0;
    var mergedPrepaid = ((_c = mergeCustomer === null || mergeCustomer === void 0 ? void 0 : mergeCustomer.meta) === null || _c === void 0 ? void 0 : _c.prepaid) || 0;
    var mergedNumberOfActiveTicketsWithoutPrepaid = ((_d = mergeCustomer === null || mergeCustomer === void 0 ? void 0 : mergeCustomer.meta) === null || _d === void 0 ? void 0 : _d.numberOfActiveTicketsWithoutPrepaid) || 0;
    return (React.createElement(BigTitleModal, { centered: true, title: "\uACE0\uAC1D \uBCD1\uD569\uD558\uAE30", width: "420px", open: open, onCancel: function () { return props.onClose(); }, okText: "\uBCD1\uD569\uD558\uAE30", onOk: onSubmit, okButtonProps: { disabled: !mergedId || mergeCustomerName !== mergedCustomerName }, children: React.createElement("div", { style: { paddingLeft: '20px', paddingRight: '20px' } },
            React.createElement(StyledForm, null,
                React.createElement(HookFormInputs.CustomerSelector, { controlProps: {
                        name: 'mergedId',
                        control: form.control,
                        rules: {
                            required: '병합할 고객을 선택해주세요.'
                        }
                    }, inputProps: {
                        placeholder: '병합할 고객을 검색해주세요(이름,전화번호)',
                        autoFocus: true,
                        hideDetailButton: true,
                        hideAddButton: true,
                        onChange: function (targetId) {
                            var _a;
                            if (Number(targetId) === ((_a = props.customer) === null || _a === void 0 ? void 0 : _a.id)) {
                                notification.warning({ message: '본인과 병합할 수 없습니다.' });
                                form.setValue('mergedId', null);
                            }
                        }
                    } }),
                React.createElement(TextComponent, { type: "caption1", color: COLORS.gray2, children: "\uC120\uD0DD\uD558\uC2E0 \uACE0\uAC1D\uC758 \uB370\uC774\uD130\uB97C ".concat(props.customer.name, "\uB2D8\uC758 \uC815\uBCF4\uC5D0 \uBCD1\uD569\uD569\uB2C8\uB2E4. \uC120\uBD88\uC561, \uC815\uAE30\uAD8C, \uB9E4\uCD9C, \uC608\uC57D, \uBB38\uC790\uAE30\uB85D\uC774 \uD3EC\uD568\uB429\uB2C8\uB2E4.(\uACF5\uC720\uC120\uBD88\uC561, \uACF5\uC720\uC815\uAE30\uAD8C\uC740 \uC81C\uC678)") }),
                mergeCustomerName && (React.createElement(Card, null,
                    React.createElement(Row, { align: "middle" },
                        React.createElement(Col, { style: { width: '60px' } },
                            React.createElement(TextComponent, { children: "\uC774\uB984", type: "caption2", color: COLORS.gray2 })),
                        React.createElement(Col, null,
                            React.createElement(TextComponent, { children: (_e = props.customer) === null || _e === void 0 ? void 0 : _e.name, type: "caption1" }))),
                    React.createElement(Row, { align: "middle" },
                        React.createElement(Col, { style: { width: '60px' } },
                            React.createElement(TextComponent, { children: "\uC5F0\uB77D\uCC98", type: "caption2", color: COLORS.gray2 })),
                        React.createElement(Col, null,
                            React.createElement(TextComponent, { children: (_f = props.customer) === null || _f === void 0 ? void 0 : _f.phoneToString, type: "caption1" }))),
                    React.createElement(StyledDivider, { marginTop: 8, marginBottom: 8 }),
                    React.createElement(Row, { align: "middle" },
                        React.createElement(Col, { style: { width: '60px' } },
                            React.createElement(TextComponent, { children: "\uC794\uC5EC \uC120\uBD88\uC561", type: "caption2", color: COLORS.gray2 })),
                        React.createElement(Col, null,
                            React.createElement(Row, null,
                                React.createElement(TextComponent, { children: prepaid.toLocaleString(), type: "caption1" }),
                                !!mergedPrepaid && (React.createElement(TextComponent, { marginLeft: 8, children: "(+".concat(mergedPrepaid.toLocaleString(), "\uC6D0)"), type: "caption1", color: COLORS.primary1 }))))),
                    React.createElement(Row, { align: "middle" },
                        React.createElement(Col, { style: { width: '60px' } },
                            React.createElement(TextComponent, { children: "\uC794\uC5EC \uC815\uAE30\uAD8C", type: "caption2", color: COLORS.gray2 })),
                        React.createElement(Col, null,
                            React.createElement(Row, { align: "middle" },
                                React.createElement(TextComponent, { children: numberOfActiveTicketsWithoutPrepaid.toLocaleString(), type: "caption1" }),
                                !!mergedNumberOfActiveTicketsWithoutPrepaid && (React.createElement(TextComponent, { marginLeft: 8, children: "(+".concat(mergedNumberOfActiveTicketsWithoutPrepaid.toLocaleString(), "\uC7A5)"), type: "caption1", color: COLORS.primary1 }))))))),
                mergeCustomerName && (React.createElement(TextComponent, { type: "caption1", color: COLORS.gray2, children: React.createElement("span", null,
                        "\uBCD1\uD569 \uD6C4\uC5D0\uB294 ",
                        mergeCustomerName,
                        "\uACE0\uAC1D\uC758 \uAE30\uB85D\uC774 ",
                        props.customer.name,
                        "\uACE0\uAC1D\uC73C\uB85C \uC774\uC804\uB418\uBA70,",
                        React.createElement("span", { style: { color: 'black' } },
                            mergeCustomerName,
                            "\uACE0\uAC1D\uC740 \uC0AD\uC81C"),
                        "\uB429\uB2C8\uB2E4. \uBCD1\uD569 \uC774\uD6C4\uC5D0\uB294 \uBCF5\uAD6C\uD558\uC2E4 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uC548\uB0B4\uB97C \uD655\uC778\uD558\uC168\uB2E4\uBA74 ",
                        React.createElement("span", { style: { color: 'black' } },
                            "\u2018",
                            mergeCustomerName,
                            "\u2019"),
                        ' ',
                        "\uC774\uB77C\uACE0 \uC544\uB798\uC5D0 \uC785\uB825\uD574\uC8FC\uC138\uC694.") })),
                React.createElement(HookFormInputs.Text, { controlProps: {
                        name: 'mergedCustomerName',
                        control: form.control
                    }, inputProps: {
                        placeholder: '병합할 고객 이름 확인'
                    } }))) }));
};
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > div {\n    margin-top: 10px;\n  }\n\n  margin-top: 10px;\n  margin-bottom: 20px;\n"], ["\n  & > div {\n    margin-top: 10px;\n  }\n\n  margin-top: 10px;\n  margin-bottom: 20px;\n"])));
var templateObject_1;
