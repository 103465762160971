import React from 'react';
import { PopupButton } from '@typeform/embed-react';
export var Typeform = function (props) {
    var type = props.type, hidden = props.hidden, id = props.id;
    switch (type) {
        case 'popup':
            return (React.createElement(PopupButton, { id: id, height: 400, opacity: 100, open: "load", hidden: hidden, onSubmit: props.onSubmit, onQuestionChanged: props.onQuestionChanged, onClose: props.onClose, hideHeaders: true, children: React.createElement("div", null) }));
    }
};
export var BlogQuestions = function (props) {
    var _a;
    var store = props.store, manager = props.manager;
    var visible = !!props.visible;
    if (visible) {
        try {
            return (React.createElement(Typeform, { id: "eoIgKl7J", type: "popup", visible: visible, onClose: function () {
                    props.onClose();
                }, hidden: {
                    store_id: store.id,
                    manager_id: manager === null || manager === void 0 ? void 0 : manager.id,
                    store_name: store.name,
                    phone: (_a = manager === null || manager === void 0 ? void 0 : manager.profile) === null || _a === void 0 ? void 0 : _a.phone,
                    manager_name: manager === null || manager === void 0 ? void 0 : manager.displayName
                } }));
        }
        catch (err) {
            return React.createElement("div", null);
        }
    }
    else {
        return React.createElement("div", null);
    }
};
