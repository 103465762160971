var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { COLORS } from 'renderer/styles';
import { TextComponent } from 'renderer/components';
export var Link = function (props) {
    var to = props.to;
    var isExternal = /https?:\/\//g.test(to);
    return isExternal ? (React.createElement("a", __assign({ href: to }, props, { rel: "noopener noreferrer", target: "_external" }),
        React.createElement(TextComponent, { type: "body1", color: COLORS.primary1 }, props.children))) : (React.createElement(RouterLink, __assign({}, props),
        React.createElement(TextComponent, { type: "body1", color: COLORS.primary1 }, props.children)));
};
