var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { DrawerComponent, AntdForm, DrawerFooter, AlertComponent, Marginer, AdditionalCover, ResponsiveWithPlusIcon, TextComponent, PhoneNumberNormalizer, GUIDE_NAMES, SortableTable, PasswordLocker, useAccessToken, AntPrimaryTextButton, Icon, BigTitleModal, NumberFormatInput, checkPermissionForOnClick, AlertSentenceWithIcon } from 'renderer/components';
import { ManagerModel, MANAGER_STATUS, MANAGER_ROLE, MANAGER_PERMISSION_OBJECT, MANAGER_PERMISSION_GROUPS, MANAGER_PERMISSION_PRIORITY } from 'renderer/models';
import dayjs from 'dayjs';
import { ManagerQuery } from 'renderer/queries';
import Form from 'antd/lib/form';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles';
import { DATE_FORMAT, ID_LENGTH_MAX, ID_LENGTH_MIN, PW_LENGTH_MAX, REGEX_ID, REGEX_PHONE } from 'renderer/constants';
import notification from 'antd/lib/notification';
import { useWindowSize } from 'renderer/utils';
import { PW_LENGTH_MIN } from 'renderer/constants';
import Modal from 'antd/lib/modal';
import { Row } from 'antd/lib/grid';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';
import { SettingTemplate } from '.';
import { FormTitle } from 'renderer/containers/app/Sales/components/IncentiveTab';
import Radio from 'antd/lib/radio';
import { Layout } from 'common-ui';
import { InlineFormItem } from './templateFields';
import Select from 'antd/lib/select';
var ColorDisplay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n  border-radius: 2px;\n  background-color: ", ";\n"], ["\n  width: 20px;\n  height: 20px;\n  border-radius: 2px;\n  background-color: ", ";\n"])), function (props) { return props.color; });
export var EmployeeListInSettings = function (props) {
    var _a;
    var _b = React.useState(), drawer = _b[0], setDrawer = _b[1];
    var signOutFromAllDevice = useAccessToken().signOutFromAllDevice;
    var columns = [
        {
            title: '이름(아이디)',
            dataIndex: 'loginId',
            key: 'loginId',
            render: function (_, record) {
                var _a;
                return (React.createElement(Row, null,
                    React.createElement(ColorDisplay, { color: (_a = record === null || record === void 0 ? void 0 : record.profile) === null || _a === void 0 ? void 0 : _a.color }),
                    React.createElement(TextComponent, { marginLeft: 10, children: "".concat(record.displayName, "(").concat(record.loginId, ")") })));
            }
        },
        {
            title: '연락처',
            dataIndex: ['profile', 'phone'],
            key: 'profile.phone'
        },
        {
            title: '입사일',
            dataIndex: 'createdAt',
            key: 'profile.createdAt',
            render: function (value, record) { return "".concat(dayjs(value).format(DATE_FORMAT), "(").concat(record.statusString, ")"); }
        },
        {
            title: '직급',
            dataIndex: ['profile', 'position'],
            key: 'meta.status',
            render: function (value) { return value; }
        }
    ];
    var handleClose = function () {
        setDrawer(null);
    };
    var store = props.store;
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var handleDelete = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                Modal.confirm({
                    cancelText: '아니오',
                    okText: '예',
                    centered: true,
                    title: "\uC9C1\uC6D0\uC744 \uC0AD\uC81C\uD558\uC2DC\uBA74 \uB9E4\uCD9C, \uACE0\uAC1D \uB4F1 \uB2F4\uB2F9\uC790 \uC815\uBCF4\uAC00 '\uB2F4\uB2F9\uC790 \uC5C6\uC74C'\uC73C\uB85C \uBCC0\uACBD\uB429\uB2C8\uB2E4.",
                    onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, deleteManager.mutateAsync({ id: Number(record.id), storeId: storeId })];
                                case 1:
                                    _a.sent();
                                    notification.success({ message: '직원의 정보가 삭제되었습니다.' });
                                    return [2 /*return*/];
                            }
                        });
                    }); }
                });
            }
            catch (error) { }
            return [2 /*return*/];
        });
    }); };
    var _c = React.useState([]), managers = _c[0], setManagers = _c[1];
    var managerQuery = ManagerQuery.useManagers(storeId, { enabled: !!storeId });
    React.useEffect(function () {
        if (managerQuery.data) {
            setManagers(managerQuery.data);
        }
    }, [managerQuery.data]);
    var create = ManagerQuery.useCreateManager();
    var update = ManagerQuery.useUpdateManager();
    var updatePriority = ManagerQuery.useUpdateManagerPriority();
    var deleteManager = ManagerQuery.useDeleteManager();
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var isUpdate, data, payload, payload, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    isUpdate = (_a = drawer.data) === null || _a === void 0 ? void 0 : _a.id;
                    data = drawer.data;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, , 7]);
                    if (!isUpdate) return [3 /*break*/, 3];
                    payload = values.permissions
                        ? __assign(__assign({}, data), { meta: __assign(__assign({}, data.meta), { permissions: values.permissions }) }) : __assign(__assign(__assign({}, data), values), { meta: __assign(__assign({}, data.meta), values.meta), profile: __assign(__assign({}, data.profile), values.profile), storeId: storeId, id: data === null || data === void 0 ? void 0 : data.id });
                    return [4 /*yield*/, update.mutateAsync(payload)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    payload = __assign(__assign({}, values), { storeId: storeId });
                    return [4 /*yield*/, create.mutateAsync(__assign(__assign({}, payload), { role: MANAGER_ROLE.STORE_EMPLOYEE, meta: __assign(__assign({}, payload.meta), { priority: managers.length }) }))];
                case 4:
                    _b.sent();
                    _b.label = 5;
                case 5:
                    notification.success({ message: '직원 정보 저장이 완료되었습니다.' });
                    return [3 /*break*/, 7];
                case 6:
                    error_1 = _b.sent();
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleOrder = function (records) { return __awaiter(void 0, void 0, void 0, function () {
        var record, managers, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    record = records[0];
                    if (!record) {
                        return [2 /*return*/];
                    }
                    if (!((_a = props.permission) === null || _a === void 0 ? void 0 : _a.update)) {
                        return [2 /*return*/, notification.info({ message: '직원 수정 권한이 없습니다.' })];
                    }
                    managers = records.map(function (item, index) { return (__assign(__assign({}, item), { meta: __assign(__assign({}, item.meta), { priority: index }) })); });
                    setManagers(managers);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updatePriority.mutateAsync({ managers: managers, storeId: storeId })];
                case 2:
                    _b.sent();
                    return [2 /*return*/, notification.info({ message: '직원 순서 수정이 완료되었습니다.' })];
                case 3:
                    error_2 = _b.sent();
                    return [2 /*return*/, notification.error({ message: '직원 순서 변경 중 에러 발생' })];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var isPC = useWindowSize().isPC;
    return (React.createElement(SettingTemplate, { title: "\uC9C1\uC6D0 \uAD00\uB9AC", description: "\uB2E4\uC778\uC0F5\uC758 \uACBD\uC6B0 \uC720\uB8CC\uD50C\uB79C\uC744 \uC774\uC6A9\uD558\uBA74 \uC9C1\uC6D0\uC744 \uC81C\uD55C\uC5C6\uC774 \uCD94\uAC00\uD558\uACE0 \uAD00\uB9AC\uD560 \uC218 \uC788\uC5B4\uC694! \uC9C1\uC6D0\uBCC4 \uC811\uADFC\uAD00\uB9AC\uAC00 \uD544\uC694\uD558\uB2E4\uBA74  \uAD8C\uD55C\uC744 \uC124\uC815\uD574\uBCF4\uC138\uC694!", action: React.createElement(ResponsiveWithPlusIcon, { children: "\uC9C1\uC6D0 \uCD94\uAC00", onClick: function () {
                var _a;
                if (checkPermissionForOnClick((_a = props.permission) === null || _a === void 0 ? void 0 : _a.create, '직원 생성 권한이 없습니다.')) {
                    setDrawer({ type: 'managerInfo', data: {} });
                }
            } }), guideName: GUIDE_NAMES.직원관리, body: React.createElement("div", null,
            ((_a = props.permission) === null || _a === void 0 ? void 0 : _a.read) && (React.createElement(PasswordLocker, { description: "\uBCF4\uC548\uC744 \uC704\uD574 \uBE44\uBC00\uBC88\uD638 \uD655\uC778 \uD6C4 \uC9C1\uC6D0\uCD94\uAC00 \uBC0F \uAD00\uB9AC \uD398\uC774\uC9C0\uB85C \uC774\uB3D9\uC774 \uAC00\uB2A5\uD569\uB2C8\uB2E4." })),
            React.createElement("div", null,
                !isPC && React.createElement(Marginer, { height: 35 }),
                React.createElement(AlertComponent, { showIcon: true, type: "info", message: "\uC9C1\uC6D0 \uD1F4\uC0AC \uC2DC \uC7AC\uC9C1\uC0C1\uD0DC\uB97C \u2018\uD1F4\uC0AC\u2019\uB85C \uBCC0\uACBD\uD574\uC8FC\uC138\uC694. \uC9C1\uC6D0 \uC0AD\uC81C \uC2DC \uD574\uB2F9 \uC9C1\uC6D0\uC758 \uBAA8\uB4E0 \uC774\uC6A9\uB0B4\uC5ED\uC774 \u2018\uB2F4\uB2F9\uC790 \uC5C6\uC74C\u2019\uC73C\uB85C \uBCC0\uACBD\uB429\uB2C8\uB2E4." }),
                React.createElement(Marginer, { height: 15 }),
                React.createElement(Marginer, { height: 12 }),
                React.createElement(SortableTable, { actions: [
                        {
                            title: '직원권한 설정',
                            icon: 'settings',
                            isDisabled: function (manager) {
                                return !(store === null || store === void 0 ? void 0 : store.hasSubscription) && !manager.isAdmin;
                            },
                            onClick: function (manager) {
                                var _a;
                                if (checkPermissionForOnClick((_a = props.permission) === null || _a === void 0 ? void 0 : _a.create, '직원 수정 권한이 없습니다.')) {
                                    setDrawer({ data: manager, type: 'permissions' });
                                }
                            }
                        },
                        {
                            title: '직원정보 수정',
                            icon: 'edit3',
                            isDisabled: function (manager) {
                                return !(store === null || store === void 0 ? void 0 : store.hasSubscription) && !manager.isAdmin;
                            },
                            onClick: function (manager) {
                                var _a;
                                if (checkPermissionForOnClick((_a = props.permission) === null || _a === void 0 ? void 0 : _a.create, '직원 수정 권한이 없습니다.')) {
                                    setDrawer({ data: manager, type: 'managerInfo' });
                                }
                            }
                        },
                        {
                            title: '직원정보 삭제',
                            icon: 'trash',
                            onClick: function (manager) {
                                var _a;
                                if (checkPermissionForOnClick((_a = props.permission) === null || _a === void 0 ? void 0 : _a.create, '직원 삭제 권한이 없습니다.')) {
                                    handleDelete(manager);
                                }
                            },
                            isDisabled: function (manager) {
                                return manager.isAdmin;
                            }
                        },
                        {
                            title: '로그아웃 처리',
                            icon: 'logout',
                            onClick: function (manager) {
                                Modal.confirm({
                                    type: 'info',
                                    cancelText: '취소',
                                    onCancel: function () { },
                                    onOk: function () {
                                        signOutFromAllDevice(manager.id, manager.isAdmin);
                                    },
                                    content: '선택한 계정을 모든 접속기기에서 로그아웃 처리하며 자동로그인 설정을 해제합니다. 재로그인을 제한하고 싶다면 비밀번호를 변경해주세요.'
                                });
                            }
                        }
                    ], onSortEnd: handleOrder, columns: columns, 
                    // rowKey={(row) => row.id}
                    dataSource: managers === null || managers === void 0 ? void 0 : managers.map(function (manager, index) { return new ManagerModel(__assign(__assign({}, manager), { key: index, index: index })); }), 
                    // dataSource={managers
                    //   ?.filter((data) => filter.includes(data.meta?.status))
                    //   ?.map((manager, index) => new ManagerModel({ ...manager, key: index, index } as any))}
                    pagination: false })),
            drawer && (drawer === null || drawer === void 0 ? void 0 : drawer.type) === 'permissions' && (React.createElement(EmployeePermissionDrawer, { drawer: drawer, onClose: handleClose, onSubmit: handleSubmit, store: store })),
            (drawer === null || drawer === void 0 ? void 0 : drawer.type) === 'managerInfo' && (React.createElement(EmployeeFormDrawer, { drawer: drawer, onClose: handleClose, onSubmit: handleSubmit, store: store }))) }));
};
var EmployeeFormDrawer = function (props) {
    var _a, _b, _c;
    var form = Form.useForm()[0];
    var drawer = props.drawer;
    var data = drawer === null || drawer === void 0 ? void 0 : drawer.data;
    var isUpdated = !!(data === null || data === void 0 ? void 0 : data.id);
    var initialValues = __assign(__assign({ role: MANAGER_ROLE.STORE_EMPLOYEE, profile: {
            color: COLORS.staff2
        } }, data), { createdAt: dayjs(data.createdAt), meta: __assign(__assign({ status: MANAGER_STATUS.WORKING }, data.meta), { retiredAt: ((_a = data.meta) === null || _a === void 0 ? void 0 : _a.retiredAt) ? dayjs((_b = data.meta) === null || _b === void 0 ? void 0 : _b.retiredAt) : null }) });
    var getDrawerTitle = function () {
        if (!drawer) {
            return;
        }
        var text = (data === null || data === void 0 ? void 0 : data.id) ? '수정' : '등록';
        return "\uC9C1\uC6D0 ".concat(text);
    };
    React.useEffect(function () {
        form.resetFields();
    }, [drawer]);
    var checkId = function () { return __awaiter(void 0, void 0, void 0, function () {
        var loginId, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loginId = form.getFieldValue('loginId');
                    if (isUpdated || !loginId) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, ManagerQuery.checkLoginId(loginId)];
                case 1:
                    result = _a.sent();
                    if (result.result) {
                        form.setFields([{ name: 'loginId', errors: result ? ['이미 존재하는 아이디 입니다.'] : [] }]);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var _d = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _d[1];
    var fields = [
        { element: React.createElement(FormTitle, { title: "\uC9C1\uC6D0 \uAE30\uBCF8 \uC815\uBCF4" }) },
        {
            type: 'text',
            itemProps: {
                name: 'loginId',
                label: '아이디',
                hasFeedback: true,
                onBlur: checkId,
                rules: [{ type: 'string', min: ID_LENGTH_MIN, max: ID_LENGTH_MAX, pattern: REGEX_ID, required: true }]
            },
            inputProps: {
                autoComplete: 'off',
                placeholder: '로그인 아이디를 입력해주세요.',
                autoFocus: !isUpdated,
                disabled: isUpdated
            }
        },
        {
            type: 'password',
            itemProps: {
                name: 'password',
                label: isUpdated ? '새 비밀번호' : '비밀번호',
                hasFeedback: true,
                rules: [{ type: 'string', required: !isUpdated, min: PW_LENGTH_MIN, max: PW_LENGTH_MAX }]
            },
            inputProps: {
                autoComplete: 'new-password',
                placeholder: isUpdated ? '비밀번호를 변경하시려면 입력하세요.' : '비밀번호를 입력하세요.',
                type: 'password'
            }
        },
        {
            type: 'password',
            itemProps: {
                name: 'passwordConfirm',
                label: '비밀번호 확인',
                hasFeedback: true,
                dependencies: ['password'],
                rules: [
                    {
                        required: !isUpdated,
                        message: '비밀번호 확인을 입력 해 주세요'
                    },
                    function (_a) {
                        var getFieldValue = _a.getFieldValue;
                        return ({
                            validator: function (rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('비밀번호를 다시 확인해주세요.');
                            }
                        });
                    }
                ]
            },
            inputProps: {
                placeholder: '위 비밀번호를 확인하세요.'
            }
        },
        {
            type: 'text',
            itemProps: {
                name: ['profile', 'name'],
                label: '이름',
                rules: [{ type: 'string', required: true, min: 2, max: 15 }]
            },
            inputProps: { placeholder: '이름을 입력해주세요.' }
        },
        {
            type: 'text',
            itemProps: {
                name: ['profile', 'phone'],
                label: '휴대전화',
                normalize: PhoneNumberNormalizer,
                rules: [
                    {
                        pattern: REGEX_PHONE,
                        required: true,
                        message: '올바른 휴대전화번호를 입력해주세요.'
                    }
                ]
            },
            inputProps: {
                placeholder: '휴대전화을 입력해주세요.',
                mask: [{ mask: '000-000[0]-0000' }]
            }
        },
        { element: React.createElement(FormTitle, { title: "\uC9C1\uC6D0 \uAE30\uBCF8 \uC815\uBCF4" }) },
        {
            type: 'datepicker',
            itemProps: {
                name: 'createdAt',
                label: '입사일',
                rules: [{ type: 'date' }]
            },
            inputProps: { placeholder: 'YYYY.MM.DD', form: DATE_FORMAT }
        },
        {
            type: 'select',
            itemProps: {
                name: ['meta', 'status'],
                label: '재직상태',
                initalValue: MANAGER_STATUS.WORKING,
                rules: [{ type: 'string', required: true }]
            },
            inputProps: {
                placeholder: '재직 상태 선택',
                disabled: (data === null || data === void 0 ? void 0 : data.role) === MANAGER_ROLE.STORE_ADMIN,
                options: [
                    { label: '재직중', value: MANAGER_STATUS.WORKING },
                    { label: '퇴사', value: MANAGER_STATUS.RETIREMENT }
                ],
                onChange: function (value) {
                    form.setFieldsValue({
                        meta: __assign(__assign({}, data.meta), { status: value, retiredAt: value === MANAGER_STATUS.RETIREMENT ? dayjs() : null })
                    });
                    forceUpdate();
                }
            }
        },
        {
            type: 'datepicker',
            itemProps: {
                name: ['meta', 'retiredAt'],
                label: '퇴사일',
                rules: [{ type: 'date' }]
            },
            inputProps: {
                placeholder: '퇴사일을 기록해주세요.',
                form: DATE_FORMAT,
                disabled: (form.getFieldValue(['meta', 'status']) || ((_c = initialValues.meta) === null || _c === void 0 ? void 0 : _c.status)) !== MANAGER_STATUS.RETIREMENT
            }
        },
        {
            type: 'text',
            itemProps: {
                name: ['profile', 'position'],
                label: '직급',
                rules: [{ type: 'string', required: true, min: 2, max: 10 }]
            },
            inputProps: { placeholder: '직급을 입력해주세요.' }
        },
        {
            type: 'color',
            itemProps: {
                name: ['profile', 'color'],
                label: '대표색상',
                rules: [{ type: 'string', required: true }]
            },
            inputProps: {
                placeholder: '색상을 선택해주세요.',
                options: [
                    { value: COLORS.staff2, label: "" },
                    { value: COLORS.staff3, label: "" },
                    { value: COLORS.staff4, label: "" },
                    { value: COLORS.staff5, label: "" },
                    { value: COLORS.staff6, label: "" },
                    { value: COLORS.staff7, label: "" },
                    { value: COLORS.staff8, label: "" }
                ]
            }
        }
    ];
    return (React.createElement(DrawerComponent, { title: getDrawerTitle(), open: !!drawer, onClose: props.onClose, footer: React.createElement(DrawerFooter, { onCancel: props.onClose, onConfirm: form.submit }) },
        (initialValues === null || initialValues === void 0 ? void 0 : initialValues.role) !== ManagerModel.MANAGER_ROLE.STORE_ADMIN && (React.createElement(AdditionalCover, { store: props.store, type: "employee", isNarrow: true })),
        React.createElement(AntdForm, { formProps: {
                form: form,
                initialValues: initialValues,
                onFinish: function (values) {
                    props.onSubmit(values);
                    props.onClose();
                }
            }, onSubmit: props.onSubmit, fields: fields })));
};
var EmployeePermissionDrawer = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var form = Form.useForm()[0];
    var drawer = props.drawer;
    var _g = React.useState('store'), selectedGroup = _g[0], setGroup = _g[1];
    React.useEffect(function () {
        form.resetFields();
    }, [drawer]);
    var _h = React.useState((_b = (_a = props.drawer) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.permissions), permissions = _h[0], setPermissions = _h[1];
    React.useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (((_b = (_a = props.drawer) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.permissions) && ((_d = (_c = props.drawer) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.permissions) !== permissions) {
            setPermissions((_f = (_e = props.drawer) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.permissions);
            form.setFieldsValue((_h = (_g = props.drawer) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.permissions);
        }
    }, [(_c = props.drawer) === null || _c === void 0 ? void 0 : _c.data]);
    var fileds = [];
    Object.keys(MANAGER_PERMISSION_OBJECT).map(function (key) {
        if (!key.startsWith(selectedGroup)) {
            return;
        }
        var data = MANAGER_PERMISSION_OBJECT[key];
        fileds.push({ element: React.createElement(FormTitle, { title: data.title }) });
        Object.keys(data.allowActions).map(function (actionKey) {
            var isDuration = actionKey.includes('Duration');
            var isNone = form.getFieldValue([key, actionKey === null || actionKey === void 0 ? void 0 : actionKey.replace(/Duration/g, '')]) === MANAGER_PERMISSION_PRIORITY.NOTHING;
            if (isNone && isDuration) {
                return;
            }
            var hasDuration = form.getFieldValue([key, actionKey, 'hasDuration']);
            fileds.push({
                type: 'select',
                itemProps: {
                    name: [key, actionKey, isDuration ? 'hasDuration' : null].filter(Boolean),
                    label: actionKey === 'read'
                        ? '조회'
                        : actionKey === 'create'
                            ? '생성'
                            : actionKey === 'update'
                                ? '수정'
                                : actionKey === 'delete'
                                    ? '삭제'
                                    : actionKey === 'readDuration'
                                        ? '조회기간'
                                        : actionKey === 'updateDuration'
                                            ? '수정기간'
                                            : actionKey === 'deleteDuration'
                                                ? '삭제기간'
                                                : ''
                },
                inputProps: {
                    defaultValue: false,
                    options: isDuration
                        ? [
                            { value: false, label: '전체' },
                            { value: true, label: '기간설정' }
                        ]
                        : data.allowActions[actionKey].map(function (item) { return ({
                            value: item,
                            label: item === 0 ? '전체' : item === 1 ? '내것만' : '권한 없음'
                        }); })
                }
            });
            if (isDuration && hasDuration) {
                var value = form.getFieldValue([key, actionKey, 'value']);
                var unit = form.getFieldValue([key, actionKey, 'unit']);
                console.log('valuevalue', value, unit);
                fileds.push({
                    element: (React.createElement(Row, null,
                        React.createElement(Col, { span: 6 }),
                        React.createElement(Col, { span: 18 },
                            React.createElement(InlineFormItem, { items: [
                                    {
                                        name: [key, actionKey, 'value'],
                                        wrapperCol: { span: 24 },
                                        rules: [
                                            {
                                                type: 'number',
                                                required: true,
                                                message: "\uAE30\uAC04\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694."
                                            },
                                            {
                                                type: 'number',
                                                min: 1,
                                                message: '1 이상의 숫자를 입력해주세요.'
                                            }
                                        ],
                                        input: (React.createElement(NumberFormatInput, { style: { width: '100%' }, placeholder: '기간을 입력해주세요.' }))
                                    },
                                    {
                                        name: [key, actionKey, 'unit'],
                                        wrapperCol: { span: 24 },
                                        rules: [{ type: 'string', required: true, message: "\uB2E8\uC704\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694." }],
                                        input: (React.createElement(Select, { style: { width: '100%' }, options: [
                                                { label: '개월', value: 'month' },
                                                { label: '주', value: 'week' },
                                                { label: '일', value: 'day' },
                                                { label: '년', value: 'year' }
                                            ], placeholder: '기간을 입력해주세요.' }))
                                    }
                                ] }),
                            value && unit ? (React.createElement(Row, { style: { marginTop: '-5px', marginBottom: '15px' } },
                                React.createElement(AlertSentenceWithIcon, { descriptions: [
                                        "\uAE30\uAC04 : ".concat(dayjs().subtract(value, unit).add(1, 'day').format(DATE_FORMAT), " ~ ").concat(dayjs().format(DATE_FORMAT), "\uC77C \uD3EC\uD568")
                                    ] }))) : (React.createElement("div", null)))))
                });
            }
        });
    });
    var handleSave = function (permissions) {
        setPermissions(permissions);
        form.setFieldsValue(permissions);
    };
    return (React.createElement(StyledDrawer, { title: "\uC9C1\uC6D0\uAD8C\uD55C \uC124\uC815", open: !!drawer, onClose: props.onClose, footer: React.createElement(DrawerFooter, { onCancel: props.onClose, onConfirm: form.submit, extra: React.createElement(Col, { flex: 1 },
                React.createElement(PermissionCopier, { storeId: (_d = props.store) === null || _d === void 0 ? void 0 : _d.id, managerId: (_f = (_e = props.drawer) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.id, onSave: handleSave })) }) },
        React.createElement(GroupRow, { gutter: 8 }, MANAGER_PERMISSION_GROUPS.map(function (group) {
            return (React.createElement(Col, { key: group.key, span: 12 },
                React.createElement(BlackButton, { active: group.key === selectedGroup, onClick: function () {
                        setGroup(group.key);
                        form.setFieldsValue(permissions);
                    } }, group.title)));
        })),
        React.createElement(FormWrapper, null,
            React.createElement(AntdForm, { formProps: {
                    form: form,
                    // values:
                    // initialValues: permissions,
                    onValuesChange: function (_, values) {
                        setPermissions(__assign(__assign({}, permissions), values));
                    },
                    onFinish: function (values) {
                        props.onSubmit({ permissions: permissions });
                        props.onClose();
                    }
                }, onSubmit: props.onSubmit, fields: fileds }))));
};
var PermissionCopier = function (props) {
    var _a, _b;
    var managers = ManagerQuery.useManagers(props.storeId);
    var _c = React.useState(), managerId = _c[0], setManagerId = _c[1];
    var onChange = function (e) {
        setManagerId(e.target.value);
    };
    var handleOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var targetManager;
        return __generator(this, function (_a) {
            try {
                targetManager = managers.data.find(function (manager) { return manager.id === managerId; });
                props.onSave(targetManager.permissions);
                notification.success({ message: '직원의 권한이 복사되었습니다.' });
                setManagerId(null);
            }
            catch (error) { }
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(Row, { justify: "end", align: "middle", style: { height: '100%' } },
        React.createElement(AntPrimaryTextButton, { children: "\uAD8C\uD55C \uBCF5\uC0AC\uD574\uC624\uAE30", icon: React.createElement(Icon, { name: "copy" }), onClick: function () { var _a; return setManagerId((_a = managers.data[0]) === null || _a === void 0 ? void 0 : _a.id); } }),
        React.createElement(BigTitleModal, { open: !!managerId, okText: "\uAD8C\uD55C \uBCF5\uC0AC", cancelText: "\uB2EB\uAE30", onCancel: function () { return setManagerId(null); }, onOk: handleOk, title: "\uAD8C\uD55C \uBCF5\uC0AC\uD574\uC624\uAE30", children: React.createElement("div", { style: { padding: '20px' } },
                React.createElement(TextComponent, { children: "\uAD8C\uD55C\uC744 \uBCF5\uC0AC\uD574 \uC62C \uC9C1\uC6D0\uC744 \uC120\uD0DD\uD558\uC138\uC694.", marginBottom: 10 }),
                React.createElement(Radio.Group, { name: "managerId", value: managerId, onChange: onChange, style: { width: '100%' } },
                    React.createElement(Layout.FluidStack, null, (_b = (_a = managers.data) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.id !== props.managerId; })) === null || _b === void 0 ? void 0 : _b.map(function (manager) {
                        return (React.createElement(StyledRadio, { value: manager.id, "$selected": managerId === manager.id },
                            React.createElement(Layout.FluidInline, { gutter: 4 },
                                React.createElement(ColorDisplay, { color: manager === null || manager === void 0 ? void 0 : manager.color }),
                                React.createElement(TextComponent, { marginLeft: 10, children: "".concat(manager.displayName, "(").concat(manager.loginId, ")") }))));
                    })))), centered: true })));
};
var StyledRadio = styled(Radio)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid ", ";\n  height: 30px;\n  background-color: ", ";\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid ", ";\n  height: 30px;\n  background-color: ", ";\n  width: 100%;\n"])), COLORS.gray7, function (props) { return (props.$selected ? COLORS.primary5 : COLORS.white); });
var FormWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0px 25px;\n"], ["\n  padding: 0px 25px;\n"])));
var StyledDrawer = styled(DrawerComponent)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  .ant-drawer-body {\n    padding: 0px;\n  }\n"], ["\n  .ant-drawer-body {\n    padding: 0px;\n  }\n"])));
var GroupRow = styled(Row)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: var(--gray-7);\n  padding: 30px 25px;\n\n  & > .ant-col {\n    margin-top: 5px;\n\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  background-color: var(--gray-7);\n  padding: 30px 25px;\n\n  & > .ant-col {\n    margin-top: 5px;\n\n    & > button {\n      width: 100%;\n    }\n  }\n"])));
var BlackButton = styled(Button)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n\n  &.ant-btn:hover,\n  &.ant-btn:focus {\n    background-color: var(--gray-1);\n    color: white;\n    border-color: transparent;\n  }\n"], ["\n  ", "\n\n  &.ant-btn:hover,\n  &.ant-btn:focus {\n    background-color: var(--gray-1);\n    color: white;\n    border-color: transparent;\n  }\n"])), function (props) {
    return props.active &&
        "\n  background-color: var(--gray-1);\n  color: white;\n\n  ";
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
